import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Container,
  Col,
  Row,
  Table,
  CardBody,
  FormGroup,
  Input,
} from "reactstrap";
import { Filter, Settings, ShoppingBag, Plus, X, Search } from "react-feather";
import { Button, Modal, Alert } from "react-bootstrap";

import AuthService from "../../../AuthServices/auth.service.js";
import PartnerService from "../../../AuthServices/partners.service.js";
import Pagination from "../../../../src/components/Pagination";

// SVG Icons
import iconEdit from "../../../assets/img/icons/edit.svg";
import iconDeleteUser from "../../../assets/img/icons/delete.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const SgaPartnersList = (props) => {
  var currentUser = AuthService.getCurrentUser();
  var currentCompany = AuthService.getCurrentCompany();
  if (currentUser == null || currentUser == undefined) {
    props.history.push("/");
    window.location.reload();
  }
  if (
    currentUser.roleName === "user" ||
    currentUser.roleName === "team-member" ||
    currentUser.roleName === "team-leader" ||
    currentUser.roleName === "company" ||
    currentUser.roleName === "partner"
  ) {
    props.history.push("/dashboard/default");
    window.location.reload();
  }
  const [partners, setPartners] = useState([]);
  const [countries, setCountry] = useState([]);

  const [partnerMessage, setMessage] = useState(null);
  const [partnerAddMessage, setPartnerAddMessage] = useState(null);

  //const [partnerFirstNameSignup, setPartnerFirstNameSignup] = useState("");
  const [partnerId, setPartnerId] = useState("");

  const [partnerAddCreditsSignUp, setPartnerAddCreditsSignUp] = useState("");

  //const [partnerLastNameSignup, setPartnerLastNameSignup] = useState("");
  const [partnerNameSignup, setPartnerNameSignup] = useState("");
  // const [partnerEmailSignup, setPartnerEmailSignup] = useState("");
  // const [partnerPasswordSignup, setPartnerPasswordSignup] = useState("");
  const [partnerAddressSignup, setPartnerAddressSignup] = useState("");
  const [partnerABNSignup, setPartnerABNSignup] = useState("");
  const [partnerStateSignup, setPartnerStateSignup] = useState("");
  const [partnerCountrySignup, setPartnerCountrySignup] = useState("");
  const [partnerZipSignup, setPartnerZipSignup] = useState("");

  const [partnerLogoSignup, setPartnerLogoSignup] = useState("");
  const [PartnerModalTitle, setPartnerModalTitle] = useState("");
  const [PartnerModalButtonTitle, setPartnerModalButtonTitle] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(50);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //componentDidMount
  useEffect(() => {
    //Fill partner list
    const partnerData = () => {
      var partnerList = PartnerService.GetPartnerList();

      var details = partnerList
        .then((response) => {
          return response;
        })
        .then((data) => {
          setPartners(data.partnerDetails);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    //fill countries dropdown data

    const countryData = () => {
      var countrylist = PartnerService.GetCountryList();

      var details = countrylist
        .then((response) => {
          return response;
        })
        .then((data) => {
          setCountry(data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    partnerData();
    countryData();
  }, []);

  //add partner model
  const [addPartnerModalshow, setAddPartnerModalshow] = useState(false);
  const addPartnerModalClose = () => setAddPartnerModalshow(false);
  const addPartnerModal = () => setAddPartnerModalshow(true);

  //add credits partner model
  const [addCreditPartnerModalshow, setAddCreditPartnerModalshow] =
    useState(false);
  const addCreditPartnerModalClose = () => setAddCreditPartnerModalshow(false);
  const addCreditDetailPartnerModal = () => setAddCreditPartnerModalshow(true);

  const addCreditPartnerModal = (id) => {
    setPartnerModalTitle("Add Credits For Partner");
    setPartnerModalButtonTitle("Add Credits");
    setAddCreditPartnerModalshow(true);
    setPartnerId(id);

    setPartnerAddCreditsSignUp("");
    setMessage("");
    setPartnerAddMessage("");

    console.log(currentUser.id);
  };

  //close the add credits Partner modal
  const addCreditPartnersDetailsModalClose = () => {
    setAddCreditPartnerModalshow(false);
    setPartnerAddMessage("");
  };

  const handleLinkClk = (id, name) => {
    console.log("partner id===" + id);
    props.history.push({
      pathname: "/PartnerView",
      state: { data: id, pName: name },
    });
  };

  const editPartnerModal = (id) => {
    setPartnerModalTitle("Edit Partner");
    setPartnerModalButtonTitle("Save Changes");
    var partnerDetails = PartnerService.GetPartnerDetailsById(id);
    partnerDetails
      .then((response) => {
        return response;
      })
      .then((data) => {
        //setPartnerFirstNameSignup(data.PartnerFirstName);
        // setPartnerLastNameSignup(data.PartnerLastName);
        setPartnerNameSignup(data.PartnerName);
        // setPartnerEmailSignup(data.PartnerEmail);
        //  setPartnerPasswordSignup(data.PartnerPassword);
        setPartnerAddressSignup(data.PartnerAddress);
        setPartnerABNSignup(data.PartnerAbn);
        setPartnerStateSignup(data.PartnerState);
        setPartnerCountrySignup(data.PartnerCountry);
        setPartnerZipSignup(data.PartnerZipCode);

                setPartnerId(data.PartnerId)

                setAddPartnerModalshow(true);
                setPartnerId(id);
            })
            .catch(e => {
                toast.error("There is some error fetching the details of the partner", { position: toast.POSITION.TOP_CENTER });
                console.log(e);
            })


    };

  //close the add Partner modal
  const addPartnersDetailsModalClose = () => {
    setAddPartnerModalshow(false);
    setPartnerAddMessage("");
  };

  //displays the add partner modal
  const addPartnersDetailsModalShow = () => {
    setAddPartnerModalshow(true);
    setPartnerAddMessage("");
    setPartnerModalTitle("Add Partner");
    setPartnerModalButtonTitle("Add Partner");

    // setPartnerFirstNameSignup("");
    //  setPartnerLastNameSignup("");
    setPartnerNameSignup("");
    //  setPartnerEmailSignup("");
    //  setPartnerPasswordSignup("");
    setPartnerAddressSignup("");
    setPartnerABNSignup("");
    setPartnerStateSignup("");
    setPartnerCountrySignup("");
    setPartnerZipSignup("");

    setPartnerId("0");
    setMessage("");
  };

  // add or updates the partner, if the partner id is null/0 then inserts the partner else updates
  const addPartnerDetails = () => {
    if (
      partnerNameSignup !== "" &&
      partnerAddressSignup !== "" &&
      partnerABNSignup !== "" &&
      partnerStateSignup !== "" &&
      partnerCountrySignup !== "" &&
      partnerZipSignup !== "" &&
      partnerId
    ) {
      if (partnerId === "0") {
        if (partnerLogoSignup !== "") {
          PartnerService.AddPartnerDetails(
            partnerNameSignup,
            partnerLogoSignup,
            partnerAddressSignup,
            partnerABNSignup,
            partnerStateSignup,
            partnerCountrySignup,
            partnerZipSignup,
            partnerId
          ).then((response) => {
            if (response.data.Status === "Success") {
              const partnerData = PartnerService.GetPartnerList();

              var details = partnerData
                .then((response) => {
                  return response;
                })
                .then((data) => {
                  setPartners(data.partnerDetails);
                })
                .catch((e) => {
                  console.log(e);
                });

                                    setAddPartnerModalshow(false);
                                    if (partnerId === "0") {
                                        toast.success("Partner Added Successfully", { position: toast.POSITION.TOP_CENTER });
                                    }
                                    else {
                                        toast.success("Partner Updated Successfully", { position: toast.POSITION.TOP_CENTER });
                                    }
                                }
                                else {
                                    setAddPartnerModalshow(true);
                                    toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER });
                                }
                            }
                        );
                }
            }
            else {
                PartnerService.AddPartnerDetails(partnerNameSignup, partnerLogoSignup, partnerAddressSignup,
                    partnerABNSignup, partnerStateSignup, partnerCountrySignup, partnerZipSignup, partnerId).then(
                        (response) => {
                            if (response.data.Status === "Success") {
                                const partnerData = PartnerService.GetPartnerList();

            var details = partnerData
              .then((response) => {
                return response;
              })
              .then((data) => {
                setPartners(data.partnerDetails);
              })
              .catch((e) => {
                console.log(e);
              });

                                setAddPartnerModalshow(false);
                                if (partnerId === "0") {
                                    toast.success("Partner Added Successfully", { position: toast.POSITION.TOP_CENTER });
                                }
                                else {
                                    toast.success("Partner Updated Successfully", { position: toast.POSITION.TOP_CENTER });
                                }
                            }
                            else {
                                setAddPartnerModalshow(true);
                                toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER });
                            }
                        }
                    );
            }
        }

        else {

            setAddPartnerModalshow(true);
            toast.error("Kindly fill all the fields", { position: toast.POSITION.TOP_CENTER });
        }
    };



  //const onChangePartnerFirstNameSignup = (e) => {
  //    const partnerFirstName = e.target.value;
  //    setPartnerFirstNameSignup(partnerFirstName);
  //};

  //const onChangePartnerLastNameSignup = (e) => {
  //    const partnerLastName = e.target.value;
  //    setPartnerLastNameSignup(partnerLastName);
  //};

  const onChangePartnerNameSignup = (e) => {
    const partnerName = e.target.value;
    setPartnerNameSignup(partnerName);
  };

  //const onChangeEmailSignup = (e) => {
  //    const partnerEmail = e.target.value;
  //    setPartnerEmailSignup(partnerEmail);
  //};

  //const onChangePasswordSignup = (e) => {
  //    const partnerPassword = e.target.value;
  //    setPartnerPasswordSignup(partnerPassword);
  //};

  const onChangePartnerAddressSignup = (e) => {
    const partnerAddress = e.target.value;
    setPartnerAddressSignup(partnerAddress);
  };

  const onChangePartnerABN = (e) => {
    const partnerABN = e.target.value;
    setPartnerABNSignup(partnerABN);
  };

  const onChangePartnerStateSignup = (e) => {
    const partnerState = e.target.value;
    setPartnerStateSignup(partnerState);
  };

  const onChangePartnerCountrySignup = (e) => {
    const partnerCountry = e.target.value;
    setPartnerCountrySignup(partnerCountry);
  };

  const onChangePartnerZipSignup = (e) => {
    const partnerZip = e.target.value;
    setPartnerZipSignup(partnerZip);
  };

  const onChangeLogoSignup = (e) => {
    const partnerlogo = e.target.files[0];
    setPartnerLogoSignup(partnerlogo);
  };

  //for add credits partner
  const onChangePartnerAddCreditsSignup = (e) => {
    const partnerCredits = e.target.value;
    setPartnerAddCreditsSignUp(partnerCredits);
  };

  //code for add  credits for partners

  const addCreditDetailsOfPartner = () => {
    //code for credit details

        var currentUserId = currentUser.id;
        if (partnerAddCreditsSignUp !== "" && partnerId) {

            PartnerService.AddPartnerCreditDetails(partnerAddCreditsSignUp, partnerId, currentUserId).then(
                (response) => {
                    if (response.data.Status === "Success") {
                        setAddCreditPartnerModalshow(false);
                        toast.success("Credit Successfully Added Into Partner Account !!", { position: toast.POSITION.TOP_CENTER });
                    }
                }
            );
        }

    };

  if (currentUser || currentCompany) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="mb-0 d-flex align-items-center">
            <h2 className="d-inline-block mr-3 mb-0">Partners</h2>
            <button
              className="p-2 text-white bg-primary rounded-circle"
              onClick={addPartnersDetailsModalShow}
            >
              <Plus size={28} />
            </button>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {/*model for add partner */}
          <Modal
            show={addPartnerModalshow}
            onHide={addPartnersDetailsModalClose}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>{PartnerModalTitle}</Modal.Title>
              {"\n"}
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  {/* <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="first-name"
                                                placeholder="Enter your first name" onChange={onChangePartnerFirstNameSignup}
                                                value={partnerFirstNameSignup}
                                            />
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="last-name"
                                                placeholder="Enter your last name" onChange={onChangePartnerLastNameSignup}
                                                value={partnerLastNameSignup}
                                            />
                                        </FormGroup>
                                    </Col> */}


                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="partner-name"
                                                placeholder="Enter partner name" onChange={onChangePartnerNameSignup}
                                                value={partnerNameSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                  <Col xs={6} md={6}>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="file"
                        name="logo"
                        onChange={onChangeLogoSignup}
                      />
                    </FormGroup>
                  </Col>

                  {/*<Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                onChange={onChangeEmailSignup}
                                                value={partnerEmailSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="password"
                                                name="password"
                                                placeholder="Enter your password"
                                                onChange={onChangePasswordSignup}
                                                value={partnerPasswordSignup}
                                            />
                                        </FormGroup>
                                    </Col>*/}


                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="address"
                                                placeholder="Address"
                                                onChange={onChangePartnerAddressSignup}
                                                value={partnerAddressSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                  <Col xs={6} md={6}>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="abn"
                        placeholder="ABN"
                        onChange={onChangePartnerABN}
                        value={partnerABNSignup}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={6} md={6}>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="state"
                        placeholder="State"
                        onChange={onChangePartnerStateSignup}
                        value={partnerStateSignup}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={6} md={6}>
                    <FormGroup>
                      Choose Country :
                      <select
                        className="custom-select"
                        value={partnerCountrySignup}
                        onChange={onChangePartnerCountrySignup}
                      >
                        {countries &&
                          countries.length > 0 &&
                          countries.map((schema) => {
                            return (
                              <option key={schema.id} value={schema.id}>
                                {schema.country_name}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={6} md={6}>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="zipcode"
                        placeholder="Zipcode"
                        onChange={onChangePartnerZipSignup}
                        value={partnerZipSignup}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              {partnerAddMessage && (
                <Alert variant="danger">{partnerAddMessage}</Alert>
              )}

              <Button variant="primary" onClick={addPartnerDetails}>
                {PartnerModalButtonTitle}
              </Button>
              <Button
                variant="secondary"
                onClick={addPartnersDetailsModalClose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/*model for add partner */}


                    {/*model for add credits partner */}
                    <Modal show={addCreditPartnerModalshow} onHide={addCreditPartnersDetailsModalClose} backdrop="static" >
                        <Modal.Header closeButton>
                            <Modal.Title>{PartnerModalTitle}</Modal.Title>{"\n"}

                        </Modal.Header>
                        <Modal.Body>

                            <Container>
                                <Row>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="partner-credits"
                                                placeholder="Enter partner credits" onChange={onChangePartnerAddCreditsSignup}
                                                value={partnerAddCreditsSignUp}
                                            />
                                        </FormGroup>
                                    </Col>


                                </Row>
                            </Container>

                        </Modal.Body>
                        <Modal.Footer>
                            {partnerAddMessage && <Alert variant="danger">{partnerAddMessage}</Alert>}

                            <Button variant="primary" onClick={addCreditDetailsOfPartner}>
                                {PartnerModalButtonTitle}
                            </Button>
                            <Button variant="secondary" onClick={addCreditPartnersDetailsModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/*model for add credit partner */}



                    {partnerMessage && <Alert variant="success" style={{ "width": "400px" }}>{partnerMessage}</Alert>}

          <Table striped hover>
            <thead>
              <tr>
                <th></th>
                <th>Partner Id</th>
                <th>Logo</th>
                <th>PartnerName</th>
                <th className="text-center">Edit</th>
                {currentUser.RoleId === 0 ? (
                  <th className="text-center">Add Credits</th>
                ) : (
                  <div></div>
                )}
                {currentUser.RoleId === 0 ? (
                  <th className="text-center">Remove</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {partners.slice(indexOfFirstPost, indexOfLastPost).map((ptnr) => {
                return (
                  <tr key={ptnr.Id}>
                    <td>
                      <label className="checkbox-container">
                        <input type="checkbox" checked="" />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                    <td>{ptnr.Id}</td>
                    <td>
                      <img
                        src={
                          "data:" +
                          ptnr.logoDetails.FileType +
                          ";base64," +
                          ptnr.logoDetails.FileContent
                        }
                        width="48"
                        height="48"
                        className="rounded-circle mr-2"
                        alt="Avatar"
                      />{" "}
                    </td>
                    <td>
                      <div
                        className="my-pointer"
                        onClick={() => handleLinkClk(ptnr.Id, ptnr.PartnerName)}
                      >
                        {" "}
                        {ptnr.PartnerName}{" "}
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn d-block mx-auto"
                        onClick={() => editPartnerModal(ptnr.Id)}
                      >
                        <img src={iconEdit} alt="" />
                      </button>
                    </td>

                    {currentUser.RoleId === 0 ? (
                      <td>
                        <button
                          className="btn text-primary d-block mx-auto"
                          onClick={() => addCreditPartnerModal(ptnr.Id)}
                        >
                          <ShoppingBag size={50} />
                        </button>
                      </td>
                    ) : (
                      <div></div>
                    )}

                    {currentUser.RoleId === 0 ? (
                      <td>
                        <button className="btn d-block mx-auto">
                          <img src={iconDeleteUser} alt="" />
                        </button>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={partners.length}
            paginate={paginate}
          />
          {/*Table for partner list*/}
        </CardBody>
      </Card>
    );
  } else {
    return <Card></Card>;
  }
};

const Tables = (props) => (
  <div id="partner_div">
    <Container fluid className="p-0">
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="ml-auto text-right mt-n1">
          <Button color="primary" className="shadow-sm mr-1">
            <Filter className="feather" />
          </Button>
          <Button color="primary" className="shadow-sm">
            <Settings className="feather" />
          </Button>
        </Col>
      </Row>

      <SgaPartnersList {...props} />
    </Container>
  </div>
);

export default Tables;
