
import React, { useState, useRef } from "react";
import { Button, Modal } from 'react-bootstrap';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import { Filter, Settings } from "react-feather";
import Watch from "../../../assets/img/icons/watch.png";
import AuthService from "../../../AuthServices/auth.service.js";
import { useEffect } from "react";
import assessmentService from "../../../AuthServices/assessment.service.js";
import { setInterval } from "timers";


const QuizView = (props) => {

    localStorage.removeItem("ReSubmitQuestionKey");
    localStorage.removeItem("AssessmentQuizKey");

    const [show, setShow] = useState(false);
    const [showTimesUp, setShowTimesUp] = useState(false);


    var currentUser = AuthService.getCurrentUser();
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        setShow(false);
        setShowTimesUp(false);
        props.history.push("/");
        window.location.reload();
    }
    var userId = currentUser.id;
    if (props.location.state == null || props.location.state == undefined) {
         
        setShow(false);
        setShowTimesUp(false);
        props.history.push({
            pathname: '/Assessments'
        })
        window.location.reload();
    }

    if (props.history.action == "POP") {
        var isKeyPresent = (JSON.parse(localStorage.getItem("ReviewAssessmentKey")));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: '/Assessments'
            })
            window.location.reload();
        }
    }

    //set questionObject from props
    var questions = props.location.state.questionsList;
    var assessmentId = props.location.state.assessmentId;
    var creditId = props.location.state.creditId;
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const TitleList = questions;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [seconds, setSeconds] = useState(null);

    const intervalRef = useRef();

    //let  = 0;
    var localTimeReview = Number(JSON.parse(localStorage.getItem("hourAssessment")));
    const ReviewQuestions = (count, question, optionList, assessmentId, selectedAnswer, assessmentName,title) => {
        //once the person click on change answer then it is redirected to other page for changing the answer.
        var time = Number(JSON.parse(localStorage.getItem("hourAssessment")));
        localStorage.setItem("hourAssessment", JSON.stringify(time));
        localStorage.setItem("ReSubmitQuestionOption", "true");
         
        clearInterval(intervalRef.current);
        localStorage.setItem("ReSubmitQuestionKey", "false");
        localStorage.removeItem("AssessmentQuizKey");
        localStorage.removeItem("ReviewAssessmentKey");
        props.history.push({
            pathname: '/ReSubmitQuestionOption',
            state: { currentCounter: count, optionList: optionList, question: question, assessmentId: assessmentId, selectedAnswer, AssessmentName: assessmentName, title: title, creditId: creditId }

        })
    }
    const handleFinishButton = () => {
        setShow(true);
    }
    const handleFinalSubmit = () => {
        setShow(false);
        localStorage.removeItem("hourAssessment");
        localStorage.removeItem('questionCounter');
        localStorage.removeItem('state');
        localStorage.removeItem('Title');
        localStorage.removeItem('AssessmentName');
        localStorage.removeItem('currentQuestionIndex');
        localStorage.removeItem('currentTitleIndex');
        localStorage.removeItem('checkedRadio');
        assessmentService.UpdateUserTestTable(userId, assessmentId,creditId).then(
            (response) => {
                if (response !== null) {
                    props.history.push({
                        pathname: '/Assessments'
                    })
                }
            });

    }

    useEffect(() => {
        //set the values after taking from the session/local storage

        assessmentService.GetCurrentAssessmentSessionValues(userId, assessmentId, creditId).then(
            (response) => {
                if (response !== null) {
                    var timeForStorage = Number(JSON.parse(localStorage.getItem("hourAssessment")));
                    if (timeForStorage != 0 && timeForStorage > 0) {
                        setSeconds(secondsToMinutesFormatting(timeForStorage));
                        localTimeReview = timeForStorage;
                    }
                    else {
                        setSeconds(secondsToMinutesFormatting(response.Create_Date));
                        localTimeReview = response.Create_Date;
                        localStorage.setItem("hourAssessment", JSON.stringify(localTimeReview));
                    }
                }
            });


    }, []);
    const secondsToMinutesFormatting = (seconds1) => {
        if (seconds1 < 0) {
            seconds1 = 0;
        }
        var sec_num = parseInt(seconds1, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }

    const SetTimeForTimer = () => {
        if (seconds == null) {
            assessmentService.GetPendingTimeForTimer(userId, assessmentId,creditId).then(
                (response) => {
                    if (response !== null) {
                        if (response == 0) {
                            //clearInterval(interval.current);

                            setShowTimesUp(true);
                            setTimeout(function () {
                                setShowTimesUp(false);
                                props.history.push({
                                    pathname: '/Assessments'
                                })
                                window.location.reload();
                            }, 2000);
                        } else {
                             
                            var timeForStorage = Number(JSON.parse(localStorage.getItem("hourAssessment")));
                            if (timeForStorage != 0 && timeForStorage > 0) {
                                setSeconds(secondsToMinutesFormatting(timeForStorage));
                                localTimeReview=timeForStorage;
                            }
                            else {
                                 
                                setSeconds(secondsToMinutesFormatting(response));
                                localTimeReview = response;
                                localStorage.setItem("hourAssessment", JSON.stringify(response));
                            }
                        }
                    }
                });
        }
    }


    useEffect(() => {
        if (localTimeReview==undefined) {
             
            SetTimeForTimer();
        }
    }, []);

    useEffect(() => {
         
        clearInterval(Number(JSON.parse(localStorage.getItem("ReviewTimer"))));
        clearInterval(Number(JSON.parse(localStorage.getItem("ResubmitTimer"))));

       // alert("in use effect");
        const startTimerAgain = () => {
            if (window.location.href.indexOf("ReviewAssessment") > -1) {
                if (localTimeReview > 0 && localTimeReview != undefined) {
                    //var test = localTimeReview;
                    //test = localTimeReview - 1;
                    localTimeReview = localTimeReview - 1;
                    setSeconds(secondsToMinutesFormatting(localTimeReview));
                    localStorage.setItem("hourAssessment", JSON.stringify(localTimeReview));
                }
                else {
                    setSeconds(secondsToMinutesFormatting(0));
                    localTimeReview = 0;
                    localStorage.removeItem("hourAssessment");
                }

                if (localTimeReview % 10 == 0 && window.location.href.indexOf("ReviewAssessment") > -1) {
                    assessmentService.SetPendingTimeForTimer(userId, assessmentId,creditId).then(
                        (response) => {
                            if (response !== null && response != 0) {

                                var timeForStorage = Number(JSON.parse(localStorage.getItem("hourAssessment")));
                                if (timeForStorage != 0 && timeForStorage > 0) {
                                    setSeconds(secondsToMinutesFormatting(timeForStorage));
                                    localTimeReview = timeForStorage;
                                    localStorage.setItem("hourAssessment", JSON.stringify(localTimeReview));
                                } else {
                                    setSeconds(secondsToMinutesFormatting(response));
                                    localTimeReview = response;
                                    localStorage.setItem("hourAssessment", JSON.stringify(localTimeReview));

                                }

                            }
                            else {
                                assessmentService.UpdateUserTestTable(userId, assessmentId,creditId).then(
                                    (response) => {
                                        if (response !== null) {
                                            setSeconds(secondsToMinutesFormatting(0));
                                            localStorage.removeItem("hourAssessment");
                                            localTimeReview = 0;
                                            // clearInterval(interval.current);
                                            setShowTimesUp(true);
                                            setTimeout(function () {
                                                setSeconds(secondsToMinutesFormatting(0));
                                                localStorage.removeItem("hourAssessment");
                                                localTimeReview = 0;
                                                setShowTimesUp(false);
                                                props.history.push({
                                                    pathname: '/Assessments'
                                                })
                                                window.location.reload();
                                            }, 2000);
                                        }
                                    });
                            }
                        });

                }
            }
        }
        intervalRef.current = setInterval(startTimerAgain, 1000);
        localStorage.setItem("ReviewTimer", JSON.stringify(intervalRef.current._id));
        return () => clearInterval(intervalRef.current);
    }, []);
    var count = 0;

    return (
        <Row>
            <Col md="12">
                <div className="container">
                    <div className="bloc-Mytabs">
                        <button
                            className={toggleState === 1 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(1)} >
                            Home
                                 </button>
                        <button
                            className={toggleState === 2 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(2)}>
                            Assessments
                                </button>
                        <button
                            className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(3)}>
                            User Analytics
                                </button>
                    </div>

                    <div className="content-Mytabs">
                        <div
                            className={toggleState === 1 ? "content-t  active-content-t" : "content-t"} >

                            {/*......................................Assessment Quiz View .......................................*/}
                            <Row>
                                <Modal size="lg" show={showTimesUp} backdrop="static" onHide={handleClose} className="modal-colored modal-danger">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Time over!!</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="text-center m-3 modal-body">
                                            <p className="mb-0 text-white"> Your assessment will now be automatically submitted!!</p>
                                        </div>

                                    </Modal.Body>
                                </Modal>

                                <Modal size="md" show={show} onHide={handleClose} backdrop="static" className="modal-colored modal-warning">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Are you sure you want to submit the assessement?</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="text-center m-3 modal-body">
                                            <p className="mb-0 text-white"> Once you submit the assessment, you cannot modify the answers. Do you want to continue?</p>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn-light" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button className="btn-light" onClick={handleFinalSubmit}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <div className="col-lg-8 col-md-8">

                                    <Card className="my-card">
                                        <CardBody>
                                            <Row>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="float-left">
                                                        <h4 className="my-h4-bold">Review Assessment</h4>
                                                        <div className="scrollable">
                                                            <div className="text-left">
                                                                {TitleList.TitleList.map((x) => (
                                                                    <div>  <h5 className="right-sidebar-h5">{x.TitleName}</h5><hr className="my-hr" />
                                                                        {x.QuestionList.map((p) => (<Card className="" ><div className="quiz-question-h5">Question {count += 1}: {p.Questions}</div>
                                                                            <hr className="my-hr" />
                                                                            <div className="quiz-question-h5">Selected Answer:</div>{p.OptionsWithScore.filter(u => u.OptionsId == p.SelectedAnswer)[0].Options}<br /><br /> <button id="review-button" className="quiz-submit-btn btn-pill reviewButton" onClick={() => ReviewQuestions(p.QuestionId, p.Questions, p.OptionsWithScore, TitleList.AssessmentId, p.SelectedAnswer, TitleList.AssessmentName, TitleList.TitleList[0].TitleName)}>Change Answer</button>
                                                                            <br />
                                                                            <hr className="my-hr" />
                                                                        </Card>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <Card className="my-card">
                                        <CardHeader>
                                            <Row>
                                                <div className="col-lg-12 col-md-12">
                                                    <Row>
                                                        <Col sm={4} md={4}>
                                                            <img
                                                                src={Watch}
                                                                className="img-responsive mt-2"
                                                                width="50"
                                                                height="50"
                                                            />
                                                        </Col>
                                                        <Col sm={8} md={8}>
                                                            <div>
                                                                <p>Your time left</p>
                                                                <h2>{seconds}</h2>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>

                                        </CardHeader>
                                        <hr className="my-hr" />
                                        <br />
                                        <button id="submit-button" className="quiz-submit-btn btn-pill" style={{ marginLeft: "20%", marginRight: "20%" }} onClick={handleFinishButton}>Submit Assessment</button>
                                        <CardBody>
                                            <Row>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Row>

                        </div>

                        <div
                            className={toggleState === 2 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Team Assessments</h2>
                            <hr />

                        </div>

                        <div
                            className={toggleState === 3 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Organization Assessments</h2>
                            <hr />

                        </div>
                        <div
                            className={toggleState === 4 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Partner Assessments</h2>
                            <hr />

                        </div>
                    </div>
                </div>
            </Col>


        </Row>

    );
}

const AssessmentQuiz = (props) => (
    <>
        <Row className="mb-2 mb-xl-4">


            <Col xs="auto" className="ml-auto text-right mt-n1">


                <Button color="primary" className="shadow-sm mr-1">
                    <Filter className="feather" />
                </Button>
                <Button color="primary" className="shadow-sm">
                    <Settings className="feather" />
                </Button>

            </Col>
        </Row>
        <QuizView {...props} />
    </>
);



export default AssessmentQuiz;
