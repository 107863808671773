import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
    loginPanel as loginPanelRoutes,
    companyLoginPanel as companyLoginRoutes,
    thankYou as thankYouRoutes,
    companyThankYou as companyThankYouRoutes,
    dashboard as dashboardRoutes,
    page as pageRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import ScrollToTop from "../components/ScrollToTop";



const childRoutes = (Layout, routes) =>
    routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
                // Route item without children
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            )
    );

const Routes = () => (
    <Router>
        <ScrollToTop>
            <Switch>
                {childRoutes(AuthLayout, loginPanelRoutes)}
                {childRoutes(AuthLayout, companyLoginRoutes)}
                {childRoutes(AuthLayout, thankYouRoutes)}
                {childRoutes(AuthLayout, companyThankYouRoutes)}

                {childRoutes(DashboardLayout, dashboardRoutes)}
                {childRoutes(AuthLayout, pageRoutes)}
                <Route
                    render={() => (
                        <AuthLayout>
                            <Page404 />
                        </AuthLayout>
                    )}
                />
            </Switch>
        </ScrollToTop>
    </Router>
);

export default Routes;
