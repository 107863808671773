import React from "react";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Media,
    Col,
    Container,
    Row
} from "reactstrap";

import {
    faBuilding,
    faUser,
    faUserCircle,
    faMale,
    faFlagCheckered,
    faQuestionCircle,
    faNewspaper,
    faShoppingBag,
    faCommentAlt,
    faChartBar,
    faBookOpen
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const SettingsOptions = () => (
    <Card>
        
        <CardBody>
            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faUserCircle}
                    fixedWidth
                />
                <span className="align-middle">My Profile</span>
            </div>

            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faBuilding}
                    fixedWidth
                />
                <span className="align-middle">My Company</span>
            </div>

            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faUser}
                    fixedWidth
                />
                <span className="align-middle">My Account</span>
            </div>

            <div className="mb-2">
                <hr className="my-0" />
            </div>

            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faFlagCheckered}
                    fixedWidth
                />
                <span className="align-middle">Assessments</span>
            </div>

            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faQuestionCircle}
                    fixedWidth
                />
                <span className="align-middle">Questions</span>
            </div>

            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faNewspaper}
                    fixedWidth
                />
                <span className="align-middle">Reports</span>
            </div>

            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faBookOpen}
                    fixedWidth
                />
                <span className="align-middle">Courses</span>
            </div>

            <div className="mb-2">
                <hr className="my-0" />
            </div>


            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faShoppingBag}
                    fixedWidth
                />
                <span className="align-middle">Job Roles</span>
            </div>

            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faCommentAlt}
                    fixedWidth
                />
                <span className="align-middle">Topics</span>
            </div>

            <div className="mb-2">
                <FontAwesomeIcon
                    className="align-middle mr-2 "
                    icon={faChartBar}
                    fixedWidth
                />
                <span className="align-middle">Capabilities</span>
            </div>

        </CardBody>

      


    </Card>
);



const Activities = () => {
   

    return (
        <Container fluid className="p-0">
            <Row>
                <Col md="6" xl>
                    <Card className="flex-fill">
                        <CardBody className="py-4">
                            <Media>

                                <Media body>
                                    <h5 className="mb-2"></h5>
                                    <h3 className="mb-2"></h3>
                                    <div className="mb-0"></div>
                                </Media>
                            </Media>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="6" xl>
                    <Card className="flex-fill">
                        <CardBody className="py-4">
                            <Media>

                            </Media>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="6" xl>
                    <Card className="flex-fill">
                        <CardBody className="py-4">
                            <Media>

                            </Media>
                        </CardBody>
                    </Card>
                </Col>
               

            </Row>

            <Row>
                <Col md="7" >
                    <Card className="flex-fill">
                        <CardBody className="py-4">
                            <Media>

                                <Media body>
                                    <h5 className="mb-2"></h5>
                                    <h3 className="mb-2"></h3>
                                    <div className="mb-0"> </div>
                                </Media>
                            </Media>
                        </CardBody>
                    </Card>
                </Col>

                <Col md="5">
                    <Card className="flex-fill">
                        <CardBody className="py-4">
                            <Media>

                                <Media body>
                                    <h5 className="mb-2"></h5>
                                    <h3 className="mb-2"></h3>
                                    <div className="mb-0"></div>
                                </Media>
                            </Media>
                        </CardBody>
                    </Card>
                </Col>

               
            </Row>
        </Container>
    );
};

const Settings = () => (
    <div id="setting_div">
    <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-4">
            <h2>Settings</h2>
        </Row>

        <Row>
            <Col md="4" xl="3">
                <SettingsOptions />
            </Col>
            <Col md="8" xl="9">
                <Activities />
            </Col>
        </Row>
        </Container>
    </div>
);

export default Settings;
