import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Container,
  Col,
  Row,
  Table,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import DeleteConfirmation from "../../../../src/components/DeleteConfirmation";
import { Filter, Settings, Link, Plus, X, Search } from "react-feather";

import { Button, Modal, Alert } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSort,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

import AuthService from "../../../AuthServices/auth.service.js";
import TeamsService from "../../../AuthServices/teams.service.js";
import PartnerService from "../../../AuthServices/partners.service";

import { withRouter } from "react-router-dom";
import TeamLeaderView from "../teamdata/TeamLeaderView";

// SVG Icons
import iconEdit from '../../../assets/img/icons/edit.svg';
import iconDelete from '../../../assets/img/icons/delete.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

{
  /*...................................................Team list code part..............................................................*/
}
const TeamsList = (props) => {
  var currentUser = AuthService.getCurrentUser();
  var currentCompany = AuthService.getCurrentCompany();

  if (currentUser == null || currentUser == undefined) {
    props.history.push("/");
    window.location.reload();
  }
  const [teams, setTeams] = useState([]);
  const [id, setId] = useState(null);
  const [type, setType] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [teamMessage, setMessage] = useState(null);
  const [teamAddMessage, setTeamAddMessage] = useState(null);

  const [teamNameSignup, setTeamNameSignup] = useState("");
  const [teamId, setTeamId] = useState("");
  const [TeamModalTitle, setTeamModalTitle] = useState("");
  const [TeamModalButtonText, setTeamModalButtonText] = useState("");

  //const [users, setUsers] = useState("");
  const [userId, setUserId] = useState(1);

  // const [partners, setPartners] = useState("");
  const [partnerId, setPartnerId] = useState(0);

  //const [companies, setCompanies] = useState("");
  const [companyId, setCompanyId] = useState(0);

  //use for drop down cascade

  const [partners, setPartners] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);

  const [disabledPartner, setDisabledPartner] = useState(true);
  const [disabledCompany, setDisabledCompany] = useState(true);
  const [disabledUser, setDisabledUser] = useState(true);
  const [arrowPositionTeamName, setArrowPositionTeamName] = useState(faSort);
  const [arrowPositionCompany, setArrowPositionCompany] = useState(faSort);
  const [arrowPositionPartner, setArrowPositionPartner] = useState(faSort);
  const [state, setState] = useState({
    data: [],
    sort: {
      column: null,
      direction: "desc",
    },
  });
  //componentDidMount
  useEffect(() => {
    //Fill team list
    //
    const teamData = () => {
      var teamsItemList = TeamsService.GetTeamList();

      var details = teamsItemList
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (currentUser.roleName === "company") {
            setTeams(
              data.teamDetails.filter(
                (x) => x.CompanyId === currentUser.company_id
              )
            );
            setState({
              data: data.teamDetails.filter(
                (x) => x.CompanyId === currentUser.company_id
              ),
            });
          } else if (currentUser.roleName === "partner") {
            setTeams(
              data.teamDetails.filter(
                (x) => x.PartnerId === currentUser.partner_id
              )
            );
            setState({
              data: data.teamDetails.filter(
                (x) => x.PartnerId === currentUser.partner_id
              ),
            });
          } else {
            setTeams(data.teamDetails);
            setState({ data: data.teamDetails });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    //fill partners dropdown data

        const partnerData = () => {
            var partnerDDList = PartnerService.GetPartnerList();
            var details = partnerDDList.then((response) => {
                return response;
            })
                .then((data) => {
                    if (currentUser.roleName === "partner" || currentUser.roleName === "company") {
                        setPartners(data.partnerDetails.filter(x => x.Id === currentUser.partner_id));
                    }
                    else {
                        setPartners(data.partnerDetails);

                    }
                })
                .catch(e => {
                    console.log(e);
                })
        };

    teamData();
    partnerData();
  }, []);

  //add team modal
  const [addTeamModalshow, setAddTeamModalshow] = useState(false);
  const addTeamModalClose = () => setAddTeamModalshow(false);

  const showDeleteModal = (type, id) => {
    //setting type in case to make this reusable , here setting team , can be set to user etc
    setType(type);
    //setting the id of the team
    setId(id);
    setDeleteMessage(
      `Are you sure you want to delete the team  '${
        teams.find((x) => x.Id === id).TeamName
      }'?`
    );
    setDisplayConfirmationModal(true);
  };

  const handlePartnerChange = (e) => {
    if (e.target.value > 0) {
      setPartnerId(e.target.value);

      let value = e.target.value;
      var ptnrId = value;

            if (ptnrId.length === 0) {
                setDisabledCompany(true);
            }

            else {
                TeamsService.getCompanyDetailsByPartnerIdTeamViewDropDown(ptnrId).then(
                    (response) => {

                        if (response !== null) {
                            if (currentUser.roleName === "company") {

                                setCompanies(response.filter(x => x.Id === currentUser.company_id));
                            }
                            else {
                                setCompanies(response);

                            }
                            setCompanyId(0)
                            setDisabledCompany(false);
                            //console.log(companies);
                        }
                    }
                ).catch(e => {
                    console.log(e);
                });
            }


        }
        else {
            setPartnerId(0);
            setCompanyId(0)
            setDisabledCompany(true);
            setUserId(0)
            setDisabledUser(true);
        }

    };

  const handleCompanyChange = (e) => {
    if (e.target.value > 0) {
      setCompanyId(e.target.value);

      let value = e.target.value;
      var usrId = value;

      if (usrId === 0) {
        setDisabledPartner(true);
      } else {
        TeamsService.getUserDetailsByCompanyIdTeamViewDropDown(usrId)
          .then((response) => {
            if (response !== null) {
              setUsers(response);
              setUserId(0);
              setDisabledUser(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      setUserId(0);
      setDisabledUser(true);
    }
  };

  const handleUserChange = (e) => {
    setUserId(e.target.value);
  };

  const editTeamModal = (id) => {
    setDisabledPartner(true);
    setDisabledCompany(true);
    setDisabledUser(false);

    setTeamModalTitle("Edit Team");
    setTeamModalButtonText("Save Changes");
    var teamDetails = TeamsService.GetTeamDetailsById(id);
    teamDetails
      .then((response) => {
        console.log(response);
        return response;
      })
      .then((data) => {
        setPartnerId(data.PartnerId);

        TeamsService.getCompanyDetailsByPartnerIdTeamViewDropDown(
          data.PartnerId
        )
          .then((response) => {
            if (response !== null) {
              if (currentUser.roleName === "company") {
                setCompanies(
                  response.filter((x) => x.Id === currentUser.company_id)
                );
              } else {
                setCompanies(response);
              }
              setCompanyId(data.CompanyId);

              //console.log(companies);
            }
          })
          .catch((e) => {
            console.log(e);
          });

        TeamsService.getUserDetailsByCompanyIdTeamViewDropDown(data.CompanyId)
          .then((response) => {
            if (response !== null) {
              setUsers(response);
              setUserId(data.TeamLeaderId);
              // setDisabledUser(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });

                //setSelectedPartner(data.PartnerId);
                console.log("edit "+data.PartnerId);
                setTeamNameSignup(data.TeamName);
                setTeamId(data.TeamId)
                setAddTeamModalshow(true);
                setTeamId(id);

            })
            .catch(e => {
                toast.error("There is some error fetching the details of the team", { position: toast.POSITION.TOP_CENTER })
                console.log(e);
            })
    };

    const submitDelete = (type, id) => {
        if (type === "team") {
            TeamsService.DeleteTeamById(id);
            toast.success(`The Team '${teams.find((x) => x.Id === id).TeamName}'` , { position: toast.POSITION.TOP_CENTER })
            setTeams(teams.filter((team) => team.Id !== id));
        }
        setDisplayConfirmationModal(false);
    };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  //close the add team modal
  const addTeamDetailsModalClose = () => {
    setAddTeamModalshow(false);
    setTeamAddMessage("");
  };

  //displays the add team modal
  const addTeamDetailsModalShow = () => {
    setAddTeamModalshow(true);
    setTeamAddMessage("");
    setTeamModalTitle("Add Team");
    setTeamModalButtonText("Add Team");
    setTeamNameSignup("");
    setTeamId("0");
    setMessage("");
    setPartnerId(0);
    setCompanyId(0);
    setDisabledPartner(false);
    setDisabledUser(true);
    setDisabledCompany(true);
  };

  // add or updates the team, if the team id is null/0 then inserts the team else updates
  const addTeamDetails = () => {
    if (teamNameSignup !== "" && teamId) {
      TeamsService.AddTeamDetails(
        teamNameSignup,
        teamId,
        partnerId,
        companyId,
        userId
      ).then((response) => {
        if (response.data.Status === "Success") {
          const teamData = TeamsService.GetTeamList();

                            var details = teamData.then((response) => {
                                return response;
                            })
                                .then((data) => {
                                    if (currentUser.roleName === "company") {
                                        setTeams(data.teamDetails.filter(x => x.CompanyId === currentUser.company_id));
                                        setState({ data: data.teamDetails.filter(x => x.CompanyId === currentUser.company_id) });
                                    }
                                    else if (currentUser.roleName === "partner") {
                                        setTeams(data.teamDetails.filter(x => x.PartnerId === currentUser.partner_id));
                                        setState({ data: data.teamDetails.filter(x => x.PartnerId === currentUser.partner_id) });
                                    }
                                    else {
                                        setTeams(data.teamDetails);
                                        setState({ data: data.teamDetails });
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                })


                            setAddTeamModalshow(false);
                            if (teamId === "0") {
                                toast.success("Team Added Successfully", { position: toast.POSITION.TOP_CENTER })
                            }
                            else {
                                toast.success("Team Updated Successfully", { position: toast.POSITION.TOP_CENTER })
                            }
                        }
                        else {
                            setAddTeamModalshow(true);
                            toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER })
                        }
                    }
                );

            }

        else {

            setAddTeamModalshow(true);
            toast.error('Kindly fill all the fields', { position: toast.POSITION.TOP_CENTER })
        }
    };

  const onChangeTeamNameSignup = (e) => {
    const teamName = e.target.value;
    setTeamNameSignup(teamName);
  };

  const handleLinkClk = (id) => {
    console.log("TeamId===" + id);
    // window.location.href = "http://localhost:3000/teacmLeaderView?teamId=" + id;
    props.history.push({
      pathname: "/teamLeaderView",
      state: { data: id },
    });
  };

  {
    /*...................................................End Team list code part..............................................................*/
  }

  {
    /*...............................filter view  section code........................................................*/
  }

  const [filterViewShow, setFilterViewShow] = useState(false);
  const [filterClientName, setFilterClientName] = useState(0);
  const [filterPartnerName, setFilterPartnerName] = useState(0);
  const [filterTeamName, setFilterTeamName] = useState(0);
  const onFilterButtonClick = () => {
    let data1 = teams;
    if (filterPartnerName != 0) {
      data1 = data1.filter((x) => x.PartnerName == filterPartnerName);
    }
    if (filterClientName != 0) {
      data1 = data1.filter((x) => x.CompanyName == filterClientName);
    }
    if (filterTeamName != 0) {
      data1 = data1.filter((x) => x.TeamName == filterTeamName);
    }
    setState({ data: data1 });
  };
  const onChangefilterPartnerName = (e) => {
    setFilterPartnerName(e.target.value);
  };

  const onChangefilterTeamName = (e) => {
    setFilterTeamName(e.target.value);
  };

  const onChangefilterClientName = (e) => {
    setFilterClientName(e.target.value);
  };

  const filterView = () => {
    setFilterViewShow((s) => !s);
  };

  const onSort = (column) => {
    const direction =
      state.sort != undefined && state.sort.column
        ? state.sort.direction === "asc"
          ? "desc"
          : "asc"
        : "desc";
    const sortedData = state.data.sort((a, b) => {
      if (column === "TeamName") {
        if (direction === "desc") {
          setArrowPositionTeamName(faSortAlphaUp);
        } else {
          setArrowPositionTeamName(faSortAlphaDown);
        }
        const nameA = a.TeamName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.TeamName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
      if (column === "Company") {
        if (direction === "desc") {
          setArrowPositionCompany(faSortAlphaUp);
        } else {
          setArrowPositionCompany(faSortAlphaDown);
        }
        const nameA = a.CompanyName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.CompanyName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
      if (column === "Partner") {
        if (direction === "desc") {
          setArrowPositionPartner(faSortAlphaUp);
        } else {
          setArrowPositionPartner(faSortAlphaDown);
        }
        const nameA = a.PartnerName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.PartnerName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    });

    if (direction === "desc") {
      setTeams(sortedData.reverse());
    }
    setState({
      data: sortedData,
      sort: {
        column,
        direction,
      },
    });
  };

  {
    /*............................... End filter view  section........................................................*/
  }

  if (currentUser || currentCompany) {
    return (
      <>
        <Row className="mb-2 mb-xl-4">
          <Col xs="auto" className="ml-auto text-right mt-n1">
            <Button
              color="primary"
              className="shadow-sm mr-1"
              onClick={filterView}
            >
              <Filter className="feather" />
            </Button>

            <Button color="primary" className="shadow-sm">
              <Settings className="feather" />
            </Button>
          </Col>
        </Row>

        <Row>
          <Col md={filterViewShow ? "9" : "12"}>
            <Card>
              <CardHeader>
                <CardTitle className="mb-0 d-flex align-items-center">
                  <h2 className="d-inline-block mr-3 mb-0">Teams</h2>
                  <button
                    className="p-2 text-white bg-primary rounded-circle"
                    onClick={addTeamDetailsModalShow}
                  >
                    <Plus size={28} />
                  </button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {/*model for add team */}
                    <Modal show={addTeamModalshow} onHide={addTeamDetailsModalClose} backdrop="static" >
                  <Modal.Header closeButton>
                    <Modal.Title>{TeamModalTitle}</Modal.Title>
                    {"\n"}
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            Choose Partner :
                            <select
                              disabled={disabledPartner}
                              className="custom-select"
                              value={partnerId}
                              onChange={handlePartnerChange}
                            >
                              <option key="0">Select partner</option>
                              {partners &&
                                partners.length > 0 &&
                                partners.map((schema) => {
                                  return (
                                    <option key={schema.Id} value={schema.Id}>
                                      {schema.PartnerName}
                                    </option>
                                  );
                                })}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col xs={6} md={6}>
                          <FormGroup>
                            Choose Company :
                            <select
                              disabled={disabledCompany}
                              className="custom-select"
                              value={companyId}
                              onChange={handleCompanyChange}
                            >
                              <option key="0">Select company</option>
                              {companies &&
                                companies.length > 0 &&
                                companies.map((schema) => {
                                  return (
                                    <option key={schema.Id} value={schema.Id}>
                                      {schema.CompanyName}
                                    </option>
                                  );
                                })}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col xs={6} md={6}>
                          <FormGroup>
                            Choose Team Leader :
                            <select
                              disabled={disabledUser}
                              className="custom-select"
                              value={userId}
                              onChange={handleUserChange}
                            >
                              <option key="0">Select user</option>
                              {users &&
                                users.length > 0 &&
                                users.map((schema) => {
                                  return (
                                    <option key={schema.Id} value={schema.Id}>
                                      {schema.UserName}
                                    </option>
                                  );
                                })}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col xs={6} md={6}>
                          <FormGroup>
                            <br />
                            <Input
                              bsSize="lg"
                              type="text"
                              name="team-name"
                              placeholder="Enter your team name"
                              onChange={onChangeTeamNameSignup}
                              value={teamNameSignup}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    {teamAddMessage && (
                      <Alert variant="danger">{teamAddMessage}</Alert>
                    )}

                    <Button variant="primary" onClick={addTeamDetails}>
                      {TeamModalButtonText}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={addTeamDetailsModalClose}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/*model for add team */}

                {teamMessage && (
                  <Alert variant="success" style={{ width: "400px" }}>
                    {teamMessage}
                  </Alert>
                )}

                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Team Id</th>
                      <th>
                        <button
                          type="button"
                          className="SortButton"
                          onClick={() => onSort("TeamName")}
                        >
                          Team Name
                          <FontAwesomeIcon icon={arrowPositionTeamName} />
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          className="SortButton"
                          onClick={() => onSort("Company")}
                        >
                          Company
                          <FontAwesomeIcon icon={arrowPositionCompany} />
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          className="SortButton"
                          onClick={() => onSort("Partner")}
                        >
                          Partner
                          <FontAwesomeIcon icon={arrowPositionPartner} />
                        </button>
                      </th>
                      <th className="text-center">Edit</th>
                      <th className="text-center">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.data.map((tm) => {
                      return (
                        <tr key={tm.Id}>
                          <td>{tm.Id}</td>

                          <td>
                            <div
                              className="my-pointer"
                              onClick={() => handleLinkClk(tm.Id)}
                            >
                              {" "}
                              {tm.TeamName}{" "}
                            </div>
                          </td>

                          <td>{tm.CompanyName}</td>
                          <td>{tm.PartnerName}</td>
                          <td>
                            <button
                              className="btn mx-auto d-block"
                              onClick={() => editTeamModal(tm.Id)}
                            >
                              <img src={iconEdit} alt="" />
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn mx-auto d-block"
                              onClick={() => showDeleteModal("team", tm.Id)}
                            >
                              <img src={iconDelete} alt="" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {/*Table for partner list*/}
              </CardBody>
              <DeleteConfirmation
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={hideConfirmationModal}
                type={type}
                id={id}
                message={deleteMessage}
              />
            </Card>
          </Col>

          {/*...............................filter view  section........................................................*/}
          {filterViewShow ? (
            <Col md={3}>
              <Card className="app-filters">
                <CardHeader className="px-4 border-bottom">
                  <div style={{ float: "right" }}>
                    <button
                      className="btn text-dark rounded-pill"
                      onClick={() => setFilterViewShow(false)}
                    >
                      <X size={35}></X>
                    </button>
                  </div>
                  <p className="mb-0 h1  text-left text-primary font-weight-bold">
                    <Filter className="" size={40}></Filter> Filter
                  </p>
                </CardHeader>
                <CardBody>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Team Name</Label>
                      <select
                        className="custom-select"
                        value={filterTeamName}
                        onChange={onChangefilterTeamName}
                      >
                        <option value="0">Select Team Name</option>
                        {teams
                          .filter(
                            (v, i, a) =>
                              a.findIndex((t) => t.TeamName === v.TeamName) ===
                              i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.TeamName}
                                value={schema.TeamName}
                              >
                                {schema.TeamName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Client</Label>
                      <select
                        className="custom-select"
                        value={filterClientName}
                        onChange={onChangefilterClientName}
                      >
                        <option value="0">Select Client</option>
                        {teams
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.CompanyName === v.CompanyName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.CompanyName}
                                value={schema.CompanyName}
                              >
                                {schema.CompanyName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Partner</Label>
                      <select
                        className="custom-select"
                        value={filterPartnerName}
                        onChange={onChangefilterPartnerName}
                      >
                        <option value="0">Select Partner</option>
                        {teams
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.PartnerName === v.PartnerName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.PartnerName}
                                value={schema.PartnerName}
                              >
                                {schema.PartnerName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <div className="text-center">
                      <button
                        className="btn btn-primary d-block col-12"
                        onClick={onFilterButtonClick}
                      >
                        <Search></Search> Search
                      </button>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <div></div>
          )}
        </Row>
      </>
    );
  } else {
    return <Card></Card>;
  }
};

const Tables = (props) => (
  <div id="team_div">
    <TeamsList {...props} />
  </div>
);

export default Tables;
