import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardTitle,
    Container,
    Col,
    Row,
    Table,
    CardBody,
    FormGroup,
    Label,
    Input

} from "reactstrap";
import validator from 'validator'
import DeleteConfirmation from "../../../../src/components/DeleteConfirmation";
import { Filter, Settings,Plus } from "react-feather";
import Pagination from  "../../../../src/components/Pagination";

import { Button, Modal, Alert } from "react-bootstrap";

import CompanyService from "../../../AuthServices/companies.service";
import AuthService from "../../../AuthServices/auth.service.js";
import PartnerService from "../../../AuthServices/partners.service.js";
import userService from "../../../AuthServices/user.service.js";

// SVG Icons
import iconEdit from "../../../assets/img/icons/edit.svg";
import iconDelete from "../../../assets/img/icons/delete.svg";
import iconAddUser from "../../../assets/img/icons/add-user.svg";
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
const CompaniesList = (props) => {
     

    var currentUser = AuthService.getCurrentUser();
    var currentCompany = AuthService.getCurrentCompany();
    if (currentUser == null || currentUser==undefined) {
        props.history.push("/");
        window.location.reload();
    }
    if (currentUser.roleName === "user" || ((currentUser.roleName === "team-member" || currentUser.roleName === "team-leader" ))) {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    const userRoleList = [
        {
            id: 1,
            value: 'user'
        }, {
            id: 2,
            value: 'team-member'
        }, {
            id: 3,
            value: 'team-leader'
        }, {
            id: 4,
            value: 'company'
        }, {
            id: 5,
            value: 'partner'
        }
    ];

    const [companies, setCompanies] = useState([]);
    const [countries, setCountry] = useState([]);
    const [id, setId] = useState(null);
    const [type, setType] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [companyMessage, setMessage] = useState(null);
    const [companyAddMessage, setCompanyAddMessage] = useState(null);
    const [companyAddCreditMessage, setCompanyAddCreditMessage] = useState(null);
    
    const [companyAddCreditsSignUp, setCompanyAddCreditsSignUp] = useState("");
    const [companyNameSignup, setCompanyNameSignup] = useState("");
    const [companyId, setCompanyId] = useState("");
    //const [companyPasswordSignup, setCompanyPasswordSignup] = useState("");
    //const [companyEmailSignup, setCompanyEmailSignup] = useState("");
    const [companyABVSignup, setCompanyABVSignup] = useState("");
    const [companyDomainSignup, setCompanyDomainSignup] = useState("");
    const [companyCountrySignup, setCompanyCountrySignup] = useState(261);
    const [companyAddressSignup, setCompanyAddressSignup] = useState("");
    const [companyStateSignup, setCompanyStateSignup] = useState("");
    const [companyZipSignup, setCompanyZipSignup] = useState("");
    const [companyIndustryNameSignup, setCompanyIndustryNameSignup] = useState(0);
    const [companyLogoSignup, setCompanyLogoSignup] = useState("");
    const [CompanyModalTitle, setCompanyModalTitle] = useState("");
    const [CompanyModalButtonTitle, setCompanyModalButtonTitle] = useState("");
    const [partners, setPartners] = useState("");
    const [partnerId, setPartnerId] = useState(0);

    const [addUserModalShow, setAddUserModalshow] = useState(false);
    const [passwordSignup, setPasswordSignup] = useState("");
    const [emailSignup, setEmailSignup] = useState("");
    const [firstNameSignup, setFirstNameSignup] = useState("");
    const [lastNameSignup, setLastNameSignup] = useState("");
    const [messageAddUser, setMessageAddUser] = useState(null);
    const [currentCompanyAddUser, setCurrentCompanyAddUser] = useState("");
    const [currentPartnerAddUser, setCurrentPartnerAddUser] = useState(0);
    const [jobRoles, setJobRoles] = useState("");
    const [currentJobRole, setCurrentJobRole] = useState(0);
    const [industries, setIndustries] = useState("");

    const [userUpdatedRole, setUserUpdatedRole] = useState(1);
    const [filteredUser, setFilteredUser] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const [currentPosts, setCurrentPosts] = useState(1);
   // const currentPosts = companies.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);


    //componentDidMount
    useEffect(() => {
        //Fill company list
        const companyData = () => {
            var companyList = CompanyService.GetCompanyList();

            var details = companyList.then((response) => {
                return response;
            })
                .then((data) => {
                    if (currentUser.roleName === "partner") {
                         
                        setCompanies(data.companyDetails.filter(x => x.Partner_Id === currentUser.partner_id));
                    }
                    else {
                        setCompanies(data.companyDetails);
                    }
                    setCurrentPosts(companies.slice(indexOfFirstPost, indexOfLastPost));
                })
                .catch(e => {
                    console.log(e);
                })
        };
        //fill countries dropdown data
        const countryData = () => {
            var countryList = CompanyService.GetCountryList();

            var details = countryList.then((response) => {
                return response;
            })
                .then((data) => {
                    setCountry(data);
                })
                .catch(e => {
                    console.log(e);
                })
        };
        //fill partners dropdown data
        const partnerData = () => {
            var partnerList = PartnerService.GetPartnerList();
            var details = partnerList.then((response) => {
                return response;
            })
                .then((data) => {
                    if (currentUser.roleName === "partner") {
                        setPartners(data.partnerDetails.filter(x => x.Id === currentUser.partner_id));
                    }
                    else {
                        setPartners(data.partnerDetails);

                    }
                })
                .catch(e => {
                    console.log(e);
                })
        };
        const jobRolesList = () => {
            userService.GetJobRoles().then(
                (response) => {

                    if (response !== null) {
                        setJobRoles(response)
                    }

                }
            );
        };
        const industriesList = () => {
            userService.GetIndustries().then(
                (response) => {

                    if (response !== null) {
                        setIndustries(response)
                    }

                }
            );
        };
        partnerData();
        countryData();
        companyData();
        industriesList();
        jobRolesList();
    }, []);

     


    //add company model 
    const [addCompanyModalshow, setAddCompanyModalshow] = useState(false);
    const addCompanyModalClose = () => setAddCompanyModalshow(false);
    const addCompanyModal = () => setAddCompanyModalshow(true);


    //add credits Company model 
    const [addCreditCompanyModalshow, setAddCreditCompanyModalshow] = useState(false);
    const addCreditCompanyModalClose = () => setAddCreditCompanyModalshow(false);
    const addCreditDetailCompanyModal = () => setAddCreditCompanyModalshow(true);


    const addCreditCompanyModal = (id) => {
        setCompanyModalTitle("Add Credits For Company")
        setCompanyModalButtonTitle("Add Credits")
        setAddCreditCompanyModalshow(true);
        setCompanyId(id);

        setCompanyAddCreditsSignUp("");
        setMessage("");

       

    };


    //close the add credits Company modal
    const addCreditCompanyDetailsModalClose = () => {
        setAddCreditCompanyModalshow(false);
        setCompanyAddCreditMessage("");
    };

    const handleUserRoleChange = (e) => {

        setUserUpdatedRole(e.target.value);
    }


    const showDeleteModal = (type, id) => {
        //setting type in case to make this reusable , here setting company , can be set to partners etc
        setType(type);
        //setting the id of the company
        setId(id);
        setDeleteMessage(`Are you sure you want to delete the company  '${companies.find((x) => x.Id === id).CompanyName}'?`);
        setDisplayConfirmationModal(true);
    };

    //validates the password
    const validatePassword = (e) => {

        const password = e.target.value;

        if (validator.isStrongPassword(password, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setCompanyAddMessage('')
            //setCompanyPasswordSignup(password);
            setPasswordSignup(password);
            setMessageAddUser('')
        } else {
            //setCompanyAddMessage('Please enter a  strong password(minLength: 8, minLowercase: 1,minUppercase: 1, minNumbers: 1, minSymbols: 1)')
            setMessageAddUser('Please enter a  strong password(minLength: 8, minLowercase: 1,minUppercase: 1, minNumbers: 1, minSymbols: 1)')
        }
    }

    const handlePartnerChange = (e) => {

        setPartnerId(e.target.value);
    };

    const editCompanyModal = (id) => {
        setCompanyModalTitle("Edit Company")
        setCompanyModalButtonTitle("Save Changes");
        var companyDetails = CompanyService.GetCompanyDetailsById(id);
        companyDetails.then((response) => {
            return response;
        })
            .then((data) => {
                 
                setCompanyNameSignup(data.CompanyName);
                setCompanyIndustryNameSignup(data.CompanyIndustry);
                setCompanyABVSignup(data.Company_Abn);
                setCompanyAddressSignup(data.CompanyAddress);
                setCompanyCountrySignup(data.CompanyCountry);
                setCompanyStateSignup(data.CompanyState);
                setCompanyZipSignup(data.CompanyZip);
                //setCompanyEmailSignup(data.Email);
                setCompanyId(data.CompanyId)
                //setCompanyPasswordSignup(data.Password);
                setAddCompanyModalshow(true);
                //setFirstNameSignup(data.FirstName)
                //setLastNameSignup(data.LastName)
                setCompanyId(id);
            })
            .catch(e => {
                toast.error('There is some error fetching the details of the company', { position: toast.POSITION.TOP_CENTER })
                console.log(e);
            })


    };
    const submitDelete = (type, id) => {
        if (type === "company") {
            CompanyService.DeleteCompanyById(id);
            toast.success(`The Company '${companies.find((x) => x.Id === id).CompanyName}'`, { position: toast.POSITION.TOP_CENTER })
            setCompanies(companies.filter((company) => company.Id !== id));
            setCurrentPosts(companies.slice(indexOfFirstPost, indexOfLastPost));
        }
        setDisplayConfirmationModal(false);
    };
    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };
    //close the add company modal
    const addCompanyDetailsModalClose = () => {
        setAddCompanyModalshow(false);
        setCompanyAddMessage("");
    };
    //displays the add company modal 
    const addCompanyDetailsModalShow = () => {
        setAddCompanyModalshow(true);
        setCompanyAddMessage("");
        setCompanyModalTitle("Add Company")
        setCompanyModalButtonTitle("Add Company")
        setCompanyNameSignup("");
        setCompanyIndustryNameSignup("0");
        setCompanyABVSignup("");
        setCompanyDomainSignup("");
        setCompanyAddressSignup("");
        setCompanyCountrySignup("0");
        setCompanyStateSignup("");
        setCompanyZipSignup("");
        setPartnerId(0);
        //setCompanyEmailSignup("");
        //setCompanyPasswordSignup("");
        //setFirstNameSignup("");
        //setLastNameSignup("");
        setCompanyId("0");
        setMessage("");
    };
    // add or updates the company, if the company id is null/0 then inserts the company else updates
    const addCompanyDetails = () => {
         
        if (partnerId !== "0" && companyCountrySignup !== "0" && companyNameSignup !== "" /*&& companyPasswordSignup !== ""*/ && companyABVSignup !== "" && companyStateSignup !== "" && companyZipSignup !== "" /*&& companyEmailSignup !== ""*/ && companyCountrySignup !== "" && companyAddressSignup !== "" && companyIndustryNameSignup !== "0" /*&& firstNameSignup !== "" && lastNameSignup !== "" */ && companyId && companyDomainSignup!="") {

                if (companyLogoSignup !== "") {
                    CompanyService.AddCompanyDetails(companyNameSignup, /*companyPasswordSignup, */companyABVSignup, companyStateSignup, companyZipSignup,
                        companyLogoSignup, /*companyEmailSignup,*/ companyCountrySignup, companyAddressSignup, companyIndustryNameSignup, /*4, firstNameSignup, lastNameSignup,*/ companyId, partnerId, companyDomainSignup).then(
                            (response) => {
                                if (response.data.Status === "Success") {
                                    const companyData = CompanyService.GetCompanyList();

                                    var details = companyData.then((response) => {
                                        return response;
                                    })
                                        .then((data) => {
                                            if (currentUser.roleName === "partner") {
                                                setCompanies(data.companyDetails.filter(x => x.Partner_Id === currentUser.partner_id));
                                            }
                                            else {
                                                setCompanies(data.companyDetails);
                                            }
                                            setCurrentPosts(companies.slice(indexOfFirstPost, indexOfLastPost));
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        })
                                    setAddCompanyModalshow(false);
                                    if (companyId === "0") {
                                        toast.success('Company Added Successfully', { position: toast.POSITION.TOP_CENTER })
                                    }
                                    else {
                                        toast.success('Company Updated Successfully', { position: toast.POSITION.TOP_CENTER })
                                    }
                                }
                                else {
                                    setAddCompanyModalshow(true);
                                    toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER })
                                }
                            }
                        );
                }
            //}
            else {
                CompanyService.AddCompanyDetails(companyNameSignup, /*companyPasswordSignup,*/ companyABVSignup, companyStateSignup, companyZipSignup,
                    companyLogoSignup,/* companyEmailSignup, */companyCountrySignup, companyAddressSignup, companyIndustryNameSignup,/* 4, firstNameSignup, lastNameSignup, */companyId, partnerId, companyDomainSignup).then(
                        (response) => {
                            if (response.data.Status === "Success") {
                                const companyData = CompanyService.GetCompanyList();

                                var details = companyData.then((response) => {
                                    return response;
                                })
                                    .then((data) => {
                                        if (currentUser.roleName === "partner") {
                                            setCompanies(data.companyDetails.filter(x => x.Partner_Id === currentUser.partner_id));
                                        }
                                        else {
                                            setCompanies(data.companyDetails);
                                        }

                                    })
                                    .catch(e => {
                                        console.log(e);
                                    })
                                setAddCompanyModalshow(false);
                                if (companyId === "0") {
                                   toast.success('Company Added Successfully', { position: toast.POSITION.TOP_CENTER })
                                }
                                else {
                                    toast.success('Company Updated Successfully', { position: toast.POSITION.TOP_CENTER })
                                }
                            }
                            else {
                                setAddCompanyModalshow(true);
                                toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER })
                            }
                        }
                    );
            }
        }

        else {

            setAddCompanyModalshow(true);
            toast.error('Kindly fill all the fields', { position: toast.POSITION.TOP_CENTER })
        }
    };
    const onChangeCompanyNameSignup = (e) => {
        const companyName = e.target.value;
        setCompanyNameSignup(companyName);
    };
    const onChangeJobRole = (e) => {
         
        const jobRole = e.target.value;
        setCurrentJobRole(jobRole);
    };
    //const onChangePasswordSignup = (e) => {
    //    const password = e.target.value;
    //    setCompanyPasswordSignup(password);
    //};

    const onChangeCompanyABV = (e) => {
        const companyABV = e.target.value;
        setCompanyABVSignup(companyABV);
    };

    const onChangeCompanyCountrySignup = (e) => {
        const companyCountry = e.target.value;
        setCompanyCountrySignup(companyCountry);
    };

    //const onChangeCompanyEmailSignup = (e) => {
    //    const companyEmail = e.target.value;
    //    setCompanyEmailSignup(companyEmail);
    //};
    const onChangeCompanyAddressSignup = (e) => {
        const companyAddress = e.target.value;
        setCompanyAddressSignup(companyAddress);
    };
    const onChangeCompanyStateSignup = (e) => {
        const companyState = e.target.value;
        setCompanyStateSignup(companyState);
    };
    const onChangeCompanyZipSignup = (e) => {
        const companyZip = e.target.value;
        setCompanyZipSignup(companyZip);
    };
    const onChangeCompanyDomainSignup = (e) => {
        const companyDomain = e.target.value;
        setCompanyDomainSignup(companyDomain);
    };
    const onChangeCompanyIndustryNameSignup = (e) => {
        const companyIndustryName = e.target.value;
        setCompanyIndustryNameSignup(companyIndustryName);
    };
    const onChangeLogoSignup = (e) => {
        const companylogo = e.target.files[0];
        setCompanyLogoSignup(companylogo);
    };
    const onChangeUserPasswordSignup = (e) => {
        const password = e.target.value;
        setPasswordSignup(password);
    };


    const onChangeFirstName = (e) => {
        const firstName = e.target.value;
        setFirstNameSignup(firstName);
    };

    const onChangeLastName = (e) => {
        const lastName = e.target.value;
        setLastNameSignup(lastName);
    };

    const onChangeEmailSignup = (e) => {
        const email = e.target.value;
        setEmailSignup(email);
    };

    //for add credits company  
    const onChangeCompanyAddCreditsSignup = (e) => {
        const companyCredits = e.target.value;
        setCompanyAddCreditsSignUp(companyCredits);
    }



    const addUserDetailsModalClose = () => {
        setAddUserModalshow(false);
        setMessageAddUser("")
    };
    const addUserModal = (id, partnerId) => {

        setCurrentCompanyAddUser(id);
        setCurrentPartnerAddUser(partnerId);
        setFirstNameSignup("");
        setLastNameSignup("");
        setEmailSignup("");
        setPasswordSignup("");
        setFilteredUser(userRoleList.filter(x => x.id === 2 || x.id === 3 || x.id === 4));
        setAddUserModalshow(true);

    }
    const btnClick_addUser = () => {

        if (passwordSignup !== "" && firstNameSignup !== "" && lastNameSignup !== "" && emailSignup !== "" && currentJobRole !== "0" && userUpdatedRole != "0") {

            userService.AddUserDetails(firstNameSignup, lastNameSignup, emailSignup, "", passwordSignup, currentJobRole, currentPartnerAddUser, currentCompanyAddUser, userUpdatedRole, 0, "").then(
                (response) => {

                    if (response.data.Status === "Success") {

                        setAddUserModalshow(false);
                        toast.success('User Added Successfully', { position: toast.POSITION.TOP_CENTER })
                    }
                    else {
                        setAddUserModalshow(true);
                        toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER })
                    }
                }
            );
        }
        else {
            setAddUserModalshow(true);
            toast.error('Kindly fill all the fields!! ', { position: toast.POSITION.TOP_CENTER })
        }
    }




    //code for add  credits for company 
     
    const addCreditDetailsOfCompany = () => {
        //code for credit details 

        var currentUserId = currentUser.id;
        if (companyAddCreditsSignUp !== "" && companyId) {

            CompanyService.AddCompanyCreditDetails(companyAddCreditsSignUp, companyId, currentUserId).then(
                (response) => {
                    if (response.data.Status === "Success") {
                        toast.success("Credit Successfully Added Into Company Account !!", { position: toast.POSITION.TOP_CENTER })
                    }
                }
            );
        }

    };



    if (currentUser || currentCompany) {
        return (

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0 d-flex align-items-center">
                        <h2 className="d-inline-block mr-3 mb-0">Companies</h2>
                    <button
                            className="p-2 text-white bg-primary rounded-circle"
                            onClick={addCompanyDetailsModalShow}
                        >
                            <Plus size={28} />
                        </button>
                    </CardTitle>

                </CardHeader>
                <CardBody>

                    {/*model for add company */}
                    <Modal show={addCompanyModalshow} onHide={addCompanyDetailsModalClose} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>{CompanyModalTitle}</Modal.Title>{"\n"}

                        </Modal.Header>
                        <Modal.Body>

                            <Container>
                                <Row>
                                    {/*<Col xs={6} md={6}>*/}
                                    {/*    <FormGroup>*/}

                                    {/*        <Input*/}
                                    {/*            bsSize="lg"*/}
                                    {/*            type="text"*/}
                                    {/*            name="First Name"*/}
                                    {/*            placeholder="Enter First Name" value={firstNameSignup}*/}
                                    {/*            onChange={onChangeFirstName}*/}
                                    {/*        />*/}
                                    {/*    </FormGroup>*/}
                                    {/*</Col>*/}
                                    {/*<Col xs={6} md={6}>*/}
                                    {/*    <FormGroup>*/}

                                    {/*        <Input*/}
                                    {/*            bsSize="lg"*/}
                                    {/*            type="text"*/}
                                    {/*            name="lastName"*/}
                                    {/*            placeholder="Enter Last Name" value={lastNameSignup}*/}
                                    {/*            onChange={onChangeLastName}*/}
                                    {/*        />*/}
                                    {/*    </FormGroup>*/}
                                    {/*</Col>*/}
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="company-name"
                                                placeholder="Enter your company name" onChange={onChangeCompanyNameSignup}
                                                value={companyNameSignup}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {/*<Col xs={6} md={6}>*/}
                                    {/*    <FormGroup>*/}

                                    {/*        <Input*/}
                                    {/*            bsSize="lg"*/}
                                    {/*            type="email"*/}
                                    {/*            name="email"*/}
                                    {/*            placeholder="Enter your company Email" onChange={onChangeCompanyEmailSignup}*/}
                                    {/*            value={companyEmailSignup}*/}
                                    {/*        />*/}
                                    {/*    </FormGroup>*/}
                                    {/*</Col>*/}
                                    {/*<Col xs={6} md={6}>*/}
                                    {/*    <FormGroup>*/}

                                    {/*        <Input*/}
                                    {/*            bsSize="lg"*/}
                                    {/*            type="password"*/}
                                    {/*            name="password"*/}
                                    {/*            placeholder="Enter your password" onChange={onChangePasswordSignup}*/}
                                    {/*            value={companyPasswordSignup}*/}
                                    {/*            onBlur={validatePassword}*/}
                                    {/*        />*/}
                                    {/*    </FormGroup>*/}
                                    {/*</Col>*/}

                                   
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="address"
                                                placeholder="Address" onChange={onChangeCompanyAddressSignup} value={companyAddressSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="number"
                                                name="abn"
                                                placeholder="ABN" onChange={onChangeCompanyABV} value={companyABVSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="state"
                                                placeholder="State" onChange={onChangeCompanyStateSignup} value={companyStateSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="zipcode"
                                                placeholder="Zipcode" onChange={onChangeCompanyZipSignup} value={companyZipSignup}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="Company Domain"
                                                placeholder="Company Domain" onChange={onChangeCompanyDomainSignup} value={companyDomainSignup}
                                            />
                                        </FormGroup>
                                    </Col>
                                    

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            Choose Partner  :
                                           <select  className="custom-select" 
                                                value={partnerId}
                                                onChange={handlePartnerChange}
                                            >
                                                <option value="0">Select Partner</option>
                                                {partners &&
                                                    partners.length > 0 &&
                                                    partners.map(schema => {
                                                        return <option key={schema.Id} value={schema.Id}>{schema.PartnerName}</option>;
                                                    })}


                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            Choose Country :
                                            <select  className="custom-select" 
                                                value={companyCountrySignup}
                                                onChange={onChangeCompanyCountrySignup}>

                                                <option value="0">Select Country</option>
                                                {countries &&
                                                    countries.length > 0 &&
                                                    countries.map(schema => {
                                                        return <option key={schema.id} value={schema.id}>{schema.country_name}</option>;
                                                    })}


                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <Label>Industry</Label>
                                            <select className="custom-select"
                                                onChange={onChangeCompanyIndustryNameSignup}
                                                value={companyIndustryNameSignup}
                                            >
                                                <option value="0">Select Industry</option>
                                                {industries &&
                                                    industries.length > 0 &&
                                                    industries.map(schema => {
                                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <Input
                                                bsSize="lg"
                                                type="file"
                                                name="logo"
                                                title="Kindly upload company logo"
                                                onChange={onChangeLogoSignup}
                                            />
                                        </FormGroup>
                                    </Col>
                                    
                                </Row>
                            </Container>

                        </Modal.Body>
                        <Modal.Footer>
                            {companyAddMessage && <Alert variant="danger">{companyAddMessage}</Alert>}

                            <Button variant="primary" onClick={addCompanyDetails}>
                                {CompanyModalButtonTitle}
                            </Button>
                            <Button variant="secondary" onClick={addCompanyDetailsModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/*model for add partner */}
                    <Modal show={addUserModalShow} onHide={addUserDetailsModalClose} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Add User for Company</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Container>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="first-name"
                                                placeholder="Enter your first name"
                                                value={firstNameSignup}
                                                onChange={onChangeFirstName}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="last-name"
                                                placeholder="Enter your last name"
                                                value={lastNameSignup}
                                                onChange={onChangeLastName}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                value={emailSignup}
                                                onChange={onChangeEmailSignup}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="password"
                                                name="password"
                                                placeholder="Enter your password"
                                                value={passwordSignup}
                                                onChange={onChangeUserPasswordSignup}

                                            />
                                            <PasswordStrengthBar password={passwordSignup} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            Please select the job role :
                                            <select className="custom-select"
                                                value={currentJobRole}
                                                onChange={onChangeJobRole}
                                            >
                                                <option value="0">Select JobRole</option>
                                                {jobRoles &&
                                                    jobRoles.length > 0 &&
                                                    jobRoles.map(schema => {
                                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                                    })}


                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        Please select User Role
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <select className="custom-select" value={userUpdatedRole} onChange={handleUserRoleChange}>
                                                <option value="0">Select role</option>
                                                {
                                                    filteredUser &&
                                                    filteredUser.length > 0 &&
                                                    filteredUser.map(schema => {
                                                        return <option key={schema.id} value={schema.id}>{schema.value}</option>;
                                                    })}


                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>

                        </Modal.Body>
                        <Modal.Footer>
                            {messageAddUser && <Alert variant="danger">{messageAddUser}</Alert>}
                            <Button variant="primary" onClick={btnClick_addUser}>
                                Add 
                            </Button>
                            <Button variant="secondary" onClick={addUserDetailsModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    {/*{companyMessage && <Alert variant="success" style={{ "width": "400px" }}>{companyMessage}</Alert>}*/}



                    {/*model for add credits company */}
                    <Modal show={addCreditCompanyModalshow} onHide={addCreditCompanyDetailsModalClose} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>{CompanyModalTitle}</Modal.Title>{"\n"}

                        </Modal.Header>
                        <Modal.Body>

                            <Container>
                                <Row>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="company-credits"
                                                placeholder="Enter company credits" onChange={onChangeCompanyAddCreditsSignup}
                                                value={companyAddCreditsSignUp}
                                            />
                                        </FormGroup>
                                    </Col>


                                </Row>
                            </Container>

                        </Modal.Body>
                        <Modal.Footer>
                            {companyAddCreditMessage && <Alert variant="danger">{companyAddCreditMessage}</Alert>}

                            <Button variant="primary" onClick={addCreditDetailsOfCompany}>
                                {CompanyModalButtonTitle}
                            </Button>
                            <Button variant="secondary" onClick={addCreditCompanyDetailsModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/*model for add credit company */}



                    {companyMessage && <Alert variant="success" style={{ "width": "400px" }}>{companyMessage}</Alert>}

                    <Table striped hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Company Id</th>
                                <th>Logo</th>
                                <th>Company Name</th>
                                <th>Add User</th>
                                <th>Edit</th>
                                <th>Remove</th>
                                {currentUser.roleName === "partner" ?
                                    <th>Add Credits</th>
                                    : <div></div>}
                            </tr>
                        </thead>
                        <tbody>

                            {companies.slice(indexOfFirstPost, indexOfLastPost).map(cmp => {
                                return <tr key={cmp.Id}>
                                    <td>
                                        <label className="checkbox-container">
                                            <input type="checkbox" checked="" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </td>
                                    <td>{cmp.Id}</td>
                                    <td>
                                        <img
                                            src={'data:' + cmp.logoDetails.FileType + ';base64,' + cmp.logoDetails.FileContent}
                                            width="48"
                                            height="48"
                                            className="rounded-circle mr-2"
                                            alt="Avatar"
                                        />

                                    </td>
                                    <td>{cmp.CompanyName}</td>
                                    <td>
                                        <button
                                            className="btn d-block mx-auto"
                                            onClick={() => addUserModal(cmp.Id, cmp.Partner_Id)}
                                        >
                                            <img src={iconAddUser} alt="" />
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className="btn d-block mx-auto"
                                            onClick={() => editCompanyModal(cmp.Id)}
                                        >
                                            <img src={iconEdit} alt="" />
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className="btn d-block mx-auto"
                                            onClick={() => showDeleteModal("company", cmp.Id)}
                                        >
                                            <img src={iconDelete} alt="" />
                                        </button>
                                    </td>

                                    {currentUser.roleName === "partner" ?
                                    <td>
                                            <button className="btn btn-primary" onClick={() => addCreditCompanyModal(cmp.Id)}>Add Credits</button>
                                        </td>
                                       : <div></div>}
                                </tr>;
                            })}
                        </tbody>

                    </Table>
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={companies.length}
                            paginate={paginate}
                        />

                    {/*Table for partner list*/}
                </CardBody>
                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} type={type} id={id} message={deleteMessage} />
            </Card>

        );


    }

    else {
        return (<Card></Card>)
    }
}



const Tables = (props) => (
    <div id="company_div">
    <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-4">

            <Col xs="auto" className="ml-auto text-right mt-n1">


                <Button color="primary" className="shadow-sm mr-1">
                    <Filter className="feather" />
                </Button>
                <Button color="primary" className="shadow-sm">
                    <Settings className="feather" />
                </Button>

            </Col>
        </Row>



        <CompaniesList  {...props} />

        </Container>
        </div>
);

export default Tables;
