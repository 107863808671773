import async from "../components/Async";

import {
    Layout as LayoutIcon,
    Sliders as SlidersIcon,
    User as UserIcon,
    Users as UsersIcon,
    Settings as SettingsIcon,
    Grid as GridIcon,
    ShoppingCart as CartIcon,
    Flag as FlagIcon,
    Mail as MailIcon

} from "react-feather";

// Landing
import LoginPanel from "../pages/auth/LoginPanel";


// Auth
import ResetPassword from "../pages/auth/ResetPassword";
import ChangePassword from  "../pages/auth/ChangePassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

import CompanyLoginPanel from "../pages/auth/CompanyLoginPanel";
import ThankYou from "../pages/auth/ThankYou";
import CompanyThankYou from "../pages/auth/CompanyThankYou";
import PartnerView from "../pages/sgacontainer/partnerdata/PartnerView";




// Pages sga container

import SgaUsers from "../pages/sgacontainer/usersdata/SgaUsers";
import SgaPartners from "../pages/sgacontainer/partnerdata/SgaPartners";
import SgaTeams from "../pages/sgacontainer/teamdata/SgaTeams";
import SgaCompanies from "../pages/sgacontainer/companydata/SgaCompanies";

import TeamLeaderView from "../pages/sgacontainer/teamdata/TeamLeaderView";


import UserProfile from "../pages/sgacontainer/usersdata/UserProfile";

import Settings from "../pages/sgacontainer/settings/Settings";

import SgaShop from "../pages/sgacontainer/shopdata/SgaShop";

import SgaAssessments from "../pages/sgacontainer/Assessmentdata/SgaAssessments";
import SgaAssessmentsResult from "../pages/sgacontainer/Assessmentdata/SgaAssessmentsResult";

import AssessmentQuiz from "../pages/sgacontainer/Assessmentdata/AssessmentQuiz";
import ReviewAssessment from "../pages/sgacontainer/Assessmentdata/ReviewAssessment";

import NonTimeBasedQuiz from "../pages/sgacontainer/Assessmentdata/NonTimeBasedQuiz";

import ReSubmitQuestionOption from "../pages/sgacontainer/Assessmentdata/ReSubmitQuestionOption";

import ReviewAssessmentNonTimer from "../pages/sgacontainer/Assessmentdata/ReviewAssessmentNonTimer";

import ReSubmitQuestionOptionNonTimer from "../pages/sgacontainer/Assessmentdata/ReSubmitQuestionOptionNonTimer";

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));



// Routes
const loginPanelRoutes = {
    path: "/",
    name: "Landing Page",
    component: LoginPanel,
    children: null
};

const companyLoginPanelRoutes = {
    path: "/companyLogin",
    name: "company login panel",
    component: CompanyLoginPanel,
    children: null
};

//thank you page

const thankYouRoutes = {
    path: "/thankyou",
    name: "Thank you",
    component: ThankYou,
    children: null
};

//thank you page company

const companyThankYouRoutes = {
    path: "/companyThankYou",
    name: "Thank you from company",
    component: CompanyThankYou,
    children: null
};


//users page sga container
const sgaUsersRoutes = {
    path: "/users",
    name: "Users",
    icon: UserIcon,
    component: SgaUsers,
    children: null
};


//user profile page sga container
const sgaUserProfileRoutes = {
    path: "/userProfile",
    name: "User Profile",
    icon: LayoutIcon,
    component: UserProfile,
    children: null
};

//partners page sga container
const sgaPartnersRoutes = {
    path: "/partners",
    name: "Partners",
    icon: UsersIcon,
    component: SgaPartners,
    children: null
};

//Teams page sga container
const sgaTeamsRoutes = {
    path: "/teams",
    name: "Teams",
    icon: GridIcon,
    component: SgaTeams,
    children: null
};

//Companies page sga container
const sgaCompaniesRoutes = {
    path: "/companies",
    name: "Companies",
    icon: MailIcon,
    component: SgaCompanies,
    children: null
};

//Team Leader View

const teamLeaderViewRoutes = {
    path: "/teamLeaderView",
    name: "Team Leader View",
    icon: LayoutIcon,
    component: TeamLeaderView,
    children: null
}

const partnerViewRoutes = {
    path: "/partnerView",
    name: "Partner View",
    icon: LayoutIcon,
    component: PartnerView,
    children: null
}



//Assessment View

const sgaAssessmentListRoutes = {
    path: "/Assessments",
    name: "Assessments",
    icon: FlagIcon,
    component: SgaAssessments,
    children: null
}

const sgaAssessmentResultRoutes = {
    path: "/AssessmentResult",
    name: "AssessmentResult",
    icon: FlagIcon,
    component: SgaAssessmentsResult,
    children: null
}

//Shop View

const sgaShopViewRoutes = {
    path: "/sgaShop",
    name: "Shop",
    icon: CartIcon,
    component: SgaShop,
    children: null
}


//Assessment View

const sgaAssessmentQuizRoutes = {
    path: "/AssessmentQuiz",
    name: "Assessments Quiz",
    component: AssessmentQuiz,
    children: null
}
const sgaReSubmitQuestionQuizRoutes = {
    path: "/ReSubmitQuestionOption",
    name: "ResubmitQuestion Quiz",
    component: ReSubmitQuestionOption,
    children: null
}

const sgaReviewAssessmentRoutes = {
    path: "/ReviewAssessment",
    name: "Assessments Quiz",
    component: ReviewAssessment,
    children: null
}



const sgaNonTimerBasedQuizRoutes = {
    path: "/NonTimeBasedQuiz",
    name: "Non-Time Based Quiz",
    component: NonTimeBasedQuiz,
    children: null
}




const sgaReviewAssessmentNonTimerRoutes = {
    path: "/ReviewAssessmentNonTimer",
    name: "ReviewAssessmentNonTimer",
    component: ReviewAssessmentNonTimer,
    children: null
}

const sgaReSubmitQuestionOptionNonTimerRoutes = {
    path: "/ReSubmitQuestionOptionNonTimer",
    name: "ReSubmitQuestionOptionNonTimer",
    component: ReSubmitQuestionOptionNonTimer,
    children: null
}

//settings 

const settingsRoutes = {
    path: "/settings",
    name: "Settings ",
    icon: SettingsIcon,
    component: Settings,
    children: null
}


const dashboardRoutes = {
    path: "/dashboard",
    name: "Dashboards",
    header: "Pages",
    badgeColor: "primary",
    badgeText: "5",
    icon: SlidersIcon,
    containsHome: true,
    children: [
        {
            path: "/dashboard/default",
            name: "Default",
            component: Default
        },

    ]
};


const authRoutes = {
    path: "/auth",
    name: "Auth",
    icon: UsersIcon,
    badgeColor: "secondary",
    badgeText: "Special",
    children: [

        {
            path: "/auth/reset-password",
            name: "Reset Password",
            component: ResetPassword
        },
        {
            path: "/auth/change-password",
            name: "Change Password",
            component: ChangePassword
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500
        }
    ]
};


// Dashboard specific routes
export const dashboard = [
    dashboardRoutes,
    sgaAssessmentListRoutes,
    sgaShopViewRoutes,
    sgaUsersRoutes,
    sgaPartnersRoutes,
    sgaTeamsRoutes,
    sgaCompaniesRoutes,
    teamLeaderViewRoutes,
    partnerViewRoutes,
    settingsRoutes,
    sgaUserProfileRoutes,
    sgaAssessmentQuizRoutes,
    sgaNonTimerBasedQuizRoutes,
    sgaReviewAssessmentRoutes,
    sgaReSubmitQuestionQuizRoutes,
    sgaReSubmitQuestionOptionNonTimerRoutes,
    sgaReviewAssessmentNonTimerRoutes,
    sgaAssessmentResultRoutes
];

// Landing specific routes
export const loginPanel = [loginPanelRoutes];

// company login routes
export const companyLoginPanel = [companyLoginPanelRoutes];

// thank you routes
export const thankYou = [thankYouRoutes];

// thank you routes
export const companyThankYou = [companyThankYouRoutes];

// Auth specific routes 
export const page = [authRoutes];

// All routes
export default [
    dashboardRoutes,
    sgaAssessmentListRoutes,
    sgaShopViewRoutes,
    sgaUsersRoutes,
    sgaTeamsRoutes,
    sgaCompaniesRoutes,
    sgaPartnersRoutes,
    settingsRoutes
];
