import React from "react";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col
} from "reactstrap";

const ThankYou = () => (

    <React.Fragment>
        <Col sm="5" md="8" lg="6" className="mx-auto d-table h-100">
            <Card>
                <div className="signup-othercard">
                    <CardBody>
                        <div className="m-sm-4">
                            <div className="text-center">
                                <h1>Thank You </h1>
                                <Link to="/">
                                    <p className="lead">
                                        An email has been sent to your registered email id . Kindly click on the same to complete registeration process...........
                                    </p>

                                </Link>
                                <h4>Use for normal user registration </h4>
                            </div>

                        </div>
                    </CardBody>
                </div>
            </Card>
        </Col>
    </React.Fragment>
);

export default ThankYou;