import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../AuthServices/auth.service.js";
import userService from "../../AuthServices/user.service.js";
import { Redirect } from "react-router-dom";
import validator from "validator";
import PasswordStrengthBar from 'react-password-strength-bar';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Col,
  Input,
  UncontrolledAlert,
} from "reactstrap";

import companiesService from "../../AuthServices/companies.service.js";
const colors = ["danger"];

const LoginPanel = (props) => {
  var currentUser = null;
  var companyProfile = null;

  const user = AuthService.getCurrentUser();
  const company = AuthService.getCurrentCompany();
  if (user) {
    currentUser = user;
  }
  const formUser = useRef();
  const formUserSignup = useRef();

  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordSignup, setPasswordSignup] = useState("");
  const [emailSignup, setEmailSignup] = useState("");

  const [firstNameSignup, setFirstNameSignup] = useState("");
  const [lastNameSignup, setLastNameSignup] = useState("");
  const [jobRoles, setJobRoles] = useState("");
  const [currentJobRole, setCurrentJobRole] = useState(1);

  const [message, setMessage] = useState("");
  const [messageSignup, setMessageSignup] = useState("");
    const [loading, setLoading] = useState(false);
    const [companyUserEmail, setCompanyUserEmail] = useState("");

  const onChangeEmail = (e) => {
    const loginMail = e.target.value;
    setLoginEmail(loginMail);
  };
    const onChangeCompanyUserEmail = (e) => {
        setCompanyUserEmail(e.target.value);
    };
  useEffect(() => {
    if (jobRoles.length === 0) {
      userService.GetJobRoles().then((response) => {
        if (response !== null) {
          setJobRoles(response);
        }
      });
    }
  });

  const validatePassword = (e) => {
    const password = e.target.value;

    if (
      validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setMessageSignup("");
      setPasswordSignup(password);
    } else {
      setMessageSignup("Is Not Strong Password");
    }
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangePasswordSignup = (e) => {
    const password = e.target.value;
    setPasswordSignup(password);
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstNameSignup(firstName);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastNameSignup(lastName);
  };

  const onChangeEmailSignup = (e) => {
    const email = e.target.value;
    setEmailSignup(email);
  };
  const onChangeJobRole = (e) => {
    const jobRole = e.target.value;
    setCurrentJobRole(jobRole);
  };
  if (company) {
    companyProfile = company;
    if (companyProfile) {
      props.history.push("/dashboard/default");
      window.location.reload();
    }
  }
    //const validateUserEmailCompanyLogin = (e) => {
         
    //    if (companyUserEmail != "") {
    //        companiesService.GetCompanyDetailsByFromUserEmail(companyUserEmail).then(
    //            (response) => {

    //                if (response != "" && response>0) {

    //                    props.history.push("/companyLogin?cid=" + response);
    //                    window.location.reload();
    //                }
    //                else {
    //                    props.history.push("/");
    //                    window.location.reload();

    //                }
    //            }
    //        );
    //    }
    //    else {
    //        props.history.push("/");
    //        window.location.reload();

    //    }
    //}

  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    if (loginEmail !== "" && password !== "") {
       
      AuthService.loginUser(loginEmail, password).then((response) => {
        if (response.Message === "") {
          setLoading(false);
          props.history.push("/dashboard/default");
          //   window.location.reload();
        } else {
          setLoading(false);
          setMessage(response.Message);
          props.history.push("/");
        }
      });
    } else {
      setLoading(false);
      setMessage("Kindly fill all the fields");
    }
  };
  const handleSignup = (e) => {
    e.preventDefault();
    setMessageSignup("");

    if (
      passwordSignup !== "" &&
      firstNameSignup !== "" &&
      lastNameSignup !== "" &&
      emailSignup !== ""
    ) {
      AuthService.registerUser(
        firstNameSignup,
        lastNameSignup,
        emailSignup,
        passwordSignup,
        currentJobRole
      ).then((response) => {
        if (response.data.Status === "Success") {
          props.history.push("/thankyou");
          window.location.reload();
        } else {
          setMessageSignup(response.data.Message);
          props.history.push("/");
        }
      });
    } else {
      setMessageSignup(
        "All the fields are required, kindly fill all the fields"
      );
    }
  };
  if (currentUser === null) {
    return (
      <React.Fragment>
        <Col sm="5" md="8" lg="6" className="mx-auto p-0">
          <Card className="h-100 shadow-lg">
            <div className="signup-card">
              <CardBody>
                <div className="m-sm-4">
                  <div className="text-center">
                    <h2 className="h2-black-heading mb-4">Sign up</h2>
                  </div>
                  <Form onSubmit={handleSignup} ref={formUserSignup}>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="firstname"
                        placeholder="First name"
                        value={firstNameSignup}
                        onChange={onChangeFirstName}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="lastname"
                        placeholder="Last name"
                        value={lastNameSignup}
                        onChange={onChangeLastName}
                      />
                    </FormGroup>
                    <FormGroup>
                      <select
                        className="custom-select"
                        value={currentJobRole}
                        onChange={onChangeJobRole}
                      >
                        {jobRoles &&
                          jobRoles.length > 0 &&
                          jobRoles.map((schema) => {
                            return (
                              <option key={schema.id} value={schema.id}>
                                {schema.name}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={emailSignup}
                        onChange={onChangeEmailSignup}
                      />
                    </FormGroup>

                                        <FormGroup>
                                            <Input
                                                type="password"
                                                bsSize="lg"
                                                placeholder="Password"
                                                name="password"
                                                value={passwordSignup}
                                                onChange={onChangePasswordSignup}

                                            />
                                            <PasswordStrengthBar password={passwordSignup} />
                                        </FormGroup>
                                        {/* <Link to="/thankyou"> */}
                                        <button className="btn btn-primary ml-auto mr-0 d-block font-weight-bold">
                                            Signup
                    </button>
                    {/* </Link> */}
                    <div className={"mt-3"}>
                      {messageSignup && (
                        <UncontrolledAlert color={colors}>
                          <div className="alert-message">
                            <strong>{messageSignup}</strong>
                          </div>
                        </UncontrolledAlert>
                      )}
                    </div>
                  </Form>
                </div>
              </CardBody>
            </div>

            <hr className={"w-75 mx-auto"} />

            <div className="company-signup-card">
              <div className="m-sm-4">
                <div className="text-center">
                  <p className="lead font-weight-bold">
                    Does your organization want to sign up <br />
                    to the SkillsGap Analysis platform
                  </p>
                </div>
                <div className="text-center">
                 
                    <button className="btn btn-primary font-weight-bold">
                      Register Your Interest
                    </button>
                </div>
              </div>
            </div>
          </Card>
        </Col>

        <Col sm="5" md="8" lg="6" className="mx-auto p-0">
          <Card className="bg-primary h-100 shadow-lg">
            <div className="login-card">
              <CardBody>
                <div className="m-sm-4">
                  <div className="text-center">
                    <h2 className="h2-heading mb-4 text-white">Login</h2>
                  </div>
                  <Form onSubmit={handleLogin} ref={formUser}>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={onChangeEmail}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={onChangePassword}
                      />
                    </FormGroup>
                    <div className="forgot-div">
                      <small>
                        <Link
                          className="btn btn-link text-white font-weight-bold"
                          to="/auth/reset-password"
                        >
                          Forgot password?
                        </Link>
                      </small>
                    </div>
                    <div className="signin-div">
                      <button
                        className="btn btn-outline-primary text-white border-white d-block ml-auto mr-0 col-2 font-weight-bold"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Login</span>
                      </button>
                    </div>
                    <div className="alert-box-custom mt-3">
                      {message && (
                        <UncontrolledAlert color={colors}>
                          <div className="alert-message">
                            <strong>{message}</strong>
                          </div>
                        </UncontrolledAlert>
                      )}
                    </div>
                  </Form>
                </div>
              </CardBody>
            </div>

            <hr className="w-75 border-white mx-auto" />

                    {/*
            <div className="company-login-card">
              <div className="m-sm-4">
                <div className="text-center">
                  <h4 className="mb-4 text-white font-weight-bold">
                    Login to Company
                  </h4>
                </div>
                <Form className="col-8 mx-auto">
                  <FormGroup>
                                    <Input
                                        bsSize="lg"
                                        type="email"
                                        
                                        placeholder="Email Address"
                                        value={companyUserEmail}
                                        onChange={onChangeCompanyUserEmail}
                                    />
                  </FormGroup>

                  <div className="signin-div">
                   
                                    <button className="btn btn-outline-primary border-white ml-auto mr-0 d-block font-weight-bold text-white" onClick={validateUserEmailCompanyLogin}>
                        Login
                      </button>
                 
                  </div>
                </Form>
              </div>
            </div> */}
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <div>
        <Redirect to="/dashboard/default" />
      </div>
    );
  }
};
export default LoginPanel;
