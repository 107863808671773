import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  CardLink,
  CardText,
  Container,
  FormGroup,
  Input,
} from "reactstrap";
import {
  faStopwatch,
  faTag,
  faStar,
  faShoppingCart,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import { Filter, Settings } from "react-feather";
import { Button, Alert, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import Watch from "../../../assets/img/icons/watch.png";
import Bulb from "../../../assets/img/icons/bulb.png";
import Wheel from "../../../assets/img/icons/wheel.png";
import CartImg from "../../../assets/img/icons/cart-icon.png";

import shopService from "../../../AuthServices/shop.service.js";
import AuthService from "../../../AuthServices/auth.service.js";

import "../TabView.css";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const ShopView = (props) => {
  var currentUser = AuthService.getCurrentUser();
  if (currentUser == null || currentUser == undefined) {
    props.history.push("/");
    window.location.reload();
  }

  //for tab view
  const [toggleState, setToggleState] = useState(1);

  //for ratting
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  //for popover
  const directions = [
    {
      name: "Top",
      value: "top",
    },
  ];

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    // Some logic
  };

  const [courseDetails, setCourseDetails] = useState([]);
  const [numberOfAssessmentSignup, setNumberOfAssessmentSignup] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [messageShopAssessment, setMessageShopAssessment] = useState(null);

  const [buyAssessmentMessage, setBuyAssessmentMessage] = useState(null);

  useEffect(() => {
    var currentUserId = currentUser.id;

    const coursesDetails = () => {
      var courseDetailList = shopService.GetAllCourseDetails(currentUserId);

      var details = courseDetailList
        .then((response) => {
          return response;
        })
        .then((data) => {
          setCourseDetails(data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    coursesDetails();
  }, []);

  const onChangeNumberOfAssessmentSignup = (e, id) => {
    debugger;
    setCourseId(id);
    var assessmentNum = [];
    var result = [...courseDetails]; //<- copy course into result
    result = result.map((x) => {
      //<- use map on result to find element to update using id
      if (x.AssessmentId === id) x.numOfAssessment = e.target.value;
      return x;
    });
    assessmentNum = result;

    setCourseDetails(assessmentNum); //<- update ass no with value edited
  };

  const buyNowClick = (id) => {
    debugger;

        var currentUserId = currentUser.id;
        setCourseId(id);
        console.log(courseId);
        var NoOfAssessment = [...courseDetails];
        NoOfAssessment = NoOfAssessment.map((x) => {
            if (x.AssessmentId === id) {
                if (x.numOfAssessment.length > 0 && courseId != "") {
                    shopService
                        .BuyCourses(x.numOfAssessment, courseId, currentUserId)
                        .then((response) => {
                            if (response.data.Status === "Success") {
                                toast.success("Congratulations for Buying new Assessment  !!", { position: toast.POSITION.TOP_CENTER })
                                //setAssessmentModalshow(false);
                            } else {
                                toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER })
                            }
                        });
                } else {
                    //setBuyAssessmentMessage("Please fill number of assessment !!");
                    toast.error('Please fill number of assessment !!', { position: toast.POSITION.TOP_CENTER })
                }
            }
        });
    };

  return (
    <>
      <div className="bloc-Mytabs mb-4">
        <button
          className={toggleState === 1 ? "Mytabs active-Mytabs" : "Mytabs"}
          onClick={() => toggleTab(1)}
        >
          Buy Courses
        </button>
        <button
          className={toggleState === 2 ? "Mytabs active-Mytabs" : "Mytabs"}
          onClick={() => toggleTab(2)}
        >
          Course Categories
        </button>
        <button
          className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
          onClick={() => toggleTab(3)}
        >
          Cart
        </button>
        <button
          className={toggleState === 4 ? "Mytabs active-Mytabs" : "Mytabs"}
          onClick={() => toggleTab(4)}
        >
          Invoices
        </button>
      </div>
      <div className="content-Mytabs">
        <div
          className={
            (toggleState === 1 ? "content-t  active-content-t" : "content-t") +
            " bg-transparent"
          }
        >
          {/*......................................Buy Courses Tab.......................................*/}

          <Row>
            {courseDetails.map((schema) => {
              return (
                <div className="col-lg-4 col-md-6" key={schema.AssessmentId}>
                  <Card className="shop-assessment-card">
                    <CardHeader>
                      <Row className="align-items-center">
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Watch}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>{schema.AssessmentName}</h3>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="my-hr" />
                    <CardBody>
                      <Row className="align-items-center">
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faStopwatch}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Hours Required</p>
                              <p className="font-weight-bold mb-0">12 Hours</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faTag}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Total Cost</p>
                              <p className="font-weight-bold mb-0">
                                $ {schema.Default_Credit}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                    <hr className="my-hr" />
                    <CardBody>
                      <p>
                        Course Description.Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry.
                      </p>

                      <FormGroup>
                        <Input
                          bsSize="lg"
                          type="number"
                          name={schema.AssessmentId}
                          placeholder="Number of Assessment"
                          onChange={(e) =>
                            onChangeNumberOfAssessmentSignup(
                              e,
                              schema.AssessmentId
                            )
                          }
                          value={schema.numOfAssessment}
                        />
                      </FormGroup>
                    </CardBody>
                    <hr />

                    <CardBody>
                      <div className="float-left">
                        <a href="#">More info.</a>
                      </div>
                      <Button
                        color="primary"
                        className="float-right"
                        onClick={() => buyNowClick(schema.AssessmentId)}
                      >
                        Buy Now{" "}
                        <FontAwesomeIcon
                          style={{ color: "#ffffff" }}
                          icon={faShoppingCart}
                        />{" "}
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              );
            })}

            {buyAssessmentMessage && (
              <Alert variant="danger">{buyAssessmentMessage}</Alert>
            )}

            {/*{messageShopAssessment && <Alert variant="success">{messageShopAssessment}</Alert>}*/}

            {/**
                                 <div class="col-lg-4 col-md-6">
                                 <Card className="my-card">
                                 <CardHeader>
                                 <Row>
                                 <div class="col-lg-4 col-md-6">
                                 <img

                                 src={Bulb}
                                 className="img-responsive mt-2"
                                 width="50"
                                 height="50"
                                 />
                                 </div>
                                 <div class="col-lg-8 col-md-12">
                                 <h3>Assessment <br />
                                 Name 2</h3>
                                 <div>
                                 {[...Array(5)].map((star, index) => {
                                                                    const ratingValue = index + 1;
                                                                    return <label>
                                                                        <input className=".my-radio-hide"
                                                                            type="radio"
                                                                            name="rating"
                                                                            style={{ display: 'none' }}
                                                                            value={ratingValue}
                                                                            onClick={() => setRating(ratingValue)}
                                                                        />
                                                                        <FontAwesomeIcon
                                                                            className="star"
                                                                            color={ratingValue <= (hover || rating) ? "#3B82EC" : "#e4e5e9"}
                                                                            icon={faStar}
                                                                            onMouseEnter={() => setHover(ratingValue)}
                                                                            onMouseLeave={() => setHover(null)}
                                                                            fixedWidth
                                                                        />
                                                                    </label>;
                                                                })}

                                 </div>
                                 </div>
                                 </Row>

                                 </CardHeader>
                                 <hr className="my-hr" />
                                 <CardBody>
                                 <Row>
                                 <div class="col-lg-6 col-md-6">
                                 <Row>
                                 <div class="col-lg-4 col-md-6">
                                 <FontAwesomeIcon
                                 style={{ color: '#3B82EC' }}
                                 icon={faStopwatch}
                                 fixedWidth
                                 />
                                 </div>
                                 <div class="col-lg-8 col-md-6">
                                 <span >Hours Required</span>
                                 <h6>12 Hours</h6>
                                 </div>
                                 </Row>
                                 </div>
                                 <div class="col-lg-6 col-md-6">
                                 <Row>
                                 <div class="col-lg-4 col-md-6">
                                 <FontAwesomeIcon
                                 style={{ color: '#3B82EC' }}
                                 icon={faTag}
                                 fixedWidth
                                 />
                                 </div>
                                 <div class="col-lg-8 col-md-6">
                                 <span >Total Cost</span>
                                 <h6>$ 900</h6>
                                 </div>
                                 </Row>
                                 </div>
                                 </Row>
                                 </CardBody>
                                 <hr className="my-hr" />
                                 <CardBody>
                                 <p>
                                 Course Description.Lorem Ipsum is simply dummy text of the
                                 printing and typesetting industry.
                                 </p>
                                 </CardBody>
                                 <hr className="my-hr" />

                                 <CardBody>
                                 <div className="float-left"><a href="#">More info.</a></div>
                                 <Button color="primary" className="float-right" >Add to cart <FontAwesomeIcon style={{ color: '#ffffff' }} icon={faShoppingCart} /> </Button>
                                 </CardBody>
                                 </Card>
                                 </div>


                                 <div class="col-lg-4 col-md-6">
                                 <Card className="my-card">
                                 <CardHeader>
                                 <Row>
                                 <div class="col-lg-4 col-md-6">
                                 <img

                                 src={Wheel}
                                 className="img-responsive mt-2"
                                 width="50"
                                 height="50"
                                 />
                                 </div>
                                 <div class="col-lg-8 col-md-12">
                                 <h3>Assessment <br />
                                 Name 3</h3>
                                 <div>
                                 {[...Array(5)].map((star, index) => {
                                                                    const ratingValue = index + 1;
                                                                    return <label>
                                                                        <input className=".my-radio-hide"
                                                                            type="radio"
                                                                            name="rating"
                                                                            value={ratingValue}
                                                                            style={{ display: 'none' }}
                                                                            onClick={() => setRating(ratingValue)}
                                                                        />
                                                                        <FontAwesomeIcon
                                                                            className="star"
                                                                            color={ratingValue <= (hover || rating) ? "#3B82EC" : "#e4e5e9"}
                                                                            icon={faStar}
                                                                            onMouseEnter={() => setHover(ratingValue)}
                                                                            onMouseLeave={() => setHover(null)}
                                                                            fixedWidth
                                                                        />
                                                                    </label>;
                                                                })}

                                 </div>
                                 </div>
                                 </Row>

                                 </CardHeader>
                                 <hr className="my-hr" />
                                 <CardBody>
                                 <Row>
                                 <div class="col-lg-6 col-md-6">
                                 <Row>
                                 <div class="col-lg-4 col-md-6">
                                 <FontAwesomeIcon
                                 style={{ color: '#3B82EC' }}
                                 icon={faStopwatch}
                                 fixedWidth
                                 />
                                 </div>
                                 <div class="col-lg-8 col-md-6">
                                 <span >Hours Required</span>
                                 <h6>12 Hours</h6>
                                 </div>
                                 </Row>
                                 </div>
                                 <div class="col-lg-6 col-md-6">
                                 <Row>
                                 <div class="col-lg-4 col-md-6">
                                 <FontAwesomeIcon
                                 style={{ color: '#3B82EC' }}
                                 icon={faTag}
                                 fixedWidth
                                 />
                                 </div>
                                 <div class="col-lg-8 col-md-6">
                                 <span >Total Cost</span>
                                 <h6>$ 900</h6>
                                 </div>
                                 </Row>
                                 </div>
                                 </Row>
                                 </CardBody>
                                 <hr className="my-hr" />
                                 <CardBody>
                                 <p>
                                 Course Description.Lorem Ipsum is simply dummy text of the
                                 printing and typesetting industry.
                                 </p>
                                 </CardBody>
                                 <hr className="my-hr" />

                                 <CardBody>
                                 <div className="float-left"><a href="#">More info.</a></div>
                                 <Button color="primary" className="float-right" >Add to cart <FontAwesomeIcon style={{ color: '#ffffff' }} icon={faShoppingCart} /> </Button>
                                 </CardBody>
                                 </Card>
                                 </div> */}
          </Row>
        </div>

        {messageShopAssessment && (
          <Alert variant="success" style={{ width: "400px" }}>
            {messageShopAssessment}
          </Alert>
        )}

        {/*...................................Course Categoris Tab...................................*/}
        <div
          className={
            toggleState === 2 ? "content-t  active-content-t" : "content-t"
          }
        >
          <Card>
            <CardBody>
              <h2 className="mb-3">Assessments Categories</h2>

              <Row>
                <div className="col-lg-6 col-md-6">
                  <Card className="my-card shadow-lg assessment-course-cat">
                    <CardHeader>
                      <Row className="align-items-center">
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Watch}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>
                            Procurement <br />
                            Assessments
                          </h3>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="" />
                    <CardBody>
                      <p>
                        Course Description. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Aliquam nunc massa,
                        elementum vitae semper eget, aliquet eu ipsum. Aliquam
                        et sodales odio. Aliquam nunc massa, elementum vitae
                        semper eget, alique.
                      </p>
                      <Button color="primary" className="float-left">
                        View
                      </Button>
                    </CardBody>
                  </Card>
                </div>

                <div className="col-lg-6 col-md-6">
                  <Card className="my-card assessment-course-cat">
                    <CardHeader>
                      <Row className="align-items-center">
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Bulb}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>
                            Contract Management <br />
                            Assessments
                          </h3>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="" />
                    <CardBody>
                      <p>
                        Course Description. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Aliquam nunc massa,
                        elementum vitae semper eget, aliquet eu ipsum. Aliquam
                        et sodales odio. Aliquam nunc massa, elementum vitae
                        semper eget, alique.
                      </p>
                      <Button color="primary" className="float-left">
                        View
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              </Row>

              <Row>
                <div className="col-lg-6 col-md-6">
                  <Card className="my-card assessment-course-cat">
                    <CardHeader>
                      <Row>
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Wheel}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>
                            Supply Chain <br />
                            Assessments
                          </h3>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="" />
                    <CardBody>
                      <p>
                        Course Description. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Aliquam nunc massa,
                        elementum vitae semper eget, aliquet eu ipsum. Aliquam
                        et sodales odio. Aliquam nunc massa, elementum vitae
                        semper eget, alique.
                      </p>
                      <Button color="primary" className="float-left">
                        View
                      </Button>
                    </CardBody>
                  </Card>
                </div>

                <div className="col-lg-6 col-md-6">
                  <Card className="my-card assessment-course-cat">
                    <CardHeader>
                      <Row>
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Watch}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>
                            Commercial <br />
                            Assessments
                          </h3>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="" />
                    <CardBody>
                      <p>
                        Course Description. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Aliquam nunc massa,
                        elementum vitae semper eget, aliquet eu ipsum. Aliquam
                        et sodales odio. Aliquam nunc massa, elementum vitae
                        semper eget, alique.
                      </p>
                      <Button color="primary" className="float-left">
                        View
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </CardBody>
          </Card>
        </div>

        {/*.........................Cart Tab.....................*/}
        <div
          className={
            (toggleState === 3 ? "content-t  active-content-t" : "content-t") +
            " p-0 bg-transparent"
          }
        >
          <Row>
            <div className="col-lg-8 col-md-8">
              {/*.........................Assessment Lists.....................*/}

              <Row>
                <div className="col-lg-6 col-md-6">
                  <Card className="shop-assessment-card">
                    <CardHeader>
                      <Row>
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Watch}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>
                            Assessment <br />
                            Name 1
                          </h3>
                          <div>
                            {[...Array(5)].map((star, index) => {
                              const ratingValue = index + 1;
                              return (
                                <label>
                                  <input
                                    
                                    type="radio"
                                    style={{display:"none"}}
                                    name="rating"
                                    value={ratingValue}
                                    onClick={() => setRating(ratingValue)}
                                  />
                                  <FontAwesomeIcon
                                    className="star"
                                    color={
                                      ratingValue <= (hover || rating)
                                        ? "#3B82EC"
                                        : "#e4e5e9"
                                    }
                                    icon={faStar}
                                    onMouseEnter={() => setHover(ratingValue)}
                                    onMouseLeave={() => setHover(null)}
                                    fixedWidth
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="my-hr" />
                    <CardBody className="px-0">
                      <Row>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faStopwatch}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Hours Required</p>
                              <p className="font-weight-bold mb-0">12 Hours</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faTag}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Total Cost</p>
                              <p className="font-weight-bold mb-0">$ 900</p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                    <hr className="my-hr" />
                    <CardBody className="py-2 px-2">
                      <p className="mb-0">
                        Course Description.Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry.
                      </p>
                    </CardBody>
                    <hr className="my-hr" />

                    <CardBody className="py-2 px-2">
                      <div className="float-left">
                        <a href="#">More info...</a>
                      </div>
                      <Button color="primary" className="float-right">
                        Add to cart{" "}
                        <FontAwesomeIcon
                          style={{ color: "#ffffff" }}
                          icon={faShoppingCart}
                        />
                      </Button>
                    </CardBody>
                  </Card>
                </div>

                <div className="col-lg-6 col-md-6">
                  <Card className="shop-assessment-card">
                    <CardHeader>
                      <Row>
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Bulb}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>
                            Assessment <br />
                            Name 1
                          </h3>
                          <div>
                            {[...Array(5)].map((star, index) => {
                              const ratingValue = index + 1;
                              return (
                                <label>
                                  <input
                                    style={{display:"none"}}
                                    type="radio"
                                    name="rating"
                                    value={ratingValue}
                                    onClick={() => setRating(ratingValue)}
                                  />
                                  <FontAwesomeIcon
                                    className="star"
                                    color={
                                      ratingValue <= (hover || rating)
                                        ? "#3B82EC"
                                        : "#e4e5e9"
                                    }
                                    icon={faStar}
                                    onMouseEnter={() => setHover(ratingValue)}
                                    onMouseLeave={() => setHover(null)}
                                    fixedWidth
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="my-hr" />
                    <CardBody className="px-0">
                      <Row>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faStopwatch}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Hours Required</p>
                              <p className="font-weight-bold mb-0">12 Hours</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faTag}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Total Cost</p>
                              <p className="font-weight-bold mb-0">$ 900</p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                    <hr className="my-hr" />
                    <CardBody className="py-2 px-2">
                      <p className="mb-0">
                        Course Description.Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry.
                      </p>
                    </CardBody>
                    <hr className="my-hr" />

                    <CardBody className="py-2 px-2">
                      <div className="float-left">
                        <a href="#">More info...</a>
                      </div>
                      <Button color="primary" className="float-right">
                        Add to cart{" "}
                        <FontAwesomeIcon
                          style={{ color: "#ffffff" }}
                          icon={faShoppingCart}
                        />
                      </Button>
                    </CardBody>
                  </Card>
                </div>

                <div className="col-lg-6 col-md-6">
                  <Card className="shop-assessment-card">
                    <CardHeader>
                      <Row>
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Wheel}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>
                            Assessment <br />
                            Name 1
                          </h3>
                          <div>
                            {[...Array(5)].map((star, index) => {
                              const ratingValue = index + 1;
                              return (
                                <label>
                                  <input
                                    style={{display:"none"}}
                                    type="radio"
                                    name="rating"
                                    value={ratingValue}
                                    onClick={() => setRating(ratingValue)}
                                  />
                                  <FontAwesomeIcon
                                    className="star"
                                    color={
                                      ratingValue <= (hover || rating)
                                        ? "#3B82EC"
                                        : "#e4e5e9"
                                    }
                                    icon={faStar}
                                    onMouseEnter={() => setHover(ratingValue)}
                                    onMouseLeave={() => setHover(null)}
                                    fixedWidth
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="my-hr" />
                    <CardBody className="px-0">
                      <Row>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faStopwatch}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Hours Required</p>
                              <p className="font-weight-bold mb-0">12 Hours</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faTag}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Total Cost</p>
                              <p className="font-weight-bold mb-0">$ 900</p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                    <hr className="my-hr" />
                    <CardBody className="py-2 px-2">
                      <p className="mb-0">
                        Course Description.Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry.
                      </p>
                    </CardBody>
                    <hr className="my-hr" />

                    <CardBody className="py-2 px-2">
                      <div className="float-left">
                        <a href="#">More info...</a>
                      </div>
                      <Button color="primary" className="float-right">
                        Add to cart{" "}
                        <FontAwesomeIcon
                          style={{ color: "#ffffff" }}
                          icon={faShoppingCart}
                        />
                      </Button>
                    </CardBody>
                  </Card>
                </div>

                <div className="col-lg-6 col-md-6">
                  <Card className="shop-assessment-card">
                    <CardHeader>
                      <Row>
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Watch}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>
                            Assessment <br />
                            Name 1
                          </h3>
                          <div>
                            {[...Array(5)].map((star, index) => {
                              const ratingValue = index + 1;
                              return (
                                <label>
                                  <input
                                    style={{display:"none"}}
                                    type="radio"
                                    name="rating"
                                    value={ratingValue}
                                    onClick={() => setRating(ratingValue)}
                                  />
                                  <FontAwesomeIcon
                                    className="star"
                                    color={
                                      ratingValue <= (hover || rating)
                                        ? "#3B82EC"
                                        : "#e4e5e9"
                                    }
                                    icon={faStar}
                                    onMouseEnter={() => setHover(ratingValue)}
                                    onMouseLeave={() => setHover(null)}
                                    fixedWidth
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="my-hr" />
                    <CardBody className="px-0">
                      <Row>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faStopwatch}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Hours Required</p>
                              <p className="font-weight-bold mb-0">12 Hours</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faTag}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Total Cost</p>
                              <p className="font-weight-bold mb-0">$ 900</p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                    <hr className="my-hr" />
                    <CardBody className="py-2 px-2">
                      <p className="mb-0">
                        Course Description.Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry.
                      </p>
                    </CardBody>
                    <hr className="my-hr" />

                    <CardBody className="py-2 px-2">
                      <div className="float-left">
                        <a href="#">More info...</a>
                      </div>
                      <Button color="primary" className="float-right">
                        Add to cart{" "}
                        <FontAwesomeIcon
                          style={{ color: "#ffffff" }}
                          icon={faShoppingCart}
                        />
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </div>
            <div className="col-lg-4 col-md-4">
              {/*.........................After Added into cart.....................*/}
              <Card className="my-card parent-gray_card bg-dark">
                <CardHeader className="parent-gray_card bg-transparent">
                  <Row className="align-items-center">
                    <div className="col-lg-4 col-md-6">
                      <img
                        src={CartImg}
                        className="img-responsive mt-2"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div className="col-lg-8 col-md-12">
                      <h3 className="text-white mb-0 font-weight-bolder">
                        Your Shopping <br />
                        Cart
                      </h3>
                    </div>
                  </Row>
                </CardHeader>
                <hr className="my-hr" />
                <CardBody>
                  <Row>
                    <div className="col-lg-6 col-md-6">
                      <div className="media align-items-center">
                        <FontAwesomeIcon
                          style={{ color: "#3B82EC" }}
                          icon={faTag}
                          fixedWidth
                          size={"2x"}
                          className="mr-2"
                        />
                        <div className="media-body">
                          <p className="small mb-0">Total</p>
                          <p className="font-weight-bolder mb-0 text-white h4">
                            $1800
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="card-font">Price are in AUD</p>
                    </div>
                  </Row>
                </CardBody>
                <hr className="my-hr" />
                <CardBody>
                  <p className="card-font">You have 2 courses in your cart</p>
                  <Row>
                    <div className="col-lg-12 col-md-6">
                      <CardHeader className="light-gray_card">
                        <Row>
                          <div className="col-lg-4 col-md-6">
                            <img
                              src={Watch}
                              className="img-responsive mt-2 white-img"
                              width="50"
                              height="50"
                            />
                          </div>
                          <div className="col-lg-8 col-md-12">
                            <h3 className="card-font">
                              Assessment <br />
                              Name 3
                            </h3>
                            <div>
                              <h6 className="card-font">
                                <span>
                                  <FontAwesomeIcon
                                    style={{ color: "#3B82EC" }}
                                    icon={faTag}
                                    fixedWidth
                                  />
                                </span>
                                Total cost
                              </h6>
                              <h6 className="card-font card-space-left ">
                                {" "}
                                $900
                              </h6>
                            </div>
                            <div className="float-right card-font">
                              <a href="#">Remove</a>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <br />
                      <CardHeader className="light-gray_card">
                        <Row>
                          <div className="col-lg-4 col-md-6">
                            <img
                              src={Watch}
                              className="img-responsive mt-2"
                              width="50"
                              height="50"
                            />
                          </div>
                          <div className="col-lg-8 col-md-12">
                            <h3 className="card-font">
                              Assessment <br />
                              Name 4
                            </h3>
                            <div>
                              <h6 className="card-font">
                                <span>
                                  <FontAwesomeIcon
                                    style={{ color: "#3B82EC" }}
                                    icon={faTag}
                                    fixedWidth
                                  />
                                </span>
                                Total cost
                              </h6>
                              <h6 className="card-font card-space-left ">
                                {" "}
                                $900
                              </h6>
                            </div>
                            <div className="float-right card-font">
                              <a href="#">Remove</a>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                    </div>
                  </Row>
                </CardBody>
                <hr className="my-hr" />

                <CardBody>
                  <Button color="primary" className="float-right">
                    Checkout{" "}
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>

        {/*.........................Invoices Tab.....................*/}
        <div
          className={
            toggleState === 4 ? "content-t  active-content-t" : "content-t"
          }
        >
          <Card>
            <CardBody>
              <h2>Invoices</h2>

              <Table striped hover>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice Number</th>
                    <th>Price</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const SgaShop = () => (
  <div id="shop_div">
    <Row className="mb-2 mb-xl-4">
      <Col xs="auto" className="ml-auto text-right mt-n1">
        <Button color="primary" className="shadow-sm mr-1">
          <Filter className="feather" />
        </Button>
        <Button color="primary" className="shadow-sm">
          <Settings className="feather" />
        </Button>
      </Col>
    </Row>
    <ShopView />
  </div>
);

export default SgaShop;
