import axios from "axios";

const API_URL = process.env.REACT_APP_COMPANY_API_URL;

const GetCompanyList = () => {
    return axios
        .get(API_URL + "GetCompanyList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetCompanyDetailsById = (id) => {
     
    return axios
        .get(API_URL + "GetCompanyDetailsByCompanyId", { params: { companyId: id } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const DeleteCompanyById = (id) => {
    axios.delete(API_URL + "DeleteCompanyById", { params: { companyId: id } }).then(response => {
        console.log(response);
    });
};

const AddCompanyDetails = (companyNameSignup, /*companyPasswordSignup,*/ companyABVSignup, companyStateSignup, companyZipSignup,
    companyLogoSignup, /*companyEmailSignup, */companyCountrySignup, companyAddressSignup, companyIndustryNameSignup, /*role, firstName, lastName,*/ companyId, partnerId, companyDomainSignup) => {
     
    var companyDetails = new FormData();
    companyDetails.append("company_name", companyNameSignup);
    companyDetails.append("companyId", companyId);
    companyDetails.append("company_abn", companyABVSignup)
    //companyDetails.append("email", companyEmailSignup)
    //companyDetails.append("password", companyPasswordSignup)
    companyDetails.append("companyLogo", companyLogoSignup)

    companyDetails.append("companyCountry", companyCountrySignup)
    companyDetails.append("companyAddress", companyAddressSignup)
    companyDetails.append("companyIndustry", companyIndustryNameSignup)
    companyDetails.append("companyZip", companyZipSignup)
    companyDetails.append("companyState", companyStateSignup)
    companyDetails.append("companyDomain", companyDomainSignup)
    //companyDetails.append("roleId", role)
    //companyDetails.append("userFirstName", firstName)
    //companyDetails.append("userLastName", lastName)
    companyDetails.append("partnerId", partnerId)

    return axios.post(API_URL + "AddCompanyDetails", companyDetails);
};

const GetCountryList = () => {
    return axios
        .get(API_URL + "GetCountryList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};


const AddCompanyCreditDetails = (companyAddCreditsSignUp, companyId, currentUserId) => {
     
    var companyCreditDetails = new FormData();
    companyCreditDetails.append("companyId", companyId);
    companyCreditDetails.append("currentUserId", currentUserId);
    companyCreditDetails.append("companyCredits", companyAddCreditsSignUp)

    return axios.post(API_URL + "AddCompanyCreditDetails", companyCreditDetails);
};
const GetCompanyDetailsByFromUserEmail = (email) => {

    return axios
        .get(API_URL + "GetCompanyDetailsByFromUserEmail", { params: { email: email } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

export default {
    GetCompanyList, DeleteCompanyById, AddCompanyDetails, GetCompanyDetailsById, GetCountryList, AddCompanyCreditDetails, GetCompanyDetailsByFromUserEmail
};