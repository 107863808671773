
import React, { useEffect, useState, useRef } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Row
} from "reactstrap";
import { Filter, Settings } from "react-feather";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Watch from "../../../assets/img/icons/watch.png";
import assessmentService from "../../../AuthServices/assessment.service.js";
import { setInterval } from "timers";
import AuthService from "../../../AuthServices/auth.service.js";


const QuizView = (props) => {


	localStorage.removeItem("AssessmentQuizNTKey");
	localStorage.removeItem("ReviewAssessmentNTKey");


	var currentUser = AuthService.getCurrentUser();
	if (currentUser.roleName === "super-admin") {
		props.history.push("/dashboard/default");
		window.location.reload();
	}
	if (currentUser == null || currentUser == undefined) {
		props.history.push("/");
		window.location.reload();
	}
	if (props.history.action == "POP") {
		props.history.push({
			pathname: '/Assessments'
		})
		window.location.reload();
	}
	if (props.location.state == null || props.location.state == undefined) {
		props.history.push({
			pathname: '/Assessments'
		})
		window.location.reload();
	}

	if (props.history.action == "POP") {
		var isKeyPresent = (JSON.parse(localStorage.getItem("ReSubmitQuestionNTKey")));
		if (isKeyPresent == null) {
			props.history.push({
				pathname: '/Assessments'
			})
			window.location.reload();
		}
	}

	var CurrentQuestion = props.location.state.question;
	
	var OptionsWithScore = props.location.state.optionList;
	var currentCounter = props.location.state.currentCounter;
	var checkedRadio = props.location.state.selectedAnswer;
	var AssessmentName = props.location.state.AssessmentName;
	var assessmentId = props.location.state.assessmentId;
	var creditId = props.location.state.creditId;
	//for tab view
	const [toggleState, setToggleState] = useState(1);
	const [Title, setTitle] = useState(null);
	//const [AssessmentName, setAssessmentName] = useState(null);
	
	const [checkedRadioButton, setCheckedRadioButton] = useState(null);

	const toggleTab = (index) => {
		setToggleState(index);
	};

	useEffect(() => {
		setCheckedRadioButton(checkedRadio);
	}, []);
	var count = 0;

	const handleButtonClick = (e) => {

		var currentUser = AuthService.getCurrentUser();
		var userId = currentUser.id;
		assessmentService.AddUserAssessmentProgress(userId, assessmentId, "", 0, checkedRadioButton, null, null, creditId).then((response) => {
			return response;
		})
			.then((data) => {

				assessmentService.GetReviewAnsweredQuestions(userId, assessmentId,creditId).then(
					(response) => {
						if (response !== null) {
							localStorage.setItem("ReSubmitQuestionNTKey", "true");
							localStorage.setItem("AssessmentQuizNTKey", "false");
							localStorage.setItem("ReviewAssessmentNTKey", "false");
							props.history.push({
								pathname: '/ReviewAssessmentNonTimer',
								state: { questionsList: response[0], creditId: creditId }

							})
						}
					});

			});
	};



	const onChangeOfCheckedOption = (e) => {
		 
		setCheckedRadioButton(e.target.id);


	}

	return (


		<Row>
			<Col md="12">
				<div className="container">
					<div className="bloc-Mytabs">
						<button
							className={toggleState === 1 ? "Mytabs active-Mytabs" : "Mytabs"}
							onClick={() => toggleTab(1)} >
							Home
                                 </button>
						<button
							className={toggleState === 2 ? "Mytabs active-Mytabs" : "Mytabs"}
							onClick={() => toggleTab(2)}>
							Assessments
                                </button>
						<button
							className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
							onClick={() => toggleTab(3)}>
							User Analytics
                                </button>
					</div>

					<div className="content-Mytabs">
						<div
							className={toggleState === 1 ? "content-t  active-content-t" : "content-t"} >

							{/*......................................Assessment Quiz View .......................................*/}

							<Row>
								<div className="col-lg-8 col-md-8">

									<Card className="my-card">
										<CardBody>
											<Row>
												<div className="col-lg-6 col-md-6">
													<div className="float-left">
														<h4 className="my-h4-bold">{AssessmentName}</h4>
													</div>
												</div>
												<div className="col-lg-6 col-md-12">
													<div className="float-right">
														<h4 className="my-h4-blue">{Title}</h4>
													</div>
												</div>
											</Row>

										</CardBody>
										<hr className="my-hr" />

										<CardBody>
											<Row>
												<div className="col-lg-1 col-md-1">
													<div className="small-div-circle"> {currentCounter}</div>
												</div>

												<div className="col-lg-11 col-md-11">
													<h4 className="quiz-question-h5 ">Question</h4>
													<p> {CurrentQuestion}</p>
												</div>
											</Row>
										</CardBody>
										<hr className="my-hr" />

										<CardBody>
											<Row>
												<div className="col-lg-12 col-md-12">
													<h4>Answer</h4>
												</div>
											</Row>
										</CardBody>
										<CardBody>
											{OptionsWithScore.map((x) => (

												<div className="form-check" key={x.OptionsId}>
													<input name="radios-example" type="radio" className="form-check-input" id={x.OptionsId} value={x.Score} checked={checkedRadioButton == x.OptionsId} onChange={onChangeOfCheckedOption} />
													<label title="" className="form-check-label" htmlFor={x.OptionsId}>{x.Options}</label>
												</div>

											))}

										</CardBody>

										<CardBody>
											<Row>

												<div className="col-lg-8 col-md-8">
													<div className="float-right">
														<button id="submit-button" className="quiz-submit-btn btn-pill" onClick={handleButtonClick}>Submit</button>
													</div></div>
											</Row>
										</CardBody>

									</Card>

								</div>
							</Row>

						</div>

						<div
							className={toggleState === 2 ? "content-t  active-content-t" : "content-t"}>
							<h2>Team Assessments</h2>
							<hr />

						</div>

						<div
							className={toggleState === 3 ? "content-t  active-content-t" : "content-t"}>
							<h2>Organization Assessments</h2>
							<hr />

						</div>
						<div
							className={toggleState === 4 ? "content-t  active-content-t" : "content-t"}>
							<h2>Partner Assessments</h2>
							<hr />

						</div>
					</div>
				</div>
			</Col>


		</Row>

	);
}

const ReSubmitQuestionOptionNonTimer = (props) => (
	<>
		<Row className="mb-2 mb-xl-4">


			<Col xs="auto" className="ml-auto text-right mt-n1">


				<Button color="primary" className="shadow-sm mr-1">
					<Filter className="feather" />
				</Button>
				<Button color="primary" className="shadow-sm">
					<Settings className="feather" />
				</Button>

			</Col>
		</Row>
		<QuizView {...props} />
	</>
);



export default ReSubmitQuestionOptionNonTimer;

