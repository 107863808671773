import axios from "axios";

const API_URL = process.env.REACT_APP_ASSESSMENT_API_URL;

const GetCurrentDateAndTime = () => {
    return axios
        .get(API_URL + "GetCurrentDateAndTime")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetCurrentDateAndTimeFromServer = () => {
    return axios
        .get(API_URL + "GetCurrentDateAndTimeFromServer")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};



const GetAllAssessmentDetails = () => {
    return axios
        .get(API_URL + "GetAllAssessmentDetails")
        .then((response) => {
            //console.log(response.data.Message);
            localStorage.setItem("assessment", JSON.stringify(response.data));
            return response.data;
        });
};
const GetAssessments = () => {
    var value = JSON.parse(localStorage.getItem("assessment"));
    if (value == null) {
        axios
            .get(API_URL + "GetAllAssessmentDetails")
            .then((response) => {
                //console.log(response.data.Message);
                localStorage.setItem("assessment", JSON.stringify(response.data));
                value = JSON.parse(localStorage.getItem("assessment"));
            });
    }

    return value
};
const AddEntryInUserTestTable = (userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "AddEntryInUserTestTable", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
};


const AssignTheAssessmentToUser = (partnerId,userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "AssignTheAssessmentToUser", { params: { partnerId: partnerId, userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
};
const AddUserAssessmentProgress = (userId, assessmentId, topicId, questionId, answerId, startDate, endDate,creditId) => {

    return axios
        .get(API_URL + "AddUserAssessmentProgress", { params: { userId: userId, assessmentId: assessmentId, topicId: topicId, questionId: questionId, answerId: answerId, startTime: startDate, endTime: endDate, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetReviewAnsweredQuestions = (userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "GetReviewAnsweredQuestions", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetCurrentAssessmentSessionValues = (userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "GetCurrentAssessmentSessionValues", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const SetPendingTimeForTimer = (userId, assessmentId, creditId) => {
    return axios
        .get(API_URL + "SetPendingTimeForTimer", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetPendingTimeForTimer = (userId, assessmentId, creditId) => {
    return axios
        .get(API_URL + "GetTimeForTimer", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetAllAssessmentList = (userId) => {

    return axios
        .get(API_URL + "GetAllAssessmentList", {
            params: {
                userId: userId
            }
        })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const UpdateUserTestTable = (userId, assessmentId, creditId) => {
    return axios
        .get(API_URL + "UpdateUserTestTable", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}
const GetNoOfQuestionsPerAssessment = (id) => {

    return axios
        .get(API_URL + "GetNoOfQuestionsPerAssessment", { params: { assessmentId: id } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const CheckIfTheAssessmentIsLocked = (userId, assessmentId) => {

    return axios
        .get(API_URL + "CheckIfTheAssessmentIsLocked", { params: { userId: userId, assessmentId: assessmentId } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const IsTimerExhausted = (dateOriginal, dateToCompare) => {

    return axios
        .get(API_URL + "IsTimerExhausted", { params: { dateOriginal: dateOriginal, dateCurrent: dateToCompare } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetAssessmentResultReport = (assessmentId, userId,guid) => {

    return axios
        .get(API_URL + "GetAssessmentResultReport", { params: { assessmentId: assessmentId, userId: userId, guid:guid } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};


export default {
    GetCurrentDateAndTime, GetAllAssessmentDetails, GetAssessments, GetAllAssessmentList, GetNoOfQuestionsPerAssessment, AddEntryInUserTestTable, AddUserAssessmentProgress, GetReviewAnsweredQuestions, IsTimerExhausted, GetCurrentDateAndTimeFromServer, GetCurrentAssessmentSessionValues, SetPendingTimeForTimer, GetPendingTimeForTimer, UpdateUserTestTable, AssignTheAssessmentToUser, CheckIfTheAssessmentIsLocked, GetAssessmentResultReport
};