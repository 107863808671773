import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../AuthServices/auth.service.js";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Col,
  Input,
  UncontrolledAlert,
} from "reactstrap";
const colors = ["danger"];
const CompanyLoginPanel = (props) => {
  const [state, setstate] = useState("");
  const formCompany = useRef();
  const formCompanySignup = useRef();

  const [companyEmailLogin, setCompanyEmailLogin] = useState("");
  const [companyPasswordLogin, setCompanyPasswordLogin] = useState("");

  const [companyNameSignup, setCompanyNameSignup] = useState("");
  const [companyMessageSignup, setCompanyPasswordSignup] = useState("");
  const [companyEmailSignup, setCompanyEmailSignup] = useState("");

  const [companyPhoneSignup, setCompanyABVSignup] = useState("");
  const [companyContactPersonSignup, setCompanycolor1Signup] = useState("");

  const [messageCompany, setMessageCompany] = useState("");
  const [messageCompanySignup, setMessageCompanySignup] = useState("");

  const onChangeCompanyEmailLogin = (e) => {
    const username = e.target.value;
    setCompanyEmailLogin(username);
  };

  const onChangeCompanyPasswordLogin = (e) => {
    const password = e.target.value;
    setCompanyPasswordLogin(password);
  };

  const onChangeCompanyNameSignup = (e) => {
    const companyName = e.target.value;
    setCompanyNameSignup(companyName);
  };

  const onChangeMessageSignup = (e) => {
    const password = e.target.value;
    setCompanyPasswordSignup(password);
  };

  const onChangeCompanyPhone = (e) => {
    const companyABV = e.target.value;
    setCompanyABVSignup(companyABV);
  };

  const onChangeCompanyContactPersonSignup = (e) => {
    const companyColor = e.target.value;
    setCompanycolor1Signup(companyColor);
  };

  const onChangeCompanyEmailSignup = (e) => {
    const companyEmail = e.target.value;
    setCompanyEmailSignup(companyEmail);
  };

  const user = AuthService.getCurrentUser();
  var currentCompany = AuthService.getCurrentCompany();

  //if  user is logged in take to the user dashboard
  if (user) {
    props.history.push("/dashboard/default");
    window.location.reload();
  }

  //setting the state when the file is being uploaded (company logo)
  const onCompanyLogoChange = (event) => {
    // Update the state
    setstate({ file: event.target.files[0] });
  };

  const handleCompanyLogin = (e) => {
    e.preventDefault();
    setMessageCompany("");

    if (companyEmailLogin !== "" && companyPasswordLogin !== "") {
      //method is called for login to company if all the required fields are entered like password and companyName
      AuthService.loginCompany(companyEmailLogin, companyPasswordLogin).then(
        (response) => {
          if (response.Message === "") {
            //redirects to dashboard if login is success
            props.history.push("/dashboard/default");
            window.location.reload();
          } else {
            //in case of error from api show the error and keep on company login page
            setMessageCompany(response.Message);
            props.history.push("/companyLogin");
          }
        }
      );
    } else {
      setMessageCompany("Kindly fill all the fields");
    }
  };
  const handleCompanySignup = (e) => {
    e.preventDefault();

    setMessageCompanySignup("");

    if (
      companyNameSignup !== "" &&
      companyMessageSignup !== "" &&
      companyPhoneSignup !== "" &&
      companyContactPersonSignup !== "" &&
      companyEmailSignup !== ""
    ) {
      AuthService.registerCompany(
        companyNameSignup,
        companyMessageSignup,
        companyEmailSignup,
        companyPhoneSignup,
        companyContactPersonSignup
      ).then((response) => {
        if (response.data.Status === "Success") {
          props.history.push("/thankyou");
          window.location.reload();
        } else {
          setMessageCompanySignup(response.data.Message);
          props.history.push("/companyLogin");
        }
      });
    } else {
      setMessageCompanySignup("Kindly fill all the fields");
    }
  };
  if (currentCompany === null) {
    return (
      <React.Fragment>
        <Col sm="5" md="8" lg="6" className="mx-auto d-table h-100">
          <Card>
            <div className="signup-othercard ">
              <CardBody>
                <div className="m-sm-4">
                  <div className="text-center">
                    <h2 className="h2-black-heading">Sign up</h2>
                  </div>
                  <Form onSubmit={handleCompanySignup} ref={formCompanySignup}>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="Company Name"
                        placeholder="Company Name"
                        value={companyNameSignup}
                        onChange={onChangeCompanyNameSignup}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="email"
                        name="email"
                        placeholder="Company Email"
                        value={companyEmailSignup}
                        onChange={onChangeCompanyEmailSignup}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="companyPhone"
                        placeholder="Company Phone no."
                        value={companyPhoneSignup}
                        onChange={onChangeCompanyPhone}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="lastname"
                        placeholder="Contact Person Name"
                        value={companyContactPersonSignup}
                        onChange={onChangeCompanyContactPersonSignup}
                      />
                    </FormGroup>
                    {/*<FormGroup>*/}

                    {/*    <input type="file" onChange={onCompanyLogoChange} accept="image/*" />*/}

                    {/*</FormGroup>*/}

                    <FormGroup>
                      <Input
                        bsSize="lg"
                        placeholder="Message"
                        name="message"
                        type="text"
                        value={companyMessageSignup}
                        onChange={onChangeMessageSignup}
                      />
                    </FormGroup>
                    <div className="signin-div">
                      {/* <Link to="/thankyou"> */}
                      <Button className="btnBlue" onClick={handleCompanySignup}>
                        Register
                      </Button>
                      {/* </Link> */}
                    </div>
                    <div>
                      {messageCompanySignup && (
                        <UncontrolledAlert color={colors}>
                          <div className="alert-message">
                            <strong>{messageCompanySignup}</strong>
                          </div>
                        </UncontrolledAlert>
                      )}
                    </div>
                  </Form>
                </div>
              </CardBody>
            </div>
          </Card>
        </Col>

        <Col sm="5" md="8" lg="6" className="mx-auto d-table h-100">
          <Card>
            <div className="othercard">
              <CardBody>
                <div className="m-sm-4">
                  <div className="text-center">
                    <h2 className="h2-heading">Login</h2>
                  </div>
                  <Form onSubmit={handleCompanyLogin} ref={formCompany}>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="email"
                        name="companyName"
                        placeholder="Company Email"
                        onChange={onChangeCompanyEmailLogin}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        bsSize="lg"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={onChangeCompanyPasswordLogin}
                      />
                    </FormGroup>

                    <div className="forgot-div">
                      <small>
                        <Link className="white-text" to="/auth/reset-password">
                          Forgot password?
                        </Link>
                      </small>
                    </div>
                    <div className="signin-div">
                      {/* <Link to="/dashboard/default"> */}
                      <Button className="btnBlue">login</Button>
                      {/* </Link> */}
                    </div>
                  </Form>
                  <div>
                    {messageCompany && (
                      <UncontrolledAlert color={colors}>
                        <div className="alert-message">
                          <strong>{messageCompany}</strong>
                        </div>
                      </UncontrolledAlert>
                    )}
                  </div>
                </div>
              </CardBody>
            </div>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <div>
        <Redirect to="/dashboard/default" />
      </div>
    );
  }
};
export default CompanyLoginPanel;
