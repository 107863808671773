import axios from "axios";

const API_URL = process.env.REACT_APP_PARTNER_API_URL;

const GetPartnerList = () => {
    return axios
        .get(API_URL + "GetPartnerList")
        .then((response) => {
            return response.data;
        });
};

const GetPartnerDetailsById = (id) => {
    return axios
        .get(API_URL + "GetPartnerDetailsByPartnerId", { params: { partnerId: id } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetPartnerUserDetailsByPartnerId = (id) => {
    return axios
        .get(API_URL + "GetPartnerUserDetailsByPartnerId", { params: { partnerId: id } })
        .then((response) => {
            return response.data;
        });
};




const AddPartnerDetails = (partnerNameSignup, partnerLogoSignup, partnerAddressSignup, partnerABNSignup, partnerStateSignup, partnerCountrySignup, partnerZipSignup, partnerId) => {

    var partnerDetails = new FormData();
    //partnerDetails.append("partnerFirstName", partnerFirstNameSignup);
    partnerDetails.append("partnerId", partnerId);
    //partnerDetails.append("partnerLastName", partnerLastNameSignup)
    partnerDetails.append("partnertName", partnerNameSignup)
    //partnerDetails.append("partnerEmail", partnerEmailSignup)
    //partnerDetails.append("partnerPassword", partnerPasswordSignup)
    partnerDetails.append("partnerLogo", partnerLogoSignup)
    partnerDetails.append("partnerAddress", partnerAddressSignup)
    partnerDetails.append("partnerABN", partnerABNSignup)
    partnerDetails.append("partnerState", partnerStateSignup)
    partnerDetails.append("partnerCountry", partnerCountrySignup)
    partnerDetails.append("partnerZip", partnerZipSignup)


    return axios.post(API_URL + "AddPartnerDetails", partnerDetails);
};

const GetCountryList = () => {
    return axios
        .get(API_URL + "GetCountryList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const AddPartnerCreditDetails = (partnerAddCreditsSignUp, partnerId, currentUserId) => {

    var partnerCreditDetails = new FormData();
    partnerCreditDetails.append("partnerId", partnerId);
    partnerCreditDetails.append("currentUserId", currentUserId);
    partnerCreditDetails.append("partnerCredits", partnerAddCreditsSignUp)

    return axios.post(API_URL + "AddPartnerCreditDetails", partnerCreditDetails);
};


export default {
    GetPartnerList,
    AddPartnerDetails,
    GetPartnerDetailsById,
    GetCountryList,
    AddPartnerCreditDetails, GetPartnerUserDetailsByPartnerId
};

