import AuthService from "../../AuthServices/auth.service.js";
import React, { useState, useRef, useEffect } from "react";

import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    UncontrolledAlert,
    Input,
    CardHeader,
} from "reactstrap";

const ChangePassword = (props) => {
    debugger;
    var isKeyPresent = (JSON.parse(localStorage.getItem("InChangePwdPage")));
    if (isKeyPresent == null) {
        props.history.push({
            pathname: '/'
        })
        window.location.reload();
    }

    const colors = ["danger"];
    const queryParams = new URLSearchParams(window.location.search);
    let authId = queryParams.get('AuthId');
    //if there is no AuthId then set to 0
    if (authId === null) {
        authId = '';
    }
    

    const [newPasswordForReset, setNewPasswordForReset] = useState("");
    const [confirmPasswordForReset, setConfirmPasswordForReset] = useState("");
    const [messagePassReset, setMessagePassReset] = useState("");
    const [userEmailForReset, setUserEmailForReset] = useState("");


    useEffect(() => {
        //set the values after taking from the session/local storage
            setUserEmailForReset(authId);
    }, []);


    const ChangePassIfOldPasswordValid = () => {
        if (newPasswordForReset != "" && confirmPasswordForReset != "") {
            if (newPasswordForReset != confirmPasswordForReset) {
                setMessagePassReset("Password and Corfirm Password are not same");
            }
            else {
                if (userEmailForReset != "") {
                    AuthService.changePasswordIfEmailValid(userEmailForReset,  newPasswordForReset).then((response) => {
                        if (response.Status === "200") {

                            setUserEmailForReset("");
                            
                            setMessagePassReset("Password changed successfully, Please login using the new password");

                            setTimeout(function () {
                                localStorage.removeItem("InChangePwdPage");

                                props.history.push({
                                    pathname: '/'
                                })
                                window.location.reload();
                            }, 5000);

                        } else {
                            setMessagePassReset(response.Message);
                        }
                    });
                }
                else {
                     props.history.push("/");
                               window.location.reload();
                }
            }
        }
        else {
            setMessagePassReset("Kindly enter all the fields");
        }
    }

    const onChangeNewPasswordForReset = (e) => {
        const newPassword = e.target.value;
        setNewPasswordForReset(newPassword);
    };
    const onChangeConfirmPasswordForReset = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPasswordForReset(confirmPassword);
    };

    return (
        <React.Fragment>
            <Card className="mx-auto col-6 pt-3">
                <CardHeader className="text-center pb-0">
                    <h1 className="h2">Change password</h1>
                </CardHeader>
                <CardBody>
                    <div className="m-sm-4">
                        <Form>
                            <FormGroup>

                                <Input
                                    bsSize="lg"
                                    type="password"
                                    name="password"
                                    placeholder="Enter new password"
                                    value={newPasswordForReset}
                                    onChange={onChangeNewPasswordForReset}
                                />

                            </FormGroup>
                            <FormGroup>

                                <Input
                                    bsSize="lg"
                                    type="password"
                                    name="password"
                                    placeholder="Confirm password"
                                    value={confirmPasswordForReset}
                                    onChange={onChangeConfirmPasswordForReset}
                                />

                            </FormGroup>
                            <div className="text-center mt-3">

                                <Button color="primary" size="lg" onClick={ChangePassIfOldPasswordValid}>
                                    Change Password
                </Button>

                            </div>
                            <div className={"mt-3"}>
                                {messagePassReset && (
                                    <UncontrolledAlert color={colors}>
                                        <div className="alert-message">
                                            <strong>{messagePassReset}</strong>
                                        </div>
                                    </UncontrolledAlert>
                                )}
                            </div>
                        </Form>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default ChangePassword;
