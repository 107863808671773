
import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
} from "reactstrap";

import { Filter, Settings } from "react-feather";
import { Button } from "react-bootstrap";
import AuthService from "../../../AuthServices/auth.service.js";
import BarChart from "../../../components/chart/BarChart";


const AssessmentsTypes = (props) => {
    var currentUser = AuthService.getCurrentUser();
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    var score = 0;
    if (props.location.state == null || props.location.state == undefined) {
        score = 0;
    }
    else {
        score = props.location.state.Score;
    }

    return (

        <Card>

            <CardHeader>
                <CardTitle tag="h5"> <h2>SGA Assessment Result</h2>
                </CardTitle>

            </CardHeader>

            <CardBody>

                <Row>

                    <Col md={ 12}>
                        <div className="container">

                            <div className="content-Mytabs">
                                <div>
                                   

                                </div>
                                <div className="">
                                    <h2>Hii!!  YOU SCORED {score}</h2>
                                </div>

                            </div>
                        </div>
                    </Col>


                </Row>

            </CardBody>

            <CardBody>

                <Row>
                    <Col md="12">
                        <div className="container">

                            <BarChart chartData={props.location.state.Data} />

                        </div>
                    </Col>
                </Row>
            </CardBody>



        </Card>
    );
}

const SgaAssessmentsResult = (props) => (
    <>
        <Row className="mb-2 mb-xl-4">


            <Col xs="auto" className="ml-auto text-right mt-n1">


                <Button color="primary" className="shadow-sm mr-1">
                    <Filter className="feather" />
                </Button>
                <Button color="primary" className="shadow-sm">
                    <Settings className="feather" />
                </Button>

            </Col>
        </Row>
        <AssessmentsTypes {...props} />
    </>
);



export default SgaAssessmentsResult;
