import React, { useState } from "react";
import Autocomplete from "../../../components/Autocomplete";
import { Hint } from 'react-autocomplete-hint';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Row,
    FormGroup,
    Label,
    Input, Alert
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import validator from 'validator'

import avatar4 from "../../../assets/img/avatars/avatar.png";
import AuthService from "../../../AuthServices/auth.service.js";
import { useEffect } from "react";
import userService from "../../../AuthServices/user.service";
import CompanyService from "../../../AuthServices/companies.service";
//import DatePicker from 'react-datepicker';
//import "react-datepicker/dist/react-datepicker.css";

const AssesmentDetail = () => (
    <Card>
        <CardHeader>
            <h1 className="mb-0 h3">
                Assessment Details
            </h1>
        </CardHeader>
        <CardBody className="text-center">
            progress..........
        </CardBody>

        <hr className="my-0" />

    </Card>
);





const CreditDetail = () => {
    var currentUser = AuthService.getCurrentUser();

    const [credits, setCredits] = useState("");

    

    useEffect(() => {

        var currentUserId = currentUser.id;

        const creditDetail = () => {
            var creditBalance = userService.GetCreditDetail(currentUserId);

            var balance = creditBalance.then((response) => {
                return response;
            })
                .then((data) => {

                    setCredits(data);
                })
                .catch(e => {
                    console.log(e);
                })
        };

        creditDetail();
    }, []);

    if (currentUser.roleName === "partner" || currentUser.roleName === "company") {
        return (

            <Card>
                <CardHeader>
                    <h1 className="mb-0 h3">
                        Credit Balance
                    </h1>
                </CardHeader>

                <CardBody className="text-center">
                    <div>
                        <h4>Balance : {credits} </h4>
                    </div>
                </CardBody>
                <hr className="my-0" />
            </Card>
        )
    }

    else
    {
       return (<div></div>)
    }
    
  
};



const Profile = (props) => {
    var currentUser = AuthService.getCurrentUser();
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    var currentCompany = AuthService.getCurrentCompany();
    const [userName, setUserName] = useState(true);
    if (props.history.location.state != undefined) {
        currentUser.FirstName = props.history.location.state.firstName;
        currentUser.LastName = props.history.location.state.lastName;

    }

    
    useEffect(() => {


        if (currentUser !== null) {
            setUserName(currentUser.FirstName + " " + currentUser.LastName)

        }
        //else {
        //    setUserName(currentCompany.CompanyName)
        //}

       

    });
    return (
        <Card>

            <CardHeader>
                <CardTitle tag="h5">User Profile  &nbsp;
                </CardTitle>

            </CardHeader>

            <CardBody>

                <Row>
                    <Col md="4">
                        <div className="text-center">
                            <img
                                alt="Chris Wood"
                                src={avatar4}
                                className="rounded-circle img-responsive mt-2"
                                width="128"
                                height="128"
                            />
                            <div className="mt-2"><br />
                                <Button color="primary" className=" btn-rounded float-right"  >
                                    +
                                    </Button>
                            </div>
                        </div>
                    </Col>

                    <Col md="8">
                        <h4>{userName}</h4>
                        <hr className="my-0" /><br />
                        <p>Hello !!!!<br />
                        </p>
                    </Col>

                </Row>
            </CardBody>
        </Card>
    );
};


const Details = (props) => {
    var currentUser = AuthService.getCurrentUser();

    if (props.history.location.state != undefined) {
        currentUser.id = props.history.location.state.data;

    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }

    // const [startDate, setStartDate] = useState(new Date());
    const [disabledFields, setDisabledFields] = useState(true);
    const [userProfileObj, setUserProfileObj] = useState(null);
    const [firstNameProfile, setFirstNameProfile] = useState(null);
    const [lastNameProfile, setLastNameProfile] = useState(null);
    const [registrationDateProfile, setRegistrationDateProfile] = useState(null);
    const [experienceUserProfile, setExperienceUserProfile] = useState("0");
    const [industryUserProfile, setIndustryUserProfile] = useState("0");
    const [passwordUserProfile, setPasswordUserProfile] = useState(null);
    const [educationUserProfile, setEducationUserProfile] = useState("0");
    const [specialistUserProfile, setSpecialistUserProfile] = useState("0");

    const [phoneUserProfile, setPhoneUserProfile] = useState(null);
    const [proLevelUserProfile, setProLevelUserProfile] = useState("0");
    const [stateUserProfile, setStateUserProfile] = useState(null);
    const [positionUserProfile, setPositionUserProfile] = useState("0");
    const [geoInfluenceUserProfile, setGeoInfluenceUserProfile] = useState("0");
    const [postCodeUserProfile, setPostCodeUserProfile] = useState(null);
    const [roleUserProfile, setRoleUserProfile] = useState("0");
    const [sectorUserProfile, setSectorUserProfile] = useState("0");
    const [countryUserProfile, setCountryUserProfile] = useState(null);
    const [jobLevelUserProfile, setJobLevelUserProfile] = useState(null);
    const [countries, setCountry] = useState([]);
    const [jobRoles, setJobRoles] = useState("");
    const [jobRoleUserProfile, setJobRoleUserProfile] = useState("0");
    const [editButtonText, seteditButtonText] = useState("Edit");
    const [hintData, setHintData] = useState([])
    const [suggestions, setSuggestions] = useState([])

    const [experiences, setExperience] = useState("");

    const [industries, setIndustries] = useState("");

    const [educations, setEducation] = useState("");

    const [specialists, setSpecialists] = useState("");


    const [sectors, setSectors] = useState("");

    const [geoInfluences, setGeoInfluences] = useState("");

    const [positions, setPositions] = useState("");

    const [proLevels, setProlevels] = useState("");

    const [messageUserProfile, setMessageUserProfile] = useState(null);

    const [stateList, setStateList] = useState(null);
    const [text, setText] = useState('');
    const [variant, setVariant] = useState("success");

    useEffect(() => {
        if (userProfileObj === null) {

            var userobj = userService.GetUserProfiledetailsByUserId(currentUser.id).then(
                (response) => {
                    if (response !== null) {

                        setUserProfileObj(response);
                        console.log(response);
                        setFirstNameProfile(response.FirstName)
                        setLastNameProfile(response.LastName)
                        setRegistrationDateProfile(response.registration_date)
                        setExperienceUserProfile(response.experience)
                        setIndustryUserProfile(response.industry)
                        setPasswordUserProfile(response.password)
                        setEducationUserProfile(response.education)
                        setSpecialistUserProfile(response.specialist)

                        setPhoneUserProfile(response.phone_number)
                        setJobRoleUserProfile(response.job_role)
                        setProLevelUserProfile(response.pro_level)
                        setText(response.state)
                        setPositionUserProfile(response.position)
                        setGeoInfluenceUserProfile(response.geo_influence)
                        setPostCodeUserProfile(response.postcode)
                        setRoleUserProfile(response.RoleId)
                        setSectorUserProfile(response.sector)
                        setCountryUserProfile(response.country)
                         
                        userService.GetStateList(274).then(
                            (response1) => {
                                if (response1 !== null) {
                                     
                                    setStateList(response1);
                                    var hintArray = []
                                    response1.map(a => hintArray.push(a.name))
                                    setHintData(hintArray)

                                }
                            });
                        setJobLevelUserProfile(response.job_level)
                    }
                });
        }
        if (jobRoles.length === 0) {
            const dropdownCompany = userService.GetJobRoles().then(
                (response) => {

                    if (response !== null) {
                        setJobRoles(response)
                    }

                }
            );
        }
        if (positions.length === 0) {
            const dropdownCompany = userService.GetPositionList().then(
                (response) => {

                    if (response !== null) {
                        setPositions(response)
                    }

                }
            );
        }



        if (proLevels.length === 0) {
            const dropdownCompany = userService.GetProLevelList().then(
                (response) => {

                    if (response !== null) {
                        setProlevels(response)
                    }

                }
            );
        }

        if (geoInfluences.length === 0) {
            const dropdownCompany = userService.GetGeoInfluenceList().then(
                (response) => {

                    if (response !== null) {
                        setGeoInfluences(response)
                    }

                }
            );
        }

        if (sectors.length === 0) {
            const dropdownCompany = userService.GetSectorList().then(
                (response) => {

                    if (response !== null) {
                        setSectors(response)
                    }

                }
            );
        }
        if (educations.length === 0) {
            const dropdownCompany = userService.GetEducation().then(
                (response) => {

                    if (response !== null) {
                        setEducation(response)
                    }

                }
            );
        }
        if (industries.length === 0) {
            const dropdownCompany = userService.GetIndustries().then(
                (response) => {

                    if (response !== null) {
                        setIndustries(response)
                    }

                }
            );
        }
        if (specialists.length === 0) {
            const dropdownCompany = userService.GetSpecialist().then(
                (response) => {

                    if (response !== null) {
                        setSpecialists(response)
                    }

                }
            );
        }
        if (experiences.length === 0) {
            const dropdownCompany = userService.GetExperience().then(
                (response) => {

                    if (response !== null) {
                        setExperience(response)
                    }

                }
            );
        }
        if (countries.length === 0) {
            const countryData = CompanyService.GetCountryList();

            var details = countryData.then((response) => {
                return response;
            })
                .then((data) => {
                    setCountry(data);
                })
                .catch(e => {
                    console.log(e);
                })
        }

    });
    const onChangeFirstNameProfile = (e) => {
        const firstName = e.target.value;
        setFirstNameProfile(firstName);
    }
    const onChangeLastNameProfile = (e) => {
        const lastName = e.target.value;
        setLastNameProfile(lastName);
    }
    const onChangeExperience = (e) => {
        const experience = e.target.value;
        setExperienceUserProfile(experience);
    };
    const onChangeIndustry = (e) => {
        const industry = e.target.value;
        setIndustryUserProfile(industry);
    };
    const onChangeEducation = (e) => {
        const education = e.target.value;
        setEducationUserProfile(education);
    };
    const onChangeSpecialist = (e) => {
        const specialist = e.target.value;
        setSpecialistUserProfile(specialist);
    };
    const onSuggestionHandler = (text) => {
        setText(text);
        setSuggestions([]);
    };
    const onChangeState = (text) => {
        let matches = [];
        if (text.length > 0) {
            matches = stateList.filter(x => {
                const regex = new RegExp(`${text}`, "gi")
                return  x.name.match(regex)
            })
        }
        setSuggestions(matches);
        setText(text);
    };
    const onChangeCompanyCountrySignup = (e) => {
        const companyCountry = e.target.value;
        setCountryUserProfile(companyCountry);
    };
    const handleUserRoleChange = (e) => {
        const role = e.target.value;
        setRoleUserProfile(role);
    }
    const onChangeRegisterationDate = (e) => {
        const registerationDate = e.target.value;
        setRegistrationDateProfile(registerationDate);
    }
    const onChangeProLevelUserProfile = (e) => {
        const proLevel = e.target.value;
        setProLevelUserProfile(proLevel);
    };
    const onChangePositionUserProfile = (e) => {
        const position = e.target.value;
        setPositionUserProfile(position);
    };
    const onChangeGeoInfluenceUserProfile = (e) => {
        const geoInfluence = e.target.value;
        setGeoInfluenceUserProfile(geoInfluence);
    };
    const onChangeSectorUserProfile = (e) => {
        const sector = e.target.value;
        setSectorUserProfile(sector);
    };
    const onChangePhoneNumberProfile = (e) => {
        const phone = e.target.value;
        setPhoneUserProfile(phone);
    }
    const onChangeJobRole = (e) => {

        const jobRole = e.target.value;
        setJobRoleUserProfile(jobRole);
    };

    const onChangeJobLevel = (e) => {

        const jobLevel = e.target.value;
        setJobLevelUserProfile(jobLevel);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPasswordUserProfile(password);
    }
    const onChangePostCode = (e) => {
        const postCode = e.target.value;
        setPostCodeUserProfile(postCode);
    }
    const validatePassword = (e) => {

        const password = e.target.value;

        if (validator.isStrongPassword(password, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setMessageUserProfile('')
            setPasswordUserProfile(password);
        } else {
            setVariant("danger");
            setMessageUserProfile('Please enter a  strong password(minLength: 8, minLowercase: 1,minUppercase: 1, minNumbers: 1, minSymbols: 1)')
        }
    }


    const onClickBtnEdit = () => {
        if (disabledFields) {
            setDisabledFields(false);
            seteditButtonText("Save");
        } else {
             
            if (firstNameProfile !== "" && lastNameProfile !== "" && registrationDateProfile !== "" && experienceUserProfile !== "0" && industryUserProfile !== "0" && passwordUserProfile !== "" && educationUserProfile !== "0" && specialistUserProfile !== "0" && phoneUserProfile !== "" && jobRoleUserProfile !== "0" && proLevelUserProfile !== "0" && text !== "" && positionUserProfile !== "0" && geoInfluenceUserProfile !== "0" && jobLevelUserProfile !== "" && postCodeUserProfile !== "" && roleUserProfile !== "0" && sectorUserProfile !== "0" && countryUserProfile !== "0") {
                userService.SaveUserProfileFields(firstNameProfile, lastNameProfile, registrationDateProfile, experienceUserProfile, industryUserProfile, passwordUserProfile, educationUserProfile, specialistUserProfile, phoneUserProfile, jobRoleUserProfile, proLevelUserProfile, text, positionUserProfile, geoInfluenceUserProfile, jobLevelUserProfile, postCodeUserProfile, roleUserProfile, sectorUserProfile, countryUserProfile, currentUser.id, (props.history.location.state != undefined && props.history.location.state.data!=undefined?false:true))
                    .then(
                        (response) => {
                            if (response !== null) {
                                setVariant("success");
                                setMessageUserProfile("Details updated successfully");
                                window.location.reload();
                            }
                            else {
                                setVariant("danger");
                                setMessageUserProfile("There is some error saving the details!! Please try again")
                            }
                            setDisabledFields(true);
                            seteditButtonText("Edit");
                        }

                    )

            }
            else {
                setVariant("danger");
                setMessageUserProfile("Kindly fill all the fields!!")

            }
        }
    };

    const onClickBtnCancel = () => {
        seteditButtonText("Edit");
        setDisabledFields(true);
        setMessageUserProfile("")
        setSuggestions([]);
    };
    return (
        <Card>
            <CardHeader>

                <CardTitle tag="h5" className="mb-0">
                    User Details
      </CardTitle>
            </CardHeader>
            <CardBody>
                {messageUserProfile && <Alert color={variant} style={{ "width": "400px" }}>{messageUserProfile}</Alert>}

                <Row >
                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>First Name</Label>
                            <Input
                                bsSize="lg"
                                type="text"
                                name="first-name"
                                placeholder="Enter your first name" value={firstNameProfile} onChange={onChangeFirstNameProfile}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>


                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Last Name</Label>
                            <Input
                                bsSize="lg"
                                type="text"
                                name="last-name"
                                placeholder="Last Name" value={lastNameProfile} onChange={onChangeLastNameProfile}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>
                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Password</Label>
                            <Input
                                bsSize="lg"
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={passwordUserProfile} onChange={onChangePassword} onBlur={validatePassword}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Job Title</Label>
                            <Input
                                bsSize="lg"
                                type="text"
                                name="job-Title"
                                placeholder="Job Title"
                                value={jobLevelUserProfile} onChange={onChangeJobLevel}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Phone Number</Label>
                            <Input
                                bsSize="lg"
                                type="text"
                                name="phone"
                                placeholder="Phone Number" value={phoneUserProfile} onChange={onChangePhoneNumberProfile}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>
                    <Col xs={4} md={4}>
                        <FormGroup>
                            Registeration Date 
                            <Input
                                bsSize="lg"
                                type="date"
                                name="date"
                                placeholder="Enter Registeration Date(xx/xx/xx)"
                                value={registrationDateProfile}
                                pattern="\d{4}-\d{2}-\d{2}"
                                onChange={onChangeRegisterationDate} disabled={disabledFields} className="form-control" readOnly="readonly"
                            />
                            </FormGroup>

                    </Col>

                    

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Experience</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={experienceUserProfile} onChange={onChangeExperience}
                            >
                                <option value="0">Select Experience</option>
                                {experiences &&
                                    experiences.length > 0 &&
                                    experiences.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Industry</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={industryUserProfile} onChange={onChangeIndustry}  >
                                <option value="0">Select Industry</option>
                                {industries &&
                                    industries.length > 0 &&
                                    industries.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}
                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Education</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={educationUserProfile} onChange={onChangeEducation}
                            >
                                <option value="0">Select Education</option>
                                {educations &&
                                    educations.length > 0 &&
                                    educations.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>
                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Specialist</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={specialistUserProfile} onChange={onChangeSpecialist}
                            >
                                <option value="0">Select Specialist</option>
                                {specialists &&
                                    specialists.length > 0 &&
                                    specialists.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Job Role</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={jobRoleUserProfile} onChange={onChangeJobRole}
                            >
                                <option value="0">Select Experience</option>
                                {jobRoles &&
                                    jobRoles.length > 0 &&
                                    jobRoles.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Pro level</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={proLevelUserProfile} onChange={onChangeProLevelUserProfile}
                            >
                                <option value="0">Select Pro-Level</option>
                                {proLevels &&
                                    proLevels.length > 0 &&
                                    proLevels.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>


                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Position</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={positionUserProfile} onChange={onChangePositionUserProfile}
                            >
                                <option value="0">Select Position</option>
                                {positions &&
                                    positions.length > 0 &&
                                    positions.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Geo Influence</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={geoInfluenceUserProfile} onChange={onChangeGeoInfluenceUserProfile}
                            >
                                <option value="0">Select Geo-Influence</option>
                                {geoInfluences &&
                                    geoInfluences.length > 0 &&
                                    geoInfluences.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Postcode</Label>
                            <Input
                                bsSize="lg"
                                type="text"
                                name="post-code"
                                placeholder="Postcode"
                                value={postCodeUserProfile} onChange={onChangePostCode}
                                disabled={disabledFields} />
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Role</Label>

                            <select className="form-control" value={roleUserProfile} onChange={handleUserRoleChange} disabled={currentUser.roleName=="super-admin"? disabledFields:"disabled"} >
                                <option value="0">Select Role</option>
                                <option value="1">user</option>
                                <option value="2">team-member</option>
                                <option value="3">team-leader</option>
                                {/*<option value="4">company</option>*/}
                                {/*<option value="5">partner</option>*/}
                                {/*<option value="6">super-admin</option>*/}
                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Sector</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={sectorUserProfile} onChange={onChangeSectorUserProfile}
                            >
                                <option value="0">Select Sector</option>
                                {sectors &&
                                    sectors.length > 0 &&
                                    sectors.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>

                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>Country</Label>
                            <select disabled={disabledFields} name="expirience" className="form-control" value={countryUserProfile} onChange={onChangeCompanyCountrySignup}
                            >
                                <option value="0">Select Country</option>
                                {countries &&
                                    countries.length > 0 &&
                                    countries.map(schema => {
                                        return <option key={schema.id} value={schema.id}>{schema.country_name}</option>;
                                    })}


                            </select>
                        </FormGroup>
                    </Col>
                    <Col xs={4} md={4}>
                        <FormGroup>
                            <Label>State</Label>

                            {/*<Hint options={hintData} allowTabFill>*/}
                            {/*    <input className='input-with-hint' className="form-control" disabled={disabledFields}*/}
                            {/*        value={stateUserProfile}*/}
                            {/*        onChange={e => setStateUserProfile(e.target.value)}*/}

                            {/*    />*/}
                            {/*</Hint>*/}
                            <Input
                                bsSize="lg"
                                type="text"
                                name="state"
                                placeholder="State" value={text} onChange={e => onChangeState(e.target.value)}
                                disabled={disabledFields} />
                            {suggestions && suggestions.map((suggestion, i) => <div style={{ cursor: "pointer", borderRight: "1px solid black", borderLeft: "1px solid black", borderBottom: "1px solid black" }} key={i} onClick={() => onSuggestionHandler(suggestion.name)} >{suggestion.name}</div>)}
                        </FormGroup>
                    </Col>




                </Row>
                <Button color="primary" className="float-right" style={{ margin: '10px' }} onClick={onClickBtnCancel}>Cancel</Button>

                <Button color="primary" className="float-right" style={{ margin: '10px' }} onClick={onClickBtnEdit}>{editButtonText}</Button>
            </CardBody>
        </Card>
    );
};




const UserProfile = (props) => (
    <div id = "UserProfile">
    <Container fluid className="p-0">


        <Row>
            <Col md="8" xl="9">
                <Profile {...props}/>
                <Details {...props}/>
            </Col>

            <Col md="4" xl="3">
                <AssesmentDetail />
                <CreditDetail />
            </Col>

        </Row>
        </Container>
        </div>


);

export default UserProfile;

