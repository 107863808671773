import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Filter, Settings } from "react-feather";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Watch from "../../../assets/img/icons/watch.png";
import assessmentService from "../../../AuthServices/assessment.service.js";
import { setInterval } from "timers";
import AuthService from "../../../AuthServices/auth.service.js";

const QuizView = (props) => {
    localStorage.removeItem("ReSubmitQuestionKey");
    localStorage.removeItem("ReviewAssessmentKey");

    var currentUser = AuthService.getCurrentUser();
    var userId = currentUser.id;
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }

    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: "/Assessments",
        });
        window.location.reload();
    }
    if (props.history.action == "POP") {
        var isKeyPresent = JSON.parse(localStorage.getItem("AssessmentQuizKey"));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: "/Assessments",
            });
            window.location.reload();
        }
    }
    const titleRef = useRef();
    //fetching assessmentId from props as passed from assessment home page
    var assessmentId = props.location.state.assessmentId;
    var creditId = props.location.state.creditId;
    let currentTimerAssesss = null;
    //fetching questionObject from props as passed from assessment home page
    var questions = props.location.state.questionsList;

    //declaring other const for quiz related functionality
    const [toggleState, setToggleState] = useState(1);
    const [btnText, setbtnText] = useState("Next");
    const [totalQues, setTotalQues] = useState(0);
    const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0);
    const [questionCounter, setQuestionCounter] = useState(0);
    const [currentTitleIndex, setcurrentTitleIndex] = useState(0);
    const [assessmentData, setAssessmentData] = useState(null);
    const [datetimeFromServerForProgress, setDateTimeFromServerForProgress] =
        useState(null);
    const TitleList = questions;
    const currentQuestion =
        questions.TitleList[currentTitleIndex == null ? 0 : currentTitleIndex]
            .QuestionList[currentQuestionIndex == null ? 0 : currentQuestionIndex];
    const [checkedRadio, setCheckedRadio] = useState(null);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const [show, setShow] = useState(false);
    const [AssessmentName, setAssessmentName] = useState(
        questions.AssessmentName
    );
    const [Title, setTitle] = useState(
        questions.TitleList[currentTitleIndex].TitleName
    );

    const [seconds, setSeconds] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //declaring local variables for side bar qestion highlighting
    var count = 0;
    var countDots = 0;
    var topicNavCount = 0;
    let interval = useRef();
    let localTime = 0;

    //component DidMount
    //This is for persistence of data on refresh
    useEffect(() => {
        //set the values after taking from the session/local storage
        debugger;
        const currentSessionValues = () => {
            var sessionDetails = assessmentService
                .GetCurrentAssessmentSessionValues(userId, assessmentId, creditId);
            var detailsFetched = sessionDetails.then((response) => {
                if (response !== null && response.AssessmentId != 0) {
                    var timeForStorage = Number(
                        JSON.parse(localStorage.getItem("hourAssessment"))
                    );
                    if (timeForStorage != 0 && timeForStorage > 0) {
                        setSeconds(formatToHoursAndMinutes(timeForStorage));
                        localTime = timeForStorage;
                    } else {
                        setSeconds(formatToHoursAndMinutes(response.Create_Date));
                        localTime = response.Create_Date;
                        localStorage.setItem("hourAssessment", JSON.stringify(localTime));
                    }
                    setQuestionCounter(response.QuestionId);
                    setTitle(response.Title);
                    setAssessmentName(response.AssessmentName);
                    if (response.QuestionId <= 8) {
                        setcurrentQuestionIndex(response.QuestionId);
                    } else {
                        var totalQuestion = questions.TitleList.slice(
                            0,
                            response.TitleIndexSession + 1
                        );
                        var topicQues =
                            totalQuestion[totalQuestion.length - 1].NoOfQuestionsPerTopic;
                        setcurrentQuestionIndex(response.QuestionId % topicQues);
                    }
                    setcurrentTitleIndex(response.TitleIndexSession);
                    setCheckedRadio(response.OptionsId);

                    if (
                        questionCounter == totalQues - 2 ||
                        questionCounter == totalQues - 1
                    ) {
                        setbtnText("Submit");
                    }
                }
            });
        }
        currentSessionValues();
    }, []);

    //This is for persistence of data on refresh
    //useEffect(() => {
    //    debugger;
    //    //storing the current values of the page ,question, answer etc on refresh in local storage in order to maintain the state
    //    window.localStorage.setItem(
    //        "questionCounter",
    //        questionCounter == null ? 0 : questionCounter
    //    );
    //    window.localStorage.setItem("Title", Title);
    //    window.localStorage.setItem("AssessmentName", AssessmentName);
    //    window.localStorage.setItem(
    //        "currentQuestionIndex",
    //        currentQuestionIndex == null ? 0 : currentQuestionIndex
    //    );
    //    window.localStorage.setItem(
    //        "currentTitleIndex",
    //        currentTitleIndex == null ? 0 : currentTitleIndex
    //    );
    //    window.localStorage.setItem(
    //        "checkedRadio",
    //        checkedRadio == null ? 0 : checkedRadio
    //    );
    //}, [
    //    questionCounter,
    ///*state,*/ Title,
    //    AssessmentName,
    //    currentQuestionIndex,
    //    currentTitleIndex,
    //    assessmentData,
    //    checkedRadio,
    //]);

    const SetTimeForTimer = () => {
        if (seconds == null) {
            assessmentService
                .GetPendingTimeForTimer(userId, assessmentId, creditId)
                .then((response) => {
                    if (response !== null) {
                        if (response == 0) {
                            //clearInterval(interval.current);

                            setShow(true);
                            setTimeout(function () {
                                setShow(false);
                                localStorage.removeItem("hourAssessment");
                                localStorage.removeItem("DateTimeServer");
                                props.history.push({
                                    pathname: "/Assessments",
                                });
                                window.location.reload();
                            }, 2000);
                        } else {
                            var timeForStorage = Number(
                                JSON.parse(localStorage.getItem("hourAssessment"))
                            );
                            if (timeForStorage != 0 && timeForStorage > 0) {
                                setSeconds(formatToHoursAndMinutes(timeForStorage));
                                localTime = timeForStorage;
                            } else {
                                setSeconds(formatToHoursAndMinutes(response));
                                localTime = response;
                                localStorage.setItem(
                                    "hourAssessment",
                                    JSON.stringify(localTime)
                                );
                            }
                        }
                    }
                });
        }
    };

    useEffect(() => {
        if (seconds == null) {
            SetTimeForTimer();
        }

        setAssessmentName(questions.AssessmentName);
        setTitle(questions.TitleList[currentTitleIndex].TitleName);

        //Fetches the current date and time from the api
        //async function fetchCurrentDateAndTimeFromAPI() {
        const currentTime = () => {
            var timeData = assessmentService.GetCurrentDateAndTime();
            var dateAndTimeDetails = timeData.then((response) => {
                if (response !== null) {
                    currentTimerAssesss = response;
                    window.localStorage.setItem("DateTimeServer", currentTimerAssesss);
                    //  startTimer();
                    //starting the timer on the load of the page
                }
            });
        }
        //}
        /*if (datetimeFromServerForProgress == null) {*/
        const serverTime = () => {
            var serverTimeDetails = assessmentService.GetCurrentDateAndTimeFromServer();
            var detailsTime = serverTimeDetails.then((response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);
                }
            });
        }
        //Fetches assessment data
        //if (assessmentData === null) {
        const allAssessmentDetails = () => {
            var assessDetails = assessmentService.GetAllAssessmentDetails();
            var assessmentDetails = assessDetails.then((response) => {
                if (response !== null) {
                    setAssessmentData(response);
                }
            });
        }
        //Gets total number of questions from the api
        //if (totalQues == 0) {
        const noOfQuestions = () => {
            var noOfQues = assessmentService
                .GetNoOfQuestionsPerAssessment(assessmentId);
            var quesDet = noOfQues.then((response) => {
                if (response !== null) {
                    debugger;
                    setTotalQues(5);
                }
            });
        }
        currentTime();
        serverTime();
        allAssessmentDetails();
        noOfQuestions();
        //}
        //if the datetime is not set then set it from the api
        //if (window.localStorage.getItem("DateTimeServer") == undefined ||
        //    window.localStorage.getItem("DateTimeServer") == null
        //) {
        //    fetchCurrentDateAndTimeFromAPI();
        //} else {
        //    //startTimer();
        //    currentTimerAssesss = window.localStorage.getItem("DateTimeServer");
        //}
    }, []);

    const formatToHoursAndMinutes = (secondsVal) => {
        if (secondsVal < 0) {
            secondsVal = 0;
        }
        var sec_num = parseInt(secondsVal, 10);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor(sec_num / 60) % 60;
        var seconds = sec_num % 60;

        return [hours, minutes, seconds]
            .map((v) => (v < 10 ? "0" + v : v))
            .filter((v, i) => v !== "00" || i > 0)
            .join(":");
    };

    const handleNextButtonClick = () => {
        //getting the current user details from the local storage in order to pass to api the details and id of the current user giving the assessment
        var totalNum = questions.TitleList[currentTitleIndex].NoOfQuestionsPerTopic;
        if (
            currentQuestionIndex == totalNum - 1 &&
            questionCounter !== totalQues - 1
        ) {
            //adding the user assessment progress on every click, saving the response for the current answer.
            assessmentService.GetCurrentDateAndTimeFromServer().then((response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);

                    assessmentService
                        .AddUserAssessmentProgress(
                            userId,
                            assessmentId,
                            Title,
                            currentQuestionIndex,
                            checkedRadio,
                            datetimeFromServerForProgress,
                            datetimeFromServerForProgress,
                            creditId
                        )
                        .then((response) => {
                            if (response !== null) {
                                setbtnText("Next");
                                setcurrentQuestionIndex(0);

                                //incrementing title after the questions for one section has passed
                                setCheckedRadio(0);
                                setcurrentTitleIndex(currentTitleIndex + 1);
                                setTitle(questions.TitleList[currentTitleIndex + 1].TitleName);
                                setQuestionCounter(questionCounter + 1);
                                countDots = 0;
                            }
                        });
                }
            });
        } else if (questionCounter == totalQues - 1) {
            setbtnText("Submit");

            //submitting the quiz will send the user on review page where he/she can review and change answers.
            //Get the question and answers for review
            assessmentService.GetCurrentDateAndTimeFromServer().then((response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);
                    assessmentService
                        .AddUserAssessmentProgress(
                            userId,
                            assessmentId,
                            Title,
                            currentQuestionIndex,
                            checkedRadio,
                            datetimeFromServerForProgress,
                            datetimeFromServerForProgress,
                            creditId
                        )
                        .then((response) => {
                            return response;
                        })
                        .then((data) => {
                            assessmentService
                                .GetReviewAnsweredQuestions(userId, assessmentId, creditId)
                                .then((response) => {
                                    if (response !== null) {
                                        setCheckedRadio(0);
                                        localStorage.removeItem("ReSubmitQuestionKey");
                                        localStorage.removeItem("AssessmentQuizKey");
                                        localStorage.setItem("ReviewAssessmentKey", "false");

                                        props.history.push({
                                            pathname: "/ReviewAssessment",
                                            state: {
                                                questionsList: response[0],
                                                assessmentId: assessmentId,
                                                creditId: creditId,
                                            },
                                        });
                                    }
                                });
                        });
                }
            });
        } else {
            //adding the user assessment progress on every click, saving the response for the current answer.
            assessmentService.GetCurrentDateAndTimeFromServer().then((response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);

                    assessmentService
                        .AddUserAssessmentProgress(
                            userId,
                            assessmentId,
                            Title,
                            currentQuestionIndex,
                            checkedRadio,
                            datetimeFromServerForProgress,
                            datetimeFromServerForProgress,
                            creditId
                        )
                        .then((response) => {
                            if (response !== null) {
                                if (questionCounter == totalQues - 2) {
                                    setbtnText("Submit");
                                } else {
                                    setbtnText("Next");
                                }
                                setcurrentQuestionIndex(currentQuestionIndex + 1);
                                //incrementing the question counter
                                setQuestionCounter(questionCounter + 1);
                                setCheckedRadio(0);

                                // setting the title
                                setTitle(questions.TitleList[currentTitleIndex].TitleName);
                            }
                        });
                }
            });
        }
    };
    const handlePreviousButtonClick = () => {
        setbtnText("Next");
        //checking if the currentQuestionIndex and currentTitleIndex is not zero as it should not allow the user to go decrement
        if (!(currentQuestionIndex == 0 && currentTitleIndex == 0)) {
            if (currentQuestionIndex == 0) {
                if (currentQuestionIndex == 0) {
                    setcurrentQuestionIndex(currentQuestionIndex + 8);
                } else {
                    //decrementing the question counter
                    setcurrentQuestionIndex(currentQuestionIndex - 1);
                }
                setQuestionCounter(questionCounter - 1);
                countDots = 0;
                if (currentTitleIndex != 0) {
                    setcurrentTitleIndex(currentTitleIndex - 1);
                    setTitle(questions.TitleList[currentTitleIndex - 1].TitleName);
                }
            } else {
                if (currentQuestionIndex == 0 && currentTitleIndex > 0) {
                    setcurrentTitleIndex(currentTitleIndex - 1);
                    setQuestionCounter(questionCounter - 1);
                    setcurrentQuestionIndex(7);
                    setTitle(questions.TitleList[currentTitleIndex - 1].TitleName);
                } else {
                    setcurrentQuestionIndex(currentQuestionIndex - 1);
                    setQuestionCounter(questionCounter - 1);
                }
            }
        }
    };
    const handleButtonClick = (e) => {
        switch (e.target.id) {
            case "previous-button":
                handlePreviousButtonClick();
                break;
            case "submit-button":
                if (checkedRadio == "0" || checkedRadio == undefined) {
                    //if the radio for the answer is not selected, giving an alert /promt to answer the specific question
                    alert("Please select one option!");
                } else {
                    handleNextButtonClick();
                    //since the counter starts from zero checking the second last question next hit making the text of button to submit
                    if (questionCounter == totalQues - 2) {
                        setbtnText("Submit");
                    }
                }
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        const startTimer = () => {
            if (window.location.href.indexOf("AssessmentQuiz") > -1) {
                if (localTime > 0 && localTime != undefined) {
                    //var test = localTime;
                    //test = localTime - 1;
                    localTime = localTime - 1;
                    setSeconds(formatToHoursAndMinutes(localTime));
                    localStorage.setItem("hourAssessment", JSON.stringify(localTime));
                } else {
                    setSeconds(formatToHoursAndMinutes(0));
                    localStorage.removeItem("hourAssessment");
                    localTime = 0;
                }

                if (localTime % 10 == 0 &&
                    window.location.href.indexOf("AssessmentQuiz") > -1) {
                    assessmentService
                        .SetPendingTimeForTimer(userId, assessmentId, creditId)
                        .then((response) => {
                            if (response !== null && response != 0) {
                                var timeForStorage = Number(
                                    JSON.parse(localStorage.getItem("hourAssessment"))
                                );
                                if (timeForStorage != 0 && timeForStorage > 0) {
                                    setSeconds(formatToHoursAndMinutes(timeForStorage));
                                    localTime = timeForStorage;
                                    localStorage.setItem(
                                        "hourAssessment",
                                        JSON.stringify(localTime)
                                    );
                                } else {
                                    setSeconds(formatToHoursAndMinutes(response));
                                    localTime = response;
                                    localStorage.setItem(
                                        "hourAssessment",
                                        JSON.stringify(localTime)
                                    );
                                }
                            }
                            else {
                                assessmentService
                                    .UpdateUserTestTable(userId, assessmentId, creditId)
                                    .then((response) => {
                                        if (response !== null) {
                                            setSeconds(formatToHoursAndMinutes(0));
                                            localStorage.removeItem("hourAssessment");
                                            localStorage.removeItem("DateTimeServer");
                                            localTime = 0;
                                            // clearInterval(interval.current);
                                            setShow(true);
                                            setTimeout(function () {
                                                setSeconds(formatToHoursAndMinutes(0));
                                                localStorage.removeItem("hourAssessment");
                                                localStorage.removeItem("DateTimeServer");
                                                localTime = 0;
                                                setShow(false);
                                                props.history.push({
                                                    pathname: "/Assessments",
                                                });
                                                window.location.reload();
                                            }, 2000);
                                        }
                                    });
                            }
                        });
                }
            }
        };

        const timerId = setInterval(startTimer, 1000);
        return () => clearInterval(timerId);
    }, []);

    const onChangeOfCheckedOption = (e) => {
        setCheckedRadio(e.target.id);
    };

    return (
        <>
            <Row>
                <Modal backdrop="static"
                    size="lg"
                    show={show}
                    onHide={handleClose}
                    className="modal-colored modal-danger"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Time over!!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center m-3 modal-body">
                            <p className="mb-0 text-white">
                                {" "}
                Your assessment will now be automatically submitted!!
              </p>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="col-lg-8 col-md-8">
                    <Card className="my-card assessment-wrapper-home">
                        <CardBody className="pb-0">
                            <Row className="align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="float-left">
                                        <h1 className="">
                                            {AssessmentName ? AssessmentName : "Assessment Name"}
                                        </h1>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="float-right">
                                        <h2 className="h3 text-primary font-weight-bold">
                                            {Title ? Title : "Topic"}
                                        </h2>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                        <hr className="my-hr" />

                        <CardBody>
                            <Row>
                                <div className="col-lg-1 col-md-1">
                                    <div className="question-number rounded-circle text-white font-weight-bold h3">
                                        {" "}
                                        {questionCounter + 1}
                                    </div>
                                </div>

                                <div className="col-lg-11 col-md-11">
                                    <h4 className="quiz-question-h5 ">Question</h4>
                                    <p> {currentQuestion.Questions}</p>
                                </div>
                            </Row>
                        </CardBody>
                        <hr className="my-hr" />

                        <CardBody className="py-0">
                            <Row>
                                <div className="col-lg-12 col-md-12">
                                    <h4>Answer</h4>
                                </div>
                            </Row>
                        </CardBody>
                        <CardBody className="py-0">
                            {currentQuestion.OptionsWithScore.map((x) => (
                                <div className="option-padd">
                                    <div className="quiz-options-div">
                                        <div className="form-check" key={x.OptionsId}>
                                            <span className="radio-container">
                                                <input
                                                    name="radios-example"
                                                    type="radio"
                                                    className="form-check-input"
                                                    id={x.OptionsId}
                                                    value={x.Score}
                                                    checked={checkedRadio == x.OptionsId}
                                                    onChange={onChangeOfCheckedOption}
                                                />
                                                <span class="checkmark"></span>
                                            </span>

                                            <label
                                                title=""
                                                className="form-check-label"
                                                htmlFor={x.OptionsId}
                                            >
                                                {x.Options}-{x.OptionsId}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </CardBody>

                        <CardBody>
                            <Row className="align-items-top">
                                <div className="col-lg-4 col-md-4">
                                    {" "}
                                    {TitleList.TitleList[0].QuestionList.map((x) => (
                                        <FontAwesomeIcon
                                            className={
                                                countDots <= currentQuestionIndex
                                                    ? "text-primary"
                                                    : "text-secondary"
                                            }
                                            icon={faCircle}
                                            id={(countDots += 1)}
                                            fixedWidth
                                        />
                                    ))}
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    {/* <button className="quiz-btn-blue btn-pill" id="previous-button" onClick={handleButtonClick}>Previous Question</button> &nbsp;*/}
                                    <button
                                        id="submit-button"
                                        className="quiz-submit-btn btn btn-pill btn-primary d-block mr-0 ml-auto col-4"
                                        onClick={handleButtonClick}
                                    >
                                        {btnText}
                                    </button>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-4 col-md-4">
                    <Card
                        className="my-card"
                        style={{
                            maxHeight: "705px",
                            overflowY: "scroll",
                        }}
                    >
                        <CardHeader className="pb-0">
                            <Row>
                                <div className="col-lg-12 col-md-12">
                                    <Row>
                                        <Col sm={4} md={4}>
                                            <img
                                                src={Watch}
                                                className="img-responsive mt-2 mx-auto d-block"
                                                width="80"
                                                height="80"
                                            />
                                        </Col>
                                        <Col sm={8} md={8}>
                                            <div>
                                                <p className="mb-0">Your time left</p>
                                                <h3 className="display-4 font-weight-bold text-dark">
                                                    {seconds}
                                                </h3>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>
                        </CardHeader>
                        <hr className="my-hr" />

                        <CardBody
                            className="py-0"
                            style={{
                                minHeight: "auto",
                            }}
                        >
                            <Row>
                                <h2 className="h1 font-weight-bold text-dark text-left px-2">
                                    Your Course Progress
                </h2>
                            </Row>
                        </CardBody>
                        <hr className="my-hr" />
                        <CardBody className="py-0">
                            <Row>
                                <Col md={12} sm={12}>
                                    {/*sidebar*/}
                                    <div className="my-sidenav">
                                        <div className="scrollable">
                                            {TitleList.TitleList.map((x) => (
                                                <div id={(topicNavCount += 1)} ref={titleRef}>
                                                    {" "}
                                                    <h5 className="right-sidebar-h5">{x.TitleName}</h5>
                                                    {x.QuestionList.map((p) => (
                                                        <Card
                                                            className=""
                                                            className={
                                                                (questionCounter == count
                                                                    ? "my-question-card-Highlight border border-primary bg-primary text-white"
                                                                    : "my-question-card") +
                                                                " mb-2 pl-3 shadow-none"
                                                            }
                                                        >
                                                            Question {(count += 1)}
                                                        </Card>
                                                    ))}
                                                    <hr className="my-hr" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </>
    );
};

const AssessmentQuiz = (props) => (
    <>
        <Row className="mb-2 mb-xl-4">
            <Col xs="auto" className="ml-auto text-right mt-n1">
                <Button color="primary" className="shadow-sm mr-1">
                    <Filter className="feather" />
                </Button>
                <Button color="primary" className="shadow-sm">
                    <Settings className="feather" />
                </Button>
            </Col>
        </Row>
        <QuizView {...props} />
    </>
);

export default AssessmentQuiz;
