import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Container,
  Col,
  Row,
  Table,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  Calendar,
  Filter,
  RefreshCw,
  Settings,
  Plus,
  X,
  Search,
} from "react-feather";
import validator from "validator";
import DeleteConfirmation from "../../../../src/components/DeleteConfirmation";

import { Button, Modal, Alert } from "react-bootstrap";

import userService from "../../../AuthServices/user.service.js";
import AuthService from "../../../AuthServices/auth.service.js";
import CompanyService from "../../../AuthServices/companies.service";
import PartnerService from "../../../AuthServices/partners.service.js";
import TeamsService from "../../../AuthServices/teams.service.js";
import Pagination from "../../../../src/components/Pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

// SVG Icons
import iconChangeRole from "../../../assets/img/icons/change-role.svg";
import iconDeleteUser from "../../../assets/img/icons/delete.svg";

// Images
import imgDefaultAvatar from '../../../assets/img/avatars/avatar.png'
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

{
  /*...................................................User list operations code part..............................................................*/
}
const SgaUsers = (props) => {
  const userRoleList = [
    {
      id: 1,
      value: "user",
    },
    {
      id: 2,
      value: "team-member",
    },
    {
      id: 3,
      value: "team-leader",
    },
    {
      id: 4,
      value: "company",
    },
    {
      id: 5,
      value: "partner",
    },
  ];

  var currentUser = AuthService.getCurrentUser();
  var currentCompany = AuthService.getCurrentCompany();
  if (currentUser == null || currentUser == undefined) {
    props.history.push("/");
    window.location.reload();
  }
  if (
    currentUser.roleName === "user" ||
    currentUser.roleName === "team-leader" ||
    currentUser.roleName === "team-member"
  ) {
    props.history.push("/dashboard/default");
    window.location.reload();
  }
  const [filteredUser, setFilteredUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [filteredCompany, setfilteredCompany] = useState([]);
  const [filteredTeam, setfilteredTeam] = useState([]);
  const [id, setId] = useState(null);
  const [type, setType] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [userMessage, setMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [jobRoles, setJobRoles] = useState("");
  const [currentJobRole, setCurrentJobRole] = useState(0);
  const [partners, setPartners] = useState("");
  const [disabledCompany, setDisabledCompany] = useState(true);
  const [disabledTeam, setDisabledTeam] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [currentPosts, setCurrentPosts] = useState(1);
  const [teamSignUp, setTeamUserSignup] = useState(0);
  const [iscompanyLogin, setIscompanyLogin] = useState(false);
  const [totalUsers, setTotalUsers] = useState([]);
  const [arrowPositionFirstName, setArrowPositionFirstName] =
    useState("arrow down");
  const [arrowPositionLastName, setArrowPositionLastName] =
    useState("arrow down");
  const [arrowPositionRegisterationDate, setArrowPositionRegisterationDate] =
    useState("arrow down");
  const [arrowPositionCompany, setArrowPositionCompany] =
    useState("arrow down");
  const [arrowPositionPartner, setArrowPositionPartner] =
    useState("arrow down");
  const [arrowPositionRole, setArrowPositionRole] = useState("arrow down");
  const [arrowPositionJobRole, setArrowPositionJobRole] =
    useState("arrow down");
  const [arrowPositionCountry, setArrowPositionCountry] =
    useState("arrow down");
  const [arrowPositionState, setArrowPositionState] = useState("arrow down");
  const [arrowPositionForPosition, setArrowPositionForPosition] =
    useState("arrow down");

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //componentDidMount
  useEffect(() => {
    if (teamSignUp === 0 && currentUser.roleName === "company") {
      setFilteredUser(userRoleList.filter((x) => x.id !== 2 && x.id !== 3));
    }

    const userData = () => {
      var userDataList = userService.GetUserList();

      var details = userDataList
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (currentUser.roleName === "partner") {

                        setUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" || x.roleName === "company" || x.roleName === "user")));
                        setFilteredUser(userRoleList.filter(x => x.id === 2 || x.id === 1 || x.id === 4));
                        setState({ data: data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" || x.roleName === "company" || x.roleName === "user")) });
                        setTotalUsers(data.listOfUsers.filter(x => x.partner_id === currentUser.partner_id && (x.roleName === "team-member" || x.roleName === "company" || x.roleName === "user")));

                    }
                    else if (currentUser.roleName === "company") {

            setIscompanyLogin(true);
                        setUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && x.roleName === "team-member"));
                        setFilteredUser(userRoleList.filter(x => x.id === 2));
                        setState({ data: data.listOfUsers.filter(x => x.company_id === currentUser.company_id && x.roleName === "team-member") });
                        setTotalUsers(data.listOfUsers.filter(x => x.company_id === currentUser.company_id && x.roleName === "team-member"));


                    }
                    else {
            setUsers(data.listOfUsers);
                        setFilteredUser(userRoleList.filter(x => x.id === 1));
            setState({ data: data.listOfUsers });
            setTotalUsers(data.listOfUsers);
          }

        })
        .catch((e) => {
          console.log(e);
        });
    };

    const teamListData = () => {
      var teamListValues = TeamsService.GetTeamList();

      var details = teamListValues
        .then((response) => {
          return response;
        })
        .then((data) => {
          setTeamList(data.teamDetails);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    //fill partners dropdown data
    const partnerData = () => {
      var partnerListData = PartnerService.GetPartnerList();
      var details = partnerListData
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (
            currentUser.roleName === "partner" ||
            currentUser.roleName === "company"
          ) {
            setPartners(
              data.partnerDetails.filter((x) => x.Id === currentUser.partner_id)
            );
          } else {
            setPartners(data.partnerDetails);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const jobRolesData = () => {
      userService.GetJobRoles().then((response) => {
        if (response !== null) {
          setJobRoles(response);
        }
      });
    };
    //Fill company list
    const companyData = () => {
      var companyListData = CompanyService.GetCompanyList();

      var details = companyListData
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (currentUser.roleName === "company") {
            setCompanies(
              data.companyDetails.filter((x) => x.Id === currentUser.company_id)
            );
          } else {
            setCompanies(data.companyDetails);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    teamListData();
    companyData();
    partnerData();
    jobRolesData();
    userData();
  }, []);

  //add user model
  const [addUserModalShow, setAddUserModalshow] = useState(false);
  const [userCurrentRole, setUserCurrentRole] = useState(false);
  const [userCurrentRoleId, setUserCurrentRoleId] = useState(false);
  const [userUpdatedRole, setUserUpdatedRole] = useState(1);
  const [userId, setUserId] = useState(false);
  const [passwordSignup, setPasswordSignup] = useState("");
  const [confirmPasswordSignup, setConfirmPasswordSignup] = useState("");
  const [emailSignup, setEmailSignup] = useState("");
  const [phoneSignup, setPhoneSignup] = useState("");
  const [firstNameSignup, setFirstNameSignup] = useState("");
  const [lastNameSignup, setLastNameSignup] = useState("");
  const [currentCompanyAddUser, setCurrentCompanyAddUser] = useState(0);
  const [partnerId, setPartnerId] = useState(0);
  const [jobTitleSignup, setJobTitleSignup] = useState("");
  const [roleChangeMessage, setRoleChangeMessage] = useState(null);
  const [messageAddUser, setMessageAddUser] = useState(null);
  const [state, setState] = useState({
    data: [],
    sort: {
      column: null,
      direction: "desc",
    },
  });

  const addUserModalClose = () => setAddUserModalshow(false);
  const addUserModal = () => {
    if (companies.length > 0) {
      setCurrentCompanyAddUser(companies[0].Id);
    } else {
      setCurrentCompanyAddUser("");
    }
    setFirstNameSignup("");
    setLastNameSignup("");
    setPasswordSignup("");
    setConfirmPasswordSignup("");
    setEmailSignup("");
    setPhoneSignup("");
    setCurrentJobRole(0);
    setPartnerId(0);
    setCurrentCompanyAddUser(0);
    setTeamUserSignup(0);
    setUserUpdatedRole("0");
    setJobTitleSignup("");
    setAddUserModalshow(true);
  };
  const handlePartnerChange = (e) => {
    setDisabledCompany(false);

    var filteredCompany = companies.filter(
      (x) => x.Partner_Id == e.target.value
    );
    setfilteredCompany(filteredCompany);
    setCurrentCompanyAddUser(0);
    if (filteredCompany.length === 0) {
      setDisabledCompany(true);
      setDisabledTeam(true);
    }
    if (e.target.value === "0") {
      //setFilteredUser(userRoleList.filter(x => x.id !== 2 && x.id !== 3));
      if (currentUser.roleName === "company") {
        setFilteredUser(
          userRoleList.filter(
            (x) => x.id !== 1 && x.id !== 4 && x.id !== 5 && x.id !== 3
          )
        );
      }
      if (currentUser.roleName === "partner") {
        setFilteredUser(
          userRoleList.filter((x) => x.id === 2 || x.id === 1 || x.id === 4)
        );
      } else {
        setFilteredUser(userRoleList.filter((x) => x.id === 1));
      }
    } else {
      if (
        currentUser.rolename === "company" ||
        currentUser.rolename === "partner"
      ) {
        setFilteredUser(
          userRoleList.filter((x) => x.id !== 1 && x.id != 4 && x.id !== 5)
        );
      } else {
        setFilteredUser(
          userRoleList.filter((x) => x.id === 1 || x.id === 4 || x.id === 5)
        );
      }
    }
    setPartnerId(e.target.value);
  };
  const handleLinkClk = (id, fName, lName) => {
    console.log("TeamId===" + id);
    props.history.push({
      pathname: "/userProfile",
      state: { data: id, firstName: fName, lastName: lName },
    });
  };
  const [addUserRoleChangeModalShow, setAddUserRoleChangeModalShow] =
    useState(false);
  const addUserRoleChangeModalClose = () =>
    setAddUserRoleChangeModalShow(false);
  const addUserRoleChangeModal = () => setAddUserRoleChangeModalShow(true);

  //add user model
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const onChangeConfirmPasswordSignup = (e) => {
    const password = e.target.value;
    setConfirmPasswordSignup(password);
  };
  const addUserDetailsModalClose = () => {
    setAddUserModalshow(false);
    setMessageAddUser("");
    setCurrentCompanyAddUser(0);
    setTeamUserSignup(0);
    setPartnerId(0);
    setDisabledTeam(true);
    setDisabledCompany(true);
  };
  const onChangePhoneSignup = (e) => {
    const phone = e.target.value;
    setPhoneSignup(phone);
  };
  const validateConfirmPassword = (e) => {
    var password = e.target.value;
    var passwordSignupVal = passwordSignup;
    if (passwordSignupVal === password) {
      setMessageAddUser("");
      setConfirmPasswordSignup(password);
    } else if (passwordSignupVal === "") {
            toast.error('Please enter password', { position: toast.POSITION.TOP_CENTER })
    }
        else {
            toast.error('Password and Confirm password do not match', { position: toast.POSITION.TOP_CENTER })
        }


  };
  const validatePhone = (e) => {
    var phoneValidate = e.target.value;
    setMessageAddUser("");
  };
  const validatePassword = (e) => {
    const password = e.target.value;

    if (
      validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setMessageAddUser("");
      setPasswordSignup(password);
    } else {
            setMessageAddUser('Please enter a  strong password(minLength: 8, minLowercase: 1,minUppercase: 1, minNumbers: 1, minSymbols: 1)')
    }
  };
  const onChangePasswordSignup = (e) => {
    var password = e.target.value;
    setPasswordSignup(password);
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstNameSignup(firstName);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastNameSignup(lastName);
  };

  const onChangeEmailSignup = (e) => {
    const email = e.target.value;
    setEmailSignup(email);
  };
  const onChangeJobTitleSignup = (e) => {
    const jobTitle = e.target.value;
    setJobTitleSignup(jobTitle);
  };
  const ChangeRoleModal = (id, roleName, roleId) => {
    setUserId(id);
    setRoleChangeMessage("");
    setMessage("");
    setUserCurrentRole(roleName);
    setUserCurrentRoleId(roleId);
    setAddUserRoleChangeModalShow(true);
    //if (currentUser.roleName === "partner")
    //{
    //    setFilteredUser(userRoleList.filter(x => x.id === 2 || x.id === 1 || x.id === 4));
    //}
    if (roleId == 3) {
      setFilteredUser(userRoleList.filter((x) => x.id === 2));
    } else if (roleId == 2) {
      setFilteredUser(userRoleList.filter((x) => x.id === 3));
    } else if (roleId == 4) {
      setFilteredUser(
        userRoleList.filter((x) => x.id === 2 || x.id == 3 || x.id == 4)
      );
    } else if (roleId == 5) {
      setFilteredUser(
        userRoleList.filter((x) => x.id === 1 || x.id == 5 || x.id == 4)
      );
    } else setFilteredUser(userRoleList.filter((x) => x.id === 1));
  };
  const handleUserRoleChange = (e) => {
    setUserUpdatedRole(e.target.value);
  };
  const handleTeamSignUpChange = (e) => {
    if (e.target.value === "0") {
      setFilteredUser(userRoleList.filter((x) => x.id !== 2 && x.id !== 3));
      if (currentUser.roleName === "company") {
        setFilteredUser(
          userRoleList.filter(
            (x) => x.id !== 1 && x.id !== 4 && x.id !== 5 && x.id !== 3
          )
        );
      }
      if (currentUser.roleName === "partner") {
        setFilteredUser(
          userRoleList.filter((x) => x.id === 2 || x.id === 1 || x.id === 4)
        );
      }
      if (currentUser.roleName === "super-admin") {
        setFilteredUser(
          userRoleList.filter((x) => x.id == 2 || x.id === 4 || x.id === 3)
        );
      }
    } else {
      setFilteredUser(userRoleList);
      if (currentUser.roleName === "company") {
        setFilteredUser(
          userRoleList.filter((x) => x.id !== 1 && x.id !== 4 && x.id !== 5)
        );
      }
      if (currentUser.roleName === "partner") {
        setFilteredUser(
          userRoleList.filter(
            (x) => x.id === 2 || x.id === 1 || x.id === 4 || x.id === 3
          )
        );
      }
      if (currentUser.roleName === "super-admin") {
        setFilteredUser(userRoleList.filter((x) => x.id === 2 || x.id === 3));
      }
    }
    setTeamUserSignup(e.target.value);
  };
  const onChangeJobRole = (e) => {
    var jobRole = e.target.value;
    setCurrentJobRole(jobRole);
  };

  const onChangeCompanyUserAdd = (e) => {
    var filteredteam = teamList.filter((x) => x.CompanyId == e.target.value);
    setfilteredTeam(filteredteam);
    if (filteredteam.length === 0) {
      setDisabledTeam(true);
    } else {
      setDisabledTeam(false);
      //userRoleList = userRoleList;
    }
    if (e.target.value === "0") {
      setFilteredUser(userRoleList.filter((x) => x.id !== 2 && x.id !== 3));
      if (currentUser.roleName === "super-admin") {
        setFilteredUser(
          userRoleList.filter((x) => x.id === 1 || x.id === 4 || x.id === 5)
        );
      }
    } else {
      if (currentUser.roleName === "company") {
        setFilteredUser(
          userRoleList.filter(
            (x) => x.id !== 1 && x.id !== 4 && x.id !== 5 && x.id !== 3
          )
        );
      }
      if (currentUser.roleName === "partner") {
        setFilteredUser(
          userRoleList.filter((x) => x.id === 2 || x.id === 1 || x.id === 4)
        );
      }
      if (currentUser.roleName === "super-admin") {
        setFilteredUser(
          userRoleList.filter((x) => x.id == 2 || x.id === 4 || x.id === 3)
        );
      }
    }
    setCurrentCompanyAddUser(e.target.value);
  };
  const showDeleteModal = (type, id) => {
    //setting type in case to make this reusable , here setting company , can be set to partners etc
    setType(type);
    //setting the id of the company
    setId(id);
    setDeleteMessage(
      `Are you sure you want to delete the user  '${
        users.find((x) => x.id === id).FirstName
      }'?`
    );
    setDisplayConfirmationModal(true);
  };
  const submitDelete = (type, id) => {
    if (type === "user") {
      userService.DeleteUserById(id);
            toast.success(`The User '${users.find((x) => x.id === id).FirstName}' was deleted successfully.`, { position: toast.POSITION.TOP_CENTER })
            setMessage();
      setUsers(users.filter((user) => user.id !== id));
      setState({ data: users });
    }
    setDisplayConfirmationModal(false);
  };
  const btnClick_addUser = () => {
    if (
      passwordSignup !== "" &&
      firstNameSignup !== "" &&
      lastNameSignup !== "" &&
      emailSignup !== "" &&
      confirmPasswordSignup !== "" &&
      jobTitleSignup !== "" &&
      /*phoneSignup !== "" &&*/ currentJobRole !==
        "0" /*&& currentCompanyAddUser !== "0"*/ /*&& teamSignUp != "0"*/ &&
      userUpdatedRole !== "0" /* && partnerId !== "0"*/
    ) {
      userService
        .AddUserDetails(
          firstNameSignup,
          lastNameSignup,
          emailSignup,
          phoneSignup,
          passwordSignup,
          currentJobRole,
          partnerId,
          currentCompanyAddUser,
          userUpdatedRole,
          teamSignUp,
          jobTitleSignup
        )
        .then((response) => {
          if (response.data.Status === "Success") {
            const userData = userService.GetUserList();

            var details = userData
              .then((response) => {
                return response;
              })
              .then((data) => {
                if (currentUser.roleName === "partner") {
                  setUsers(
                    data.listOfUsers.filter(
                      (x) =>
                        x.partner_id === currentUser.partner_id &&
                        (x.roleName === "team-member" ||
                          x.roleName === "company" ||
                          x.roleName === "user")
                    )
                  );
                } else if (currentUser.roleName === "company") {
                  setIscompanyLogin(true);
                  setUsers(
                    data.listOfUsers.filter(
                      (x) =>
                        x.company_id == currentUser.company_id &&
                        x.roleName === "team-member"
                    )
                  );
                } else {
                  setUsers(data.listOfUsers);
                }
                setState({ data: users });
              })
              .catch((e) => {
                console.log(e);
              });
            setAddUserModalshow(false);
                        //setMessage("User Added Successfully");
                        toast.success('User Added Successfully', { position: toast.POSITION.TOP_CENTER })
                    }
                    else {
            setAddUserModalshow(true);
            toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER });
          }
        });
    } else {
      setAddUserModalshow(true);
            toast.error('Kindly fill all the fields', { position: toast.POSITION.TOP_CENTER })
    }
  };

  const onSort = (column) => {
    const direction =
      state.sort != undefined && state.sort.column
        ? state.sort.direction === "asc"
          ? "desc"
          : "asc"
        : "desc";
    const sortedData = state.data.sort((a, b) => {
      if (column === "FirstName") {
        if (direction === "desc") {
          setArrowPositionFirstName("arrow down");
        } else {
          setArrowPositionFirstName("arrow up");
        }
        const nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
      if (column === "LastName") {
        if (direction === "desc") {
          setArrowPositionLastName("arrow down");
        } else {
          setArrowPositionLastName("arrow up");
        }
        const nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
      if (column === "RegisterationDate") {
        if (direction === "desc") {
          setArrowPositionRegisterationDate("arrow down");
        } else {
          setArrowPositionRegisterationDate("arrow up");
        }
        const nameA = a.registration_date; // ignore upper and lowercase
        const nameB = b.registration_date; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
      if (column === "Company") {
        if (direction === "desc") {
          setArrowPositionCompany("arrow down");
        } else {
          setArrowPositionCompany("arrow up");
        }

        const nameA = a.CompanyName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.CompanyName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }

      if (column === "Partner") {
        if (direction === "desc") {
          setArrowPositionPartner("arrow down");
        } else {
          setArrowPositionPartner("arrow up");
        }

        const nameA = a.partnerName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.partnerName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }

      if (column === "Role") {
        if (direction === "desc") {
          setArrowPositionRole("arrow down");
        } else {
          setArrowPositionRole("arrow up");
        }

        const nameA = a.roleName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.roleName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }

      if (column === "JobRole") {
        if (direction === "desc") {
          setArrowPositionJobRole("arrow down");
        } else {
          setArrowPositionJobRole("arrow up");
        }

        const nameA = a.jobRoleName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.jobRoleName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }

      if (column === "Country") {
        if (direction === "desc") {
          setArrowPositionCountry("arrow down");
        } else {
          setArrowPositionCountry("arrow up");
        }

        const nameA = a.countryName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.countryName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }

      if (column === "State") {
        if (direction === "desc") {
          setArrowPositionState("arrow down");
        } else {
          setArrowPositionState("arrow up");
        }

        const nameA = a.state.toUpperCase(); // ignore upper and lowercase
        const nameB = b.state.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }

      if (column === "Position") {
        if (direction === "desc") {
          setArrowPositionForPosition("arrow down");
        } else {
          setArrowPositionForPosition("arrow up");
        }

        const nameA = a.positionName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.positionName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    });

    if (direction === "desc") {
      setUsers(sortedData.reverse());
    }
    setState({
      data: sortedData,
      sort: {
        column,
        direction,
      },
    });
  };
  const btnClick_addUserRoleChange = () => {
    if (userCurrentRoleId !== userUpdatedRole) {
      var message = userService
        .UpdateUserRole(userId, userUpdatedRole)
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (data.Message !== "success") {
            setAddUserRoleChangeModalShow(true);
                        //setRoleChangeMessage("There is some error saving the role, please try again");
                        toast.error('There is some error saving the role, please try again', { position: toast.POSITION.TOP_CENTER })
                    }
                    else {
            const userData = userService.GetUserList();

            var details = userData
              .then((response) => {
                return response;
              })
              .then((data) => {
                if (currentUser.roleName === "partner") {
                  setUsers(
                    data.listOfUsers.filter(
                      (x) =>
                        x.partner_id === currentUser.partner_id &&
                        (x.roleName === "team-member" ||
                          x.roleName === "company" ||
                          x.roleName === "user")
                    )
                  );
                } else if (currentUser.roleName === "company") {
                  setIscompanyLogin(true);
                  setUsers(
                    data.listOfUsers.filter(
                      (x) =>
                        x.company_id === currentUser.company_id &&
                        x.roleName === "team-member"
                    )
                  );
                } else {
                  setUsers(data.listOfUsers);
                }
                setState({ data: users });
              })
              .catch((e) => {
                console.log(e);
              });
            setRoleChangeMessage("");

                        toast.success('Role Updated Successfully', { position: toast.POSITION.TOP_CENTER })
            setAddUserRoleChangeModalShow(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setRoleChangeMessage("");

            toast.success('Role Updated Successfully', { position: toast.POSITION.TOP_CENTER })
      setAddUserRoleChangeModalShow(false);
    }
  };

  {
    /*...................................................End User list operatios code part..............................................................*/
  }

  {
    /*..............................................................filter view  section code................................................................*/
  }

  const [filterViewShow, setFilterViewShow] = useState(false);

  const [partnerIdFilter, setPartnerIdFilter] = useState(0);

  const [companyIdFilter, setCompanyIdFilter] = useState("0");
  const [proLevelUserProfile, setProLevelUserProfile] = useState("0");
  const [stateUserProfile, setStateUserProfile] = useState("0");
  const [positionUserProfile, setPositionUserProfile] = useState("0");
  const [geoInfluenceUserProfile, setGeoInfluenceUserProfile] = useState("0");
  const [experienceUserProfile, setExperienceUserProfile] = useState("0");
  const [industryUserProfile, setIndustryUserProfile] = useState("0");
  const [educationUserProfile, setEducationUserProfile] = useState("0");
  const [specialistUserProfile, setSpecialistUserProfile] = useState("0");
  const [sectorUserProfile, setSectorUserProfile] = useState("0");
  const [countryUserProfile, setCountryUserProfile] = useState("0");
  const [jobLevelUserProfile, setJobLevelUserProfile] = useState(null);
  const [jobRoleUserProfile, setJobRoleUserProfile] = useState("0");
  const [firstNameFilter, setFirstNameFilter] = useState(null);
  const [lastNameFilter, setLastNameFilter] = useState(null);
  const [roleFilter, setRoleFilter] = useState(0);
  const [postCodeFilterProfile, setPostCodeFilterProfile] = useState(null);
  const filterView = () => {
    setFilterViewShow((s) => !s);
  };

  const handleCompanyChange = (e) => {
    setCompanyIdFilter(e.target.value);
  };
  const handlePartnerChangeFilter = (e) => {
    setPartnerIdFilter(e.target.value);
  };

  const onChangeJobRoleFilter = (e) => {
    setJobRoleUserProfile(e.target.value);
  };
  const onChangeCompanyCountrySignup = (e) => {
    const companyCountry = e.target.value;
    setCountryUserProfile(companyCountry);
  };
  const onChangeCompanyStateSignup = (e) => {
    const companyState = e.target.value;
    setStateUserProfile(companyState);
  };
  const onChangeExperience = (e) => {
    const experience = e.target.value;
    setExperienceUserProfile(experience);
  };
  const onChangeIndustry = (e) => {
    const industry = e.target.value;
    setIndustryUserProfile(industry);
  };
  const onChangeEducation = (e) => {
    const education = e.target.value;
    setEducationUserProfile(education);
  };
  const onChangePositionUserProfile = (e) => {
    const position = e.target.value;
    setPositionUserProfile(position);
  };
  const onChangeSectorUserProfile = (e) => {
    const sector = e.target.value;
    setSectorUserProfile(sector);
  };
  const onChangeGeoInfluenceUserProfile = (e) => {
    const geoInfluence = e.target.value;
    setGeoInfluenceUserProfile(geoInfluence);
  };
  const onChangeProLevelUserProfile = (e) => {
    const proLevel = e.target.value;
    setProLevelUserProfile(proLevel);
  };
  const onChangeSpecialist = (e) => {
    const specialist = e.target.value;
    setSpecialistUserProfile(specialist);
  };
  const onChangePostCodeFilter = (e) => {
    const postCode = e.target.value;
    setPostCodeFilterProfile(postCode);
  };
  const onChangeJobLevel = (e) => {
    const jobLevel = e.target.value;
    setJobLevelUserProfile(jobLevel);
  };
  const onChangeLastNameFilter = (e) => {
    const lastName = e.target.value;
    setLastNameFilter(lastName);
  };
  const onChangeFirstNameFilter = (e) => {
    const firstName = e.target.value;
    setFirstNameFilter(firstName);
  };
  const onChangeRoleFilter = (e) => {
    setRoleFilter(e.target.value);
  };
  const onFilterButtonClick = () => {
    let data1 = totalUsers;
    if (
      firstNameFilter != null &&
      firstNameFilter !== undefined &&
      firstNameFilter !== ""
    ) {
      data1 = data1.filter(
        (x) =>
          x.FirstName.toUpperCase().indexOf(firstNameFilter.toUpperCase()) > -1
      );
    }
    if (
      lastNameFilter != null &&
      lastNameFilter !== undefined &&
      lastNameFilter !== ""
    ) {
      data1 = data1.filter(
        (x) =>
          x.LastName.toUpperCase().indexOf(lastNameFilter.toUpperCase()) > -1
      );
    }
    if (roleFilter != 0) {
      data1 = data1.filter((x) => x.roleName == roleFilter);
    }
    if (companyIdFilter != 0) {
      data1 = data1.filter((x) => x.CompanyName == companyIdFilter);
    }
    if (partnerIdFilter != 0) {
      data1 = data1.filter((x) => x.partnerName == partnerIdFilter);
    }
    if (industryUserProfile != 0) {
      data1 = data1.filter((x) => x.industryName == industryUserProfile);
    }
    if (educationUserProfile != 0) {
      data1 = data1.filter((x) => x.educationName == educationUserProfile);
    }
    if (experienceUserProfile != 0) {
      data1 = data1.filter((x) => x.experienceName == experienceUserProfile);
    }
    if (jobRoleUserProfile != 0) {
      data1 = data1.filter((x) => x.jobRoleName == jobRoleUserProfile);
    }
    if (countryUserProfile != 0) {
      data1 = data1.filter((x) => x.countryName == countryUserProfile);
    }
    if (stateUserProfile != 0) {
      data1 = data1.filter((x) => x.state == stateUserProfile);
    }
    if (
      postCodeFilterProfile != null &&
      postCodeFilterProfile != undefined &&
      postCodeFilterProfile != ""
    ) {
      data1 = data1.filter((x) => x.postcode == postCodeFilterProfile);
    }
    if (
      jobLevelUserProfile != null &&
      jobLevelUserProfile != undefined &&
      jobLevelUserProfile != ""
    ) {
      data1 = data1.filter(
        (x) => x.job_level.indexOf(jobLevelUserProfile) > -1
      );
    }
    if (specialistUserProfile != 0) {
      data1 = data1.filter((x) => x.specialistName == specialistUserProfile);
    }
    if (proLevelUserProfile != 0) {
      data1 = data1.filter((x) => x.proLevelName == proLevelUserProfile);
    }
    if (geoInfluenceUserProfile != 0) {
      data1 = data1.filter(
        (x) => x.geoInfluenceName == geoInfluenceUserProfile
      );
    }
    if (sectorUserProfile != 0) {
      data1 = data1.filter((x) => x.sectorName == sectorUserProfile);
    }
    if (positionUserProfile != 0) {
      data1 = data1.filter((x) => x.positionName == positionUserProfile);
    }
    setState({ data: data1 });
  };
  {
    /*..............................................................End filter view  section code................................................................*/
  }

  if (currentUser || currentCompany) {
    return (
      <>
        <Row className="mb-2 mb-xl-4">
          <Col xs="auto" className="ml-auto text-right mt-n1">
            <Button
              color="primary"
              className="shadow-sm mr-1"
              onClick={filterView}
            >
              <Filter className="feather" />
            </Button>

            <Button color="primary" className="shadow-sm">
              <Settings className="feather" />
            </Button>
          </Col>
        </Row>

        <Row>
          <Col md={filterViewShow ? "9" : "12"}>
            <Card>
              <CardHeader>
                <CardTitle className="mb-0 d-flex align-items-center">
                  <h2 className="d-inline-block mr-3 mb-0">Users</h2>
                  <button
                    className="p-2 text-white bg-primary rounded-circle"
                    onClick={addUserModal}
                  >
                    <Plus size={28} />
                  </button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {/*model for add user */}
                                <Modal show={addUserModalShow} onHide={addUserDetailsModalClose} backdrop="static">
                  <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="text"
                              name="first-name"
                              placeholder="Enter your first name"
                              value={firstNameSignup}
                              onChange={onChangeFirstName}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="text"
                              name="last-name"
                              placeholder="Enter your last name"
                              value={lastNameSignup}
                              onChange={onChangeLastName}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={6} md={6}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="email"
                              name="email"
                              placeholder="Enter your email"
                              value={emailSignup}
                              onChange={onChangeEmailSignup}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="tel"
                              name="phone"
                              placeholder="Enter your phone"
                              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                              value={phoneSignup}
                              onChange={onChangePhoneSignup}
                              onBlur={validatePhone}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="password"
                              name="password"
                              placeholder="Enter your password"
                              value={passwordSignup}
                              onChange={onChangePasswordSignup}

                            />
                                                        <PasswordStrengthBar password={passwordSignup} />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="password"
                              name="password"
                              placeholder="Confirm your password"
                              value={confirmPasswordSignup}
                              onChange={onChangeConfirmPasswordSignup}

                            />
                                                        <PasswordStrengthBar password={confirmPasswordSignup} />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="text"
                              name="JobTitle"
                              placeholder="Job Title"
                              value={jobTitleSignup}
                              onChange={onChangeJobTitleSignup}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}></Col>

                        <Col xs={6} md={6}>
                          <FormGroup>
                            Please select the job role :
                            <select
                              className="custom-select"
                              value={currentJobRole}
                              onChange={onChangeJobRole}
                            >
                              <option value="0">Select JobRole</option>
                              {jobRoles &&
                                jobRoles.length > 0 &&
                                jobRoles.map((schema) => {
                                  return (
                                    <option key={schema.id} value={schema.id}>
                                      {schema.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col xs={6} md={6}>
                          <FormGroup>
                            Choose Partner :
                            <select
                              className="custom-select"
                              value={partnerId}
                              onChange={handlePartnerChange}
                            >
                              <option value="0">Select Partner</option>
                              {partners &&
                                partners.length > 0 &&
                                partners.map((schema) => {
                                  return (
                                    <option key={schema.Id} value={schema.Id}>
                                      {schema.PartnerName}
                                    </option>
                                  );
                                })}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            Please select the company :
                            <select
                              className="custom-select"
                              disabled={disabledCompany}
                              value={currentCompanyAddUser}
                              onChange={onChangeCompanyUserAdd}
                            >
                              <option value="0">Select Company</option>
                              {filteredCompany &&
                                filteredCompany.length > 0 &&
                                filteredCompany.map((schema) => {
                                  return (
                                    <option key={schema.Id} value={schema.Id}>
                                      {schema.CompanyName}
                                    </option>
                                  );
                                })}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            Please select the team:
                            <select
                              className="custom-select"
                              value={teamSignUp}
                              onChange={handleTeamSignUpChange}
                              disabled={disabledTeam}
                            >
                              <option value="0">Select Team</option>
                              {filteredTeam &&
                                filteredTeam.length > 0 &&
                                filteredTeam.map((schema) => {
                                  return (
                                    <option key={schema.Id} value={schema.Id}>
                                      {schema.TeamName}
                                    </option>
                                  );
                                })}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            Please select the role:
                            {/*<select className="custom-select" value={userUpdatedRole} onChange={handleUserRoleChange} >*/}
                            {/*    <option value="0">Select Role</option>*/}
                            {/*    <option value="1">user</option>*/}
                            {/*    <option value="2">team-member</option>*/}
                            {/*    <option value="3">team-leader</option>*/}
                            {/*    */}
                            {/*<option value="4">company</option>*/}
                            {/*    */}
                            {/*<option value="5">partner</option>*/}
                            {/*    */}
                            {/*<option value="6">super-admin</option>*/}
                            {/*</select>*/}
                            <select
                              className="custom-select"
                              value={userUpdatedRole}
                              onChange={handleUserRoleChange}
                            >
                              <option value="0">Select role</option>
                              {filteredUser &&
                                filteredUser.length > 0 &&
                                filteredUser.map((schema) => {
                                  return (
                                    <option key={schema.id} value={schema.id}>
                                      {schema.value}
                                    </option>
                                  );
                                })}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    {messageAddUser && (
                      <Alert variant="danger">{messageAddUser}</Alert>
                    )}
                    <Button variant="primary" onClick={btnClick_addUser}>
                      Add User
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={addUserDetailsModalClose}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                                <Modal show={addUserRoleChangeModalShow} onHide={addUserRoleChangeModalClose} backdrop="static">
                  <Modal.Header closeButton>
                    <Modal.Title>Change Role</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup>
                            User's current role is :
                            <Input
                              bsSize="lg"
                              type="text"
                              name="role-name"
                              value={userCurrentRole}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          Change User Role to:
                        </Col>
                        <Col xs={12} md={12}>
                          <FormGroup>
                            <select
                              className="custom-select"
                              value={userUpdatedRole}
                              onChange={handleUserRoleChange}
                            >
                              {/*<option value="0">Select r</option>*/}
                              {filteredUser &&
                                filteredUser.length > 0 &&
                                filteredUser.map((schema) => {
                                  return (
                                    <option key={schema.id} value={schema.id}>
                                      {schema.value}
                                    </option>
                                  );
                                })}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    {roleChangeMessage && (
                      <Alert variant="danger">{roleChangeMessage}</Alert>
                    )}
                    <Button
                      variant="primary"
                      onClick={btnClick_addUserRoleChange}
                    >
                      Save Changes
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={addUserRoleChangeModalClose}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/*model for add user */}

                {/*Table for user list*/}
                {userMessage && (
                  <Alert variant="success" style={{ width: "400px" }}>
                    {userMessage}
                  </Alert>
                )}

                <div className="scrollable">
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>

                        <th>Status</th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("FirstName")}
                          >
                            First Name{" "}
                            <i className={arrowPositionFirstName}></i>
                          </button>
                        </th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("LastName")}
                          >
                            Last Name <i className={arrowPositionLastName}></i>
                          </button>
                        </th>

                        <th>Industry</th>
                        {/*<th>Partner Name</th>*/}
                        <th>Phone Number</th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("RegisterationDate")}
                          >
                            Registeration Date{" "}
                            <i className={arrowPositionRegisterationDate}></i>
                          </button>
                        </th>
                        <th>Email</th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("Company")}
                          >
                            Company <i className={arrowPositionCompany}></i>
                          </button>
                        </th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("Partner")}
                          >
                            Partner <i className={arrowPositionPartner}></i>
                          </button>
                        </th>
                        <th>Education </th>
                        <th>Experience </th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("Role")}
                          >
                            Role <i className={arrowPositionRole}></i>
                          </button>
                        </th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("JobRole")}
                          >
                            JobRole <i className={arrowPositionJobRole}></i>
                          </button>
                        </th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("Country")}
                          >
                            Country <i className={arrowPositionCountry}></i>
                          </button>
                        </th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("State")}
                          >
                            State <i className={arrowPositionState}></i>
                          </button>
                        </th>
                        <th>Post Code </th>
                        <th>Specialist </th>
                        <th>Pro Level </th>
                        <th>Geo Influence </th>
                        <th>Sector </th>
                        <th>
                          <button
                            type="button"
                            className="SortButton"
                            onClick={() => onSort("Position")}
                          >
                            Position{" "}
                            <i className={arrowPositionForPosition}></i>
                          </button>
                        </th>
                        <th
                          style={{
                            display: iscompanyLogin ? "none" : "revert",
                          }}
                        >
                          Change Role
                        </th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.data
                        .slice(indexOfFirstPost, indexOfLastPost)
                        .map((userItem) => {
                          return (
                            <tr key={userItem.id}>
                              <td>
                                <label className="checkbox-container">
                                  <input type="checkbox" checked="" />
                                  <span className="checkmark"></span>
                                </label>
                              </td>
                              <td>
                                <img
                                  src={imgDefaultAvatar}
                                  style={{ width: "50px" }}
                                  alt=""
                                />
                              </td>

                              <td>
                                {userItem.roleName === "team-leader" ? (
                                  <FontAwesomeIcon
                                    className="align-middle mr-2"
                                    icon={faStar}
                                    fixedWidth
                                  />
                                ) : (
                                  <div></div>
                                )}
                              </td>

                              <td>{userItem.FirstName}</td>
                              <td> {userItem.LastName}</td>
                              <td>
                                {userItem.industryName == ""
                                  ? "NA"
                                  : userItem.industryName}
                              </td>
                              {/*<td>{userItem.partner_name == "" ? "NA" : userItem.partner_name}</td>*/}
                              <td>
                                {userItem.phone_number == ""
                                  ? "NA"
                                  : userItem.phone_number}
                              </td>
                              <td>
                                {userItem.registration_date == ""
                                  ? "NA"
                                  : userItem.registration_date}
                              </td>
                              <td>
                                <div
                                  className="my-pointer"
                                  onClick={() =>
                                    handleLinkClk(
                                      userItem.id,
                                      userItem.FirstName,
                                      userItem.LastName
                                    )
                                  }
                                >
                                  {" "}
                                  {userItem.Email}{" "}
                                </div>
                              </td>
                              <td>
                                {userItem.CompanyName == ""
                                  ? "NA"
                                  : userItem.CompanyName}
                              </td>
                              <td>
                                {userItem.partnerName == ""
                                  ? "NA"
                                  : userItem.partnerName}
                              </td>
                              <td>
                                {userItem.educationName == ""
                                  ? "NA"
                                  : userItem.educationName}
                              </td>
                              <td>
                                {userItem.experienceName == ""
                                  ? "NA"
                                  : userItem.experienceName}
                              </td>
                              <td>
                                {userItem.roleName == ""
                                  ? "NA"
                                  : userItem.roleName}
                              </td>
                              <td>
                                {userItem.jobRoleName == ""
                                  ? "NA"
                                  : userItem.jobRoleName}
                              </td>
                              <td>
                                {userItem.countryName == ""
                                  ? "NA"
                                  : userItem.countryName}
                              </td>
                              <td>
                                {userItem.state == "" ? "NA" : userItem.state}
                              </td>
                              <td>
                                {userItem.postcode == "0"
                                  ? "NA"
                                  : userItem.postcode}
                              </td>
                              <td>
                                {userItem.specialistName == ""
                                  ? "NA"
                                  : userItem.specialistName}
                              </td>
                              <td>
                                {userItem.proLevelName == ""
                                  ? "NA"
                                  : userItem.proLevelName}
                              </td>
                              <td>
                                {userItem.geoInfluenceName == ""
                                  ? "NA"
                                  : userItem.geoInfluenceName}
                              </td>
                              <td>
                                {userItem.sectorName == ""
                                  ? "NA"
                                  : userItem.sectorName}
                              </td>
                              <td>
                                {userItem.positionName == ""
                                  ? "NA"
                                  : userItem.positionName}
                              </td>
                              <td
                                style={{
                                  display: iscompanyLogin ? "none" : "revert",
                                }}
                              >
                                <button
                                  className="btn mx-auto d-block"
                                  onClick={() =>
                                    ChangeRoleModal(
                                      userItem.id,
                                      userItem.roleName,
                                      userItem.RoleId
                                    )
                                  }
                                >
                                  <img
                                    src={iconChangeRole}
                                    alt=""
                                    className=""
                                  />
                                </button>
                              </td>
                              <td>
                                <button
                                  className="btn mx-auto d-block"
                                  onClick={() =>
                                    showDeleteModal("user", userItem.id)
                                  }
                                >
                                  <img src={iconDeleteUser} alt="" />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={users.length}
                    paginate={paginate}
                    active={currentPage}
                  />
                  {/*Table for user list*/}
                </div>
              </CardBody>
              <DeleteConfirmation
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={hideConfirmationModal}
                type={type}
                id={id}
                message={deleteMessage}
              />
            </Card>
          </Col>

          {/*........................................ filter view................................................................................ */}
          {filterViewShow ? (
            <Col md={3}>
              <Card className="app-filters">
                <CardHeader className="px-4 border-bottom">
                  <div style={{ float: "right" }}>
                    <button
                      className="btn text-dark rounded-pill"
                      onClick={() => setFilterViewShow(false)}
                    >
                      <X size={35}></X>
                    </button>
                  </div>
                  <p className="mb-0 h1  text-left text-primary font-weight-bold">
                    <Filter className="" size={40}></Filter> Filter
                  </p>
                </CardHeader>
                <CardBody>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="first-name"
                        placeholder="Enter your first name"
                        value={firstNameFilter}
                        onChange={onChangeFirstNameFilter}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="first-name"
                        placeholder="Enter your last name"
                        value={lastNameFilter}
                        onChange={onChangeLastNameFilter}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Role</Label>
                      <select
                        name="role"
                        className="form-control"
                        value={roleFilter}
                        onChange={onChangeRoleFilter}
                      >
                        <option value="0">Select Role</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex((t) => t.roleName === v.roleName) ===
                              i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.roleName}
                                value={schema.roleName}
                              >
                                {schema.roleName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Client</Label>
                      <select
                        className="custom-select"
                        value={companyIdFilter}
                        onChange={handleCompanyChange}
                      >
                        <option key="0">Select company</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.CompanyName === v.CompanyName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.CompanyName}
                                value={schema.CompanyName}
                              >
                                {schema.CompanyName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Partner</Label>
                      <select
                        className="custom-select"
                        value={partnerIdFilter}
                        onChange={handlePartnerChangeFilter}
                      >
                        <option key="0">Select partner</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.partnerName === v.partnerName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.partnerName}
                                value={schema.partnerName}
                              >
                                {schema.partnerName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Industry</Label>
                      <select
                        name="expirience"
                        className="form-control"
                        value={industryUserProfile}
                        onChange={onChangeIndustry}
                      >
                        <option value="0">Select Industry</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.industryName === v.industryName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.industryName}
                                value={schema.industryName}
                              >
                                {schema.industryName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Education</Label>
                      <select
                        name="expirience"
                        className="form-control"
                        value={educationUserProfile}
                        onChange={onChangeEducation}
                      >
                        <option value="0">Select Education</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.educationName === v.educationName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.educationName}
                                value={schema.educationName}
                              >
                                {schema.educationName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Experience</Label>
                      <select
                        name="expirience"
                        className="form-control"
                        value={experienceUserProfile}
                        onChange={onChangeExperience}
                      >
                        <option value="0">Select Experience</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.experienceName === v.experienceName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.experienceName}
                                value={schema.experienceName}
                              >
                                {schema.experienceName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Job Role</Label>
                      <select
                        name="expirience"
                        className="form-control"
                        value={jobRoleUserProfile}
                        onChange={onChangeJobRoleFilter}
                      >
                        <option value="0">Select JobRole</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.jobRoleName === v.jobRoleName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.jobRoleName}
                                value={schema.jobRoleName}
                              >
                                {schema.jobRoleName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Country</Label>
                      <select
                        name="country"
                        className="form-control"
                        value={countryUserProfile}
                        onChange={onChangeCompanyCountrySignup}
                      >
                        <option value="0">Select Country</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.countryName === v.countryName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.countryName}
                                value={schema.countryName}
                              >
                                {schema.countryName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>State</Label>
                      <select
                        name="state"
                        className="form-control"
                        value={countryUserProfile}
                        onChange={onChangeCompanyStateSignup}
                      >
                        <option value="0">Select State</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex((t) => t.state === v.state) === i
                          )
                          .map((schema) => {
                            return (
                              <option key={schema.state} value={schema.state}>
                                {schema.state}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Post Code</Label>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="post-code"
                        placeholder="Enter post code"
                        value={postCodeFilterProfile}
                        onChange={onChangePostCodeFilter}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Job Level</Label>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="job-level"
                        placeholder="Enter job level"
                        value={jobLevelUserProfile}
                        onChange={onChangeJobLevel}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Specialist</Label>
                      <select
                        name="expirience"
                        className="form-control"
                        value={specialistUserProfile}
                        onChange={onChangeSpecialist}
                      >
                        <option value="0">Select Specialist</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.specialistName === v.specialistName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.specialistName}
                                value={schema.specialistName}
                              >
                                {schema.specialistName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Pro level</Label>
                      <select
                        name="expirience"
                        className="form-control"
                        value={proLevelUserProfile}
                        onChange={onChangeProLevelUserProfile}
                      >
                        <option value="0">Select Pro-Level</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.proLevelName === v.proLevelName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.proLevelName}
                                value={schema.proLevelName}
                              >
                                {schema.proLevelName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Geo Influence</Label>
                      <select
                        name="expirience"
                        className="form-control"
                        value={geoInfluenceUserProfile}
                        onChange={onChangeGeoInfluenceUserProfile}
                      >
                        <option value="0">Select Geo-Influence</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.geoInfluenceName === v.geoInfluenceName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.geoInfluenceName}
                                value={schema.geoInfluenceName}
                              >
                                {schema.geoInfluenceName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Sector</Label>
                      <select
                        name="expirience"
                        className="form-control"
                        value={sectorUserProfile}
                        onChange={onChangeSectorUserProfile}
                      >
                        <option value="0">Select Sector</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.sectorName === v.sectorName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.sectorName}
                                value={schema.sectorName}
                              >
                                {schema.sectorName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Position</Label>
                      <select
                        name="expirience"
                        className="form-control"
                        value={positionUserProfile}
                        onChange={onChangePositionUserProfile}
                      >
                        <option value="0">Select Position</option>
                        {totalUsers
                          .filter(
                            (v, i, a) =>
                              a.findIndex(
                                (t) => t.positionName === v.positionName
                              ) === i
                          )
                          .map((schema) => {
                            return (
                              <option
                                key={schema.positionName}
                                value={schema.positionName}
                              >
                                {schema.positionName}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={12}>
                    <div className="text-center">
                      <button
                        className="btn btn-primary d-block col-12"
                        onClick={onFilterButtonClick}
                      >
                        <Search></Search> Search
                      </button>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <div></div>
          )}
        </Row>
      </>
    );
  } else {
    return <Card></Card>;
  }
};

const Tables = (props) => (
  <div id="team_leader_view_div">
    <SgaUsers {...props} />
  </div>
);

export default Tables;
