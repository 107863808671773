import React from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";

import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const BarChart = ({ theme, chartData }) => {
     
    const data = {
        labels: chartData.map(x => x.TopicName),
        datasets: [
            {

                label: "Score(in %)",
                backgroundColor: theme.primary,
                borderColor: theme.primary,
                hoverBackgroundColor: theme.primary,
                hoverBorderColor: theme.primary,
                data: chartData.map(y => (y.Percentage) )

            }

        ]
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">Your Score Chart</CardTitle>

            </CardHeader>
            <CardBody>
                <div className="chart">
                    <Bar data={data} />
                </div>
            </CardBody>
        </Card>
    );
};

export default connect(store => ({
    theme: store.theme.currentTheme
}))(BarChart);
