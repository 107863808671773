import AuthService from "../../AuthServices/auth.service.js";
import React, { useState, useRef } from "react";

import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    UncontrolledAlert,
    Input,
    CardHeader,
} from "reactstrap";

const ResetPassword = (props) => {
    const colors = ["danger"];
    const [emailForReset, setEmailForReset] = useState("");
    const [messagePassReset, setMessagePassReset] = useState("");
    const resetPassIfEmailValid = () => {
        if (emailForReset !== "") {

            AuthService.resetPasswordIfEmailValid(emailForReset).then((response) => {
                if (response.Message !=="") {
                    setMessagePassReset(response.Message);
                    localStorage.setItem("InChangePwdPage", "true");
                }
            });
        }
    }

    const onChangeEmailForReset = (e) => {
        const email = e.target.value;
        setEmailForReset(email);
    };

    return (
        <React.Fragment>
            <Card className="mx-auto col-6 pt-3">
                <CardHeader className="text-center pb-0">
                    <h1 className="h2">Reset password</h1>
                    <p className="lead">Enter your email to reset your password.</p>
                </CardHeader>
                <CardBody>
                    <div className="m-sm-4">
                        <Form>
                            <FormGroup>
                                <Input
                                    bsSize="lg"
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={emailForReset}
                                    onChange={onChangeEmailForReset}
                                />
                            </FormGroup>
                            <div className="text-center mt-3">

                                <Button color="primary" size="lg" onClick={resetPassIfEmailValid}>
                                    Reset password
                </Button>

                            </div>
                            <div className={"mt-3"}>
                                {messagePassReset && (
                                    <UncontrolledAlert color={colors}>
                                        <div className="alert-message">
                                            <strong>{messagePassReset}</strong>
                                        </div>
                                    </UncontrolledAlert>
                                )}
                            </div>
                        </Form>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default ResetPassword;
