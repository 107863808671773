
import React, { useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import { Filter, Settings } from "react-feather";
import Watch from "../../../assets/img/icons/watch.png";
//import "../../TabView.css";
import AuthService from "../../../AuthServices/auth.service.js";

const QuizView = (props) => {

    localStorage.removeItem("ReSubmitQuestionNTKey");
    localStorage.removeItem("AssessmentQuizNTKey");


    var currentUser = AuthService.getCurrentUser();
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: '/Assessments'
        })
        window.location.reload();
    }

    if (props.history.action == "POP") {
        var isKeyPresent = (JSON.parse(localStorage.getItem("ReviewAssessmentNTKey")));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: '/Assessments'
            })
            window.location.reload();
        }
    }

    //set questionObject from props
    var questions = props.location.state.questionsList;
    
    var creditId = props.location.state.creditId;
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const TitleList = questions;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const ReviewQuestions = (count, question, optionList, assessmentId, selectedAnswer, assessmentName) => {

        localStorage.setItem("ReSubmitQuestionNTKey", "false");
        localStorage.removeItem("AssessmentQuizNTKey");
        localStorage.removeItem("ReviewAssessmentNTKey");

        //once the person click on change answer then it is redirected to other page for changing the answer.
        props.history.push({
            pathname: '/ReSubmitQuestionOptionNonTimer',
            state: { currentCounter: count, optionList: optionList, question: question, assessmentId: assessmentId, selectedAnswer, AssessmentName: assessmentName, creditId: creditId }

        })
    }
    const handleFinishButton = () => {
        setShow(true);
    }
    const handleFinalSubmit = () => {
        setShow(false);
        localStorage.removeItem('questionCounter');
        localStorage.removeItem('state');
        localStorage.removeItem('Title');
        localStorage.removeItem('AssessmentName');
        localStorage.removeItem('currentQuestionIndex');
        localStorage.removeItem('currentTitleIndex');
        localStorage.removeItem('checkedRadio');
        props.history.push({
            pathname: '/Assessments'
        })
    }
    var count = 0;

    return (
        <Row>
            <Col md="12">
                <div className="container">
                    <div className="bloc-Mytabs">
                        <button
                            className={toggleState === 1 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(1)} >
                            Home
                                 </button>
                        <button
                            className={toggleState === 2 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(2)}>
                            Assessments
                                </button>
                        <button
                            className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(3)}>
                            User Analytics
                                </button>
                    </div>

                    <div className="content-Mytabs">
                        <div
                            className={toggleState === 1 ? "content-t  active-content-t" : "content-t"} >

                            {/*......................................Assessment Quiz View .......................................*/}
                            <Row>
                                <Modal size="lg" show={show} onHide={handleClose} backdrop="static" className="modal-colored modal-warning">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Are you sure you want to submit the assessement?</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="text-center m-3 modal-body">
                                            <p className="mb-0 text-white"> Once you submit the assessment, you cannot modify the answers. Do you want to continue?</p>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
          </Button>
                                        <Button variant="primary" onClick={handleFinalSubmit}>
                                            Finish
          </Button>
                                    </Modal.Footer>
                                </Modal>
                                <div className="col-lg-8 col-md-8">

                                    <Card className="my-card">
                                        <CardBody>
                                            <Row>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="float-left">
                                                        <h4 className="my-h4-bold">Review Assessment</h4>
                                                        <div className="scrollable">
                                                            <div className="text-left">
                                                                {TitleList.TitleList.map((x) => (
                                                                    <div>  <h5 className="right-sidebar-h5">{x.TitleName}</h5><hr className="my-hr" />
                                                                        {x.QuestionList.map((p) => (<Card className="" ><div className="quiz-question-h5">Question {count += 1}: {p.Questions}</div>
                                                                            <hr className="my-hr" />
                                                                            <div className="quiz-question-h5">Selected Answer:</div>{p.OptionsWithScore.filter(u => u.OptionsId == p.SelectedAnswer)[0].Options}<br/><br/> <button id="review-button" className="quiz-submit-btn btn-pill reviewButton" onClick={() => ReviewQuestions(p.QuestionId, p.Questions, p.OptionsWithScore, TitleList.AssessmentId, p.SelectedAnswer, TitleList.AssessmentName)}>Change Answer</button>
                                                                            <br/>
                                                                            <hr className="my-hr" />
                                                                        </Card>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <Card className="my-card">
                                        <br/>
                                        <button id="submit-button" className="quiz-submit-btn btn-pill" style={{ marginLeft: "20%", marginRight: "20%" }} onClick={handleFinishButton}>Submit Assessment</button>
                                        <CardBody>
                                            <Row>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Row>

                        </div>

                        <div
                            className={toggleState === 2 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Team Assessments</h2>
                            <hr />

                        </div>

                        <div
                            className={toggleState === 3 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Organization Assessments</h2>
                            <hr />

                        </div>
                        <div
                            className={toggleState === 4 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Partner Assessments</h2>
                            <hr />

                        </div>
                    </div>
                </div>
            </Col>


        </Row>

    );
}

const ReviewAssessmentNonTimer = (props) => (
    <>
        <Row className="mb-2 mb-xl-4">


            <Col xs="auto" className="ml-auto text-right mt-n1">


                <Button color="primary" className="shadow-sm mr-1">
                    <Filter className="feather" />
                </Button>
                <Button color="primary" className="shadow-sm">
                    <Settings className="feather" />
                </Button>

            </Col>
        </Row>
        <QuizView {...props} />
    </>
);



export default ReviewAssessmentNonTimer;
