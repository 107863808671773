import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Table,
  Row,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { Filter, Settings, X, Search } from "react-feather";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSort,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import assessmentService from "../../../AuthServices/assessment.service.js";
import AuthService from "../../../AuthServices/auth.service.js";
import userService from "../../../AuthServices/user.service.js";

// import "../TabView.css";
import { left } from "@popperjs/core";

{
  /*...................................................Assessment list code part..............................................................*/
}
const AssessmentsTypes = (props) => {
  var currentUser = AuthService.getCurrentUser();
  if (currentUser == null || currentUser == undefined) {
    props.history.push("/");
    window.location.reload();
  }
  const [state, setState] = useState({
    data: [],
    sort: {
      column: null,
      direction: "desc",
    },
  });
  //for tab view
  const [toggleState, setToggleState] = useState(1);
  const [allAssessments, setAllAssessments] = useState([]);
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(1);
  const [arrowPositionAssessment, setArrowPositionAssessment] =
    useState(faSort);
  const [arrowPositionScore, setArrowPositionScore] = useState(faSort);
  const [arrowPositionTime, setArrowPositionTime] = useState(faSort);
  const [arrowPositionStatus, setArrowPositionStatus] = useState(faSort);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  let count = 0;
  //for popover
  const directions = [
    {
      name: "Bottom",
      value: "bottom",
    },
  ];
  if (props.history.action == "POP") {
    var isKeyPresent = JSON.parse(localStorage.getItem("ReviewAssessment"));
    if (isKeyPresent == undefined || isKeyPresent == null) {
      props.history.push({
        pathname: "/Assessments",
      });
    }
  }

    useEffect(() => {
        localStorage.removeItem("hourAssessment");
        localStorage.removeItem('questionCounter');
        localStorage.removeItem('state');
        localStorage.removeItem('Title');
        localStorage.removeItem('AssessmentName');
        localStorage.removeItem('currentQuestionIndex');
        localStorage.removeItem('currentTitleIndex');
        localStorage.removeItem('checkedRadio');
        localStorage.removeItem('ReviewTimer');
        localStorage.removeItem('AssessmentQuiz');
        localStorage.removeItem('DateTimeServer');
        localStorage.removeItem('ReviewAssessmentKey');


        var currentUser = AuthService.getCurrentUser();
        var userId = currentUser.id;

    const assessmentList = () => {
      var assessmentList = assessmentService.GetAllAssessmentList(userId);

      var details = assessmentList
        .then((response) => {
          return response;
        })
        .then((data) => {
          setAllAssessments(data);
          setState({ data: data });
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const userData = () => {
      var userDataList = userService.GetUserList();

      var details = userDataList
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (currentUser.roleName === "partner") {
            setUsers(
              data.listOfUsers.filter(
                (x) =>
                  x.partner_id === currentUser.partner_id &&
                  x.roleName === "company"
              )
            );
          } else if (currentUser.roleName === "company") {
            setUsers(
              data.listOfUsers.filter(
                (x) =>
                  x.company_id === currentUser.company_id &&
                  x.roleName === "team-member"
              )
            );
          } else {
            setUsers([]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const assessmentDetail = () => {
      var assessmentDetailList = assessmentService.GetAllAssessmentDetails();

      var details = assessmentDetailList
        .then((response) => {
          return response;
        })
        .then((data) => {
          setAssessmentDetails(data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    assessmentList();
    assessmentDetail();
    userData();
  }, []);

  const onSort = (column) => {
    const direction =
      state.sort != undefined && state.sort.column
        ? state.sort.direction === "asc"
          ? "desc"
          : "asc"
        : "desc";
    const sortedData = state.data.sort((a, b) => {
      if (column === "Assessment") {
        if (direction === "desc") {
          setArrowPositionAssessment(faSortAlphaUp);
        } else {
          setArrowPositionAssessment(faSortAlphaDown);
        }
        const nameA = a.AssessmentName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.AssessmentName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
      if (column === "Status") {
        if (direction === "desc") {
          setArrowPositionStatus(faSortAlphaUp);
        } else {
          setArrowPositionStatus(faSortAlphaDown);
        }
        const nameA = a.Status.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Status.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
      if (column === "Score") {
        if (direction === "desc") {
          setArrowPositionScore(faSortNumericUp);
        } else {
          setArrowPositionScore(faSortNumericDown);
        }
        const nameA = a.Score; // ignore upper and lowercase
        const nameB = b.Score; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
      if (column === "TimeSpent") {
        if (direction === "desc") {
          setArrowPositionTime(faSortNumericUp);
        } else {
          setArrowPositionTime(faSortNumericDown);
        }

        const nameA = a.TotalTimeInSec; // ignore upper and lowercase
        const nameB = b.TotalTimeInSec; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    });

    if (direction === "desc") {
      setAllAssessments(sortedData.reverse());
    }
    setState({
      data: sortedData,
      sort: {
        column,
        direction,
      },
    });
  };

  const handleUserChange = (e, guid) => {
    setUserId(e.target.value);
    //setCourseId(id);

    var assessmentNum = [];

    var result = [...allAssessments]; //<- copy course into result
    result = result.map((x) => {
      //<- use map on result to find element to update using id
      if (x.AssessGuid === guid) x.AssignedUserId = e.target.value;
      return x;
    });
    assessmentNum = result;

        setAllAssessments(assessmentNum); //<- update ass  with value edited
        setState({ data: assessmentNum });
    };
    const GetButtonStatus = (Status) => {
        return ((Status.toUpperCase() == "INCOMPLETE") ?
            true :
            false
        )

    }
    const handleDownloadReport = (assessmentId, userId,guid) => {
        debugger;
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = "Assessment Result Report"
        //axios
        //    .get(url, {
        //        responseType: "blob",
        //    })
        assessmentService.GetAssessmentResultReport(assessmentId,userId,guid)
            .then((res) => {
                debugger;
                link.href = URL.createObjectURL(
                    new Blob([res], { type: 'application/pdf' })
                );
                link.click();
            });
    };

    const GetClassName = (Status) => {

        return (Status.toUpperCase() === "COMPLETED" ? "btn btn-pill me-1 mb-1 btn-normal btn-success col-12" : Status.toUpperCase() == "IN PROGRESS" ? "btn btn-pill me-1 mb-1 btn-normal btn-info col-12" : Status.toUpperCase() == "OPEN" ? "btn btn-pill me-1 mb-1 btn-normal btn-secondary col-12" : Status.toUpperCase() == "INCOMPLETE" ? "btn btn-pill me-1 mb-1 btn-normal btn-secondary col-12" : "btn btn-pill me-1 mb-1 btn-normal btn-info col-12")
    }
    const GetIconCircleColor = (Status) => {
        return (Status.toUpperCase() === "COMPLETED" ? "text-success" : Status.toUpperCase() == "IN PROGRESS" ? "text-info" : Status.toUpperCase() == "OPEN" ? "text-secondary" : Status.toUpperCase() === "INCOMPLETE" ? "text-secondary" : "text-success")
    }


  const handleAssignment = (id, userId, creditId) => {
    if (userId != 0) {
      var currentUser = AuthService.getCurrentUser();
      var details = assessmentService
        .AssignTheAssessmentToUser(currentUser.id, userId, id, creditId)
        .then((response) => {
          return response;
        })
        .then((data) => {
          window.location.reload();
        });
    }
  };
  const handleStartAssesmentClk = (
    id,
    Is_Time_Based,
    QuizStatus,
    Score,
    Schema,
    creditId
  ) => {
    localStorage.removeItem("questionCounter");
    localStorage.removeItem("state");
    localStorage.removeItem("Title");
    localStorage.removeItem("AssessmentName");
    localStorage.removeItem("currentQuestionIndex");
    localStorage.removeItem("currentTitleIndex");
    localStorage.removeItem("checkedRadio");
    localStorage.removeItem("hourAssessment");
    if (QuizStatus.toUpperCase() == "COMPLETED") {
      props.history.push({
        pathname: "/AssessmentResult",
        state: { Score: Score, Data: Schema.TopicWisePercentage },
      });
    } else {
      var currentUser = AuthService.getCurrentUser();

            var details = assessmentService.AddEntryInUserTestTable(currentUser.id, id, creditId).then((response) => {
                return response;
            })
                .then((data) => {
                    debugger;
                    var questions = assessmentDetails.filter(x => x.AssessmentId == id)[0];
                    if (Is_Time_Based) {
                        props.history.push({
                            pathname: '/AssessmentQuiz',
                            state: { assessmentId: id, questionsList: questions, creditId: creditId }

                        })
                        localStorage.removeItem('AssessmentQuiz');

                        localStorage.setItem("AssessmentQuiz", "true");
                        localStorage.removeItem('ReviewTimer');
                        localStorage.removeItem('DateTimeServer');
                        localStorage.removeItem('ReviewAssessmentKey');

                    }
                    else {
                        props.history.push({
                            pathname: '/NonTimeBasedQuiz',
                            state: { assessmentId: id, questionsList: questions, creditId: creditId }

                        })

                    }
                    setAssessmentDetails(data);
                })
                .catch(e => {
                    console.log(e);
                })
        }
    }
    {/*...................................................End Assessment list code part..............................................................*/ }


  {
    /*...............................filter view  section code........................................................*/
  }
  const [filterViewShow, setFilterViewShow] = useState(false);
  const [filterClientName, setFilterClientName] = useState(0);
  const [filterPartnerName, setFilterPartnerName] = useState(0);
  const [filterTeamName, setFilterTeamName] = useState(0);
  const [filterUserName, setFilterUserName] = useState(0);
  const [filterStatus, setFilterStatus] = useState(0);
  const [assessmentNameFilter, setAssessmentNameFilter] = useState(null);
  const [scoreFilter, setScoreFilter] = useState(0);
  const onFilterButtonClick = () => {
    let data1 = allAssessments;
    if (
      assessmentNameFilter != null &&
      assessmentNameFilter !== undefined &&
      assessmentNameFilter !== ""
    ) {
      data1 = data1.filter(
        (x) =>
          x.AssessmentName.toUpperCase().indexOf(
            assessmentNameFilter.toUpperCase()
          ) > -1
      );
    }
    if (filterPartnerName != 0) {
      data1 = data1.filter((x) => x.PartnerName == filterPartnerName);
    }
    if (filterClientName != 0) {
      data1 = data1.filter((x) => x.CompanyName == filterClientName);
    }
    if (filterTeamName != 0) {
      data1 = data1.filter((x) => x.TeamName == filterTeamName);
    }
    if (filterUserName != 0) {
      data1 = data1.filter((x) => x.UserName == filterUserName);
    }
    if (filterStatus != 0) {
      data1 = data1.filter((x) => x.Status == filterStatus);
    }
    if (scoreFilter != 0) {
      data1 = data1.filter((x) => x.Score <= scoreFilter);
    }
    setState({ data: data1 });
  };
  const onChangefilterPartnerName = (e) => {
    setFilterPartnerName(e.target.value);
  };
  const onChangefilterStatus = (e) => {
    setFilterStatus(e.target.value);
  };

  const onChangefilterTeamName = (e) => {
    setFilterTeamName(e.target.value);
  };

  const onChangefilterClientName = (e) => {
    setFilterClientName(e.target.value);
  };
  const onChangefilterUserName = (e) => {
    setFilterUserName(e.target.value);
  };
  const onChangeAssessmentNameFilter = (e) => {
    setAssessmentNameFilter(e.target.value);
  };
  const onHandleScoreChange = (e) => {
    setScoreFilter(e.target.value);
  };
  const filterView = () => {
    setFilterViewShow((s) => !s);
  };

  {
    /*............................... End filter view code  section........................................................*/
  }

  return (
    <>
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="ml-auto text-right mt-n1">
          <Button
            color="primary"
            className="shadow-sm mr-1"
            onClick={filterView}
          >
            <Filter className="feather" />
          </Button>

          <Button color="primary" className="shadow-sm">
            <Settings className="feather" />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={filterViewShow ? "9" : "12"}>
          <div className="bloc-Mytabs mb-3">
            <button
              className={toggleState === 1 ? "Mytabs active-Mytabs" : "Mytabs"}
              onClick={() => toggleTab(1)}
            >
              Your Assessments
            </button>
            <button
              className={toggleState === 2 ? "Mytabs active-Mytabs" : "Mytabs"}
              onClick={() => toggleTab(2)}
            >
              Team Assessments
            </button>
            <button
              className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
              onClick={() => toggleTab(3)}
            >
              Organization Assessments
            </button>
            <button
              className={toggleState === 4 ? "Mytabs active-Mytabs" : "Mytabs"}
              onClick={() => toggleTab(4)}
            >
              Partner Assessments
            </button>
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col md="12">
                  <div className="content-Mytabs">
                    <div
                      className={
                        toggleState === 1
                          ? "content-t  active-content-t"
                          : "content-t"
                      }
                    >
                      <h2>All Assessments</h2>

                      {/*......................................All Assessment Table.......................................*/}
                      <div className="scrollable">
                        <Table striped hover>
                          <thead>
                            <tr>
                              {/*<th>Role</th>*/}
                              <th>
                                <button
                                  type="button"
                                  className="SortButton"
                                  onClick={() => onSort("Assessment")}
                                >
                                  Assessment{" "}
                                  <FontAwesomeIcon
                                    icon={arrowPositionAssessment}
                                  />
                                </button>
                              </th>
                              <th>
                                <button
                                  type="button"
                                  className="SortButton"
                                  onClick={() => onSort("Status")}
                                >
                                  Status
                                  <FontAwesomeIcon icon={arrowPositionStatus} />
                                </button>
                              </th>
                              <th>
                                <button
                                  type="button"
                                  className="SortButton"
                                  onClick={() => onSort("TimeSpent")}
                                >
                                  Time Spent{" "}
                                  <FontAwesomeIcon icon={arrowPositionTime} />
                                </button>
                              </th>
                              <th>
                                <button
                                  type="button"
                                  className="SortButton"
                                  onClick={() => onSort("Score")}
                                >
                                  Score
                                  <FontAwesomeIcon icon={arrowPositionScore} />
                                </button>
                              </th>
                              <th>User</th>
                              <th>Team</th>
                              <th>Company</th>
                              <th>Partner</th>
                              {(currentUser.roleName === "partner" &&
                                users.length > 0) ||
                              (currentUser.roleName === "company" &&
                                users.length > 0) ? (
                                <th>Assign to </th>
                              ) : (
                                ""
                              )}
                              <th></th>
                              {(currentUser.roleName === "partner" &&
                                users.length > 0) ||
                              (currentUser.roleName === "company" &&
                                users.length > 0) ? (
                                <th></th>
                              ) : (
                                ""
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {state.data.map((schema) => {
                              return (
                                <tr key={schema.AssessGuid}>
                                  {/*<td>{schema.Role}</td>*/}
                                  <td>{schema.AssessmentName}</td>
                                  <td>
                                    <div className="small">
                                      <FontAwesomeIcon
                                        icon={faCircle}
                                        className={GetIconCircleColor(
                                          schema.Status
                                        )}
                                      />{" "}
                                      {schema.Status}
                                    </div>
                                  </td>
                                  <td>{schema.TimeSpent}</td>
                                  <td
                                    className="my-pointer"
                                    id={"UncontrolledPopover" + "bottom"}
                                  >
                                    {schema.Score}%
                                  </td>
                                  <UncontrolledPopover
                                    placement={"bottom"}
                                    target={"UncontrolledPopover" + "bottom"}
                                  >
                                    <PopoverHeader>Breakdown</PopoverHeader>
                                    {schema.TopicWisePercentage.map(
                                      (direction, index) => (
                                        <PopoverBody>
                                          <div className="small">
                                            <FontAwesomeIcon
                                              icon={faCircle}
                                              className="text-success"
                                            />{" "}
                                            {direction.Percentage}% -{" "}
                                            {direction.TopicName}
                                          </div>
                                        </PopoverBody>
                                      )
                                    )}
                                  </UncontrolledPopover>
                                  <td>{schema.UserName}</td>
                                  <td>{schema.TeamName}</td>
                                  <td>{schema.CompanyName}</td>
                                  <td>{schema.PartnerName}</td>
                                  {((currentUser.roleName === "partner" &&
                                    users.length > 0) ||
                                    (currentUser.roleName === "company" &&
                                      users.length > 0)) &&
                                  schema.UserId == currentUser.id &&
                                  currentUser.roleName !== "super-admin" ? (
                                    <td>
                                      {/*<FormGroup>*/}
                                      <select
                                        className="custom-select"
                                        disabled={
                                          schema.ButtonText == "Start"
                                            ? false
                                            : true
                                        }
                                        value={schema.AssignedUserId}
                                        onChange={(e) =>
                                          handleUserChange(e, schema.AssessGuid)
                                        }
                                        id={`custom-checkbox-${schema.AssessGuid}`}
                                      >
                                        <option key="0">Select user</option>
                                        {users &&
                                          users.length > 0 &&
                                          users.map((a) => {
                                            return (
                                              <option key={a.id} value={a.id}>
                                                {a.FirstName}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      {/*</FormGroup>*/}
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {((currentUser.roleName === "partner" &&
                                    users.length > 0) ||
                                    (currentUser.roleName === "company" &&
                                      users.length > 0)) &&
                                  schema.UserId == currentUser.id &&
                                  currentUser.roleName !== "super-admin" ? (
                                    <td>
                                      <button
                                        className={
                                          schema.ButtonText == "Start"
                                            ? "btn btn-pill me-1 mb-1 btn-normal btn-info"
                                            : "btn btn-pill me-1 mb-1 btn-normal btn-secondary "
                                        }
                                        disabled={
                                          schema.ButtonText == "Start"
                                            ? false
                                            : true
                                        }
                                        onClick={() =>
                                          handleAssignment(
                                            schema.AssessmentId,
                                            schema.AssignedUserId,
                                            schema.CreditId
                                          )
                                        }
                                      >
                                        Assign
                                      </button>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {currentUser.roleName !== "super-admin" &&
                                  schema.UserId == currentUser.id ? (
                                    <td>
                                      <button
                                        className={GetClassName(schema.Status)}
                                        disabled={GetButtonStatus(
                                          schema.Status
                                        )}
                                        onClick={() =>
                                          handleStartAssesmentClk(
                                            schema.AssessmentId,
                                            schema.Is_Time_Based,
                                            schema.Status,
                                            schema.Score,
                                            schema,
                                            schema.CreditId
                                          )
                                        }
                                      >
                                        {schema.ButtonText}
                                      </button>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    <div
                      className={
                        toggleState === 2
                          ? "content-t  active-content-t"
                          : "content-t"
                      }
                    >
                      <h2>Team Assessments</h2>
                      <hr />
                    </div>

                    <div
                      className={
                        toggleState === 3
                          ? "content-t  active-content-t"
                          : "content-t"
                      }
                    >
                      <h2>Organization Assessments</h2>
                      <hr />
                    </div>
                    <div
                      className={
                        toggleState === 4
                          ? "content-t  active-content-t"
                          : "content-t"
                      }
                    >
                      <h2>Partner Assessments</h2>
                      <hr />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        {/*...............................filter view ........................................................*/}
        {filterViewShow ? (
          <Col md={3}>
            <Card className="app-filters">
              <CardHeader className="px-4 border-bottom">
                <div style={{ float: "right" }}>
                  <button
                    className="btn text-dark rounded-pill"
                    onClick={() => setFilterViewShow(false)}
                  >
                    <X size={35}></X>
                  </button>
                </div>
                <p className="mb-0 h1  text-left text-primary font-weight-bold">
                  <Filter className="" size={40}></Filter> Filter
                </p>
              </CardHeader>
              <CardBody>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Assessment Name</Label>
                    <Input
                      bsSize="lg"
                      type="text"
                      name="assessment-name"
                      placeholder="Enter assessment name"
                      value={assessmentNameFilter}
                      onChange={onChangeAssessmentNameFilter}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Status</Label>
                    <select
                      className="custom-select"
                      value={filterStatus}
                      onChange={onChangefilterStatus}
                    >
                      <option value="0">Select Status</option>
                      {allAssessments
                        .filter(
                          (v, i, a) =>
                            a.findIndex((t) => t.Status === v.Status) === i
                        )
                        .map((schema) => {
                          return (
                            <option key={schema.Status} value={schema.Status}>
                              {schema.Status}
                            </option>
                          );
                        })}
                    </select>
                  </FormGroup>
                </Col>

                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Select Score</Label>
                    <input
                      className="form-control"
                      type="range"
                      //id={id}
                      min={5}
                      max={50}
                      step={0.5}
                      value={scoreFilter}
                      onChange={onHandleScoreChange}
                      // value={state} // don't set value from state
                      //defaultValue={state} // but instead pass state value as default value
                      //onChange={e => console.log(e.target.value)} // don't set state on all change as react will re-render
                      //onMouseUp={handleChange} // only set state when handle is released
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Users</Label>
                    <select
                      className="custom-select"
                      value={filterUserName}
                      onChange={onChangefilterUserName}
                    >
                      <option value="0">Select User Name</option>
                      {allAssessments
                        .filter(
                          (v, i, a) =>
                            a.findIndex((t) => t.UserName === v.UserName) === i
                        )
                        .map((schema) => {
                          return (
                            <option
                              key={schema.UserName}
                              value={schema.UserName}
                            >
                              {schema.UserName}
                            </option>
                          );
                        })}
                    </select>
                  </FormGroup>
                </Col>

                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Team</Label>
                    <select
                      className="custom-select"
                      value={filterTeamName}
                      onChange={onChangefilterTeamName}
                    >
                      <option value="0">Select Team Name</option>
                      {allAssessments
                        .filter(
                          (v, i, a) =>
                            a.findIndex((t) => t.TeamName === v.TeamName) === i
                        )
                        .map((schema) => {
                          return (
                            <option
                              key={schema.TeamName}
                              value={schema.TeamName}
                            >
                              {schema.TeamName}
                            </option>
                          );
                        })}
                    </select>
                  </FormGroup>
                </Col>

                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Client</Label>
                    <select
                      className="custom-select"
                      value={filterClientName}
                      onChange={onChangefilterClientName}
                    >
                      <option value="0">Select Client</option>
                      {allAssessments
                        .filter(
                          (v, i, a) =>
                            a.findIndex(
                              (t) => t.CompanyName === v.CompanyName
                            ) === i
                        )
                        .map((schema) => {
                          return (
                            <option
                              key={schema.CompanyName}
                              value={schema.CompanyName}
                            >
                              {schema.CompanyName}
                            </option>
                          );
                        })}
                    </select>
                  </FormGroup>
                </Col>

                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Partner</Label>
                    <select
                      className="custom-select"
                      value={filterPartnerName}
                      onChange={onChangefilterPartnerName}
                    >
                      <option value="0">Select Partner</option>
                      {allAssessments
                        .filter(
                          (v, i, a) =>
                            a.findIndex(
                              (t) => t.PartnerName === v.PartnerName
                            ) === i
                        )
                        .map((schema) => {
                          return (
                            <option
                              key={schema.PartnerName}
                              value={schema.PartnerName}
                            >
                              {schema.PartnerName}
                            </option>
                          );
                        })}
                    </select>
                  </FormGroup>
                </Col>

                <Col xs={12} md={12}>
                  <div className="text-center">
                    <button
                      className="btn btn-primary d-block col-12"
                      onClick={onFilterButtonClick}
                    >
                      <Search></Search> Search
                    </button>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
        ) : (
          <div></div>
        )}
      </Row>
    </>
  );
};

const SgaAssessments = (props) => <AssessmentsTypes {...props} />;

export default SgaAssessments;
