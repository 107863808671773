import axios from "axios";

const API_URL = "https://api.skillsgapanalysis.com/api/Login/";
// const API_URL = "http://localhost:57294/api/Login/";

const registerUser = (FirstName, LastName, Email, Password, UserJobRole) => {
  let companyLogo = getCurrentCompanyLogo();
  let companyId =
    companyLogo !== null && companyLogo.logoDetails.CompanyId
      ? companyLogo.logoDetails.CompanyId
      : 0;

  return axios.post(API_URL + "RegisterUser?" + "companyId=" + companyId, {
    FirstName,

    LastName,
    Email,
    Password,
    UserJobRole,
  });
};

const loginUser = (Email, Password) => {
  return axios
    .post(API_URL + "UserLogin", {
      Email,
      Password,
    })
    .then((response) => {
      if (response.data.AccessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logoutUser = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("companyList");
  localStorage.clear();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const registerCompany = (
  company_name,
  company_message,
  company_email,
  company_phone,
  company_contact
) => {
  var companyDetails = new FormData();
  companyDetails.append("company_name", company_name);
  companyDetails.append("company_message", company_message);
  companyDetails.append("company_email", company_email);
  companyDetails.append("company_phone", company_phone);
  companyDetails.append("company_contact", company_contact);
  //  companyDetails.append("company_email", company_email)
  //companyDetails.append("file", file.file)

  return axios.post(API_URL + "RegisterCompany", companyDetails);
};

const getCompanyNames = (userId) => {
  return axios
    .get(API_URL + "GetCompanyList?" + "userId=" + userId)
    .then((response) => {
      if (response.data) {
        localStorage.setItem("companyList", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const resetPasswordIfEmailValid = (emailId) => {
  return axios
    .get(API_URL + "ResetPasswordIfEmailValid?" + "emailId=" + emailId)
    .then((response) => {
      //console.log(response.data.Message);
      return response.data;
    });
};

const changePasswordIfEmailValid = (emailId, newPassword) => {
  return axios
    .get(API_URL + "ChangePassword?", {
      params: { viewId: emailId, newPassword: newPassword },
    })
    .then((response) => {
      //console.log(response.data.Message);
      return response.data;
    });
};
const getCompanyDetailsByCompanyId = (companyId) => {
  return axios
    .get(API_URL + "GetCompanyDetailsByCompanyId?" + "companyId=" + companyId)
    .then((response) => {
      if (response) {
        response.data.CompanyName = "";
        localStorage.removeItem("companyDetails");
        localStorage.setItem("companyDetails", JSON.stringify(response.data));
      }

      return response.data;
    });
};
const getCompanyList = () => {
  return JSON.parse(localStorage.getItem("companyList"));
};

const loginCompany = (Email, Password) => {
  return axios
    .post(API_URL + "CompanyLogin", {
      Email,
      Password,
    })
    .then((response) => {
      if (response.data.AccessToken) {
        localStorage.removeItem("company");
        localStorage.setItem("company", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logoutCompany = () => {
  localStorage.removeItem("company");
  localStorage.removeItem("companyList");
};

const getCurrentCompany = () => {
  return JSON.parse(localStorage.getItem("company"));
};

const getCurrentCompanyLogo = () => {
  return JSON.parse(localStorage.getItem("companyDetails"));
};

export default {
  registerUser,
  loginUser,
  logoutUser,
  getCurrentUser,
  registerCompany,
  loginCompany,
  logoutCompany,
  getCurrentCompany,
  getCompanyNames,
  getCompanyDetailsByCompanyId,
  getCurrentCompanyLogo,
  getCompanyList,
  resetPasswordIfEmailValid,
  changePasswordIfEmailValid,
};
