
import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import { Filter, Settings } from "react-feather";
import { Button, Modal } from "react-bootstrap";
import Watch from "../../../assets/img/icons/watch.png";
import assessmentService from "../../../AuthServices/assessment.service.js";
import { setInterval } from "timers";
import AuthService from "../../../AuthServices/auth.service.js";


const QuizView = (props) => {

    
    
    localStorage.removeItem("AssessmentQuizKey");
    localStorage.removeItem("ReviewAssessmentKey");
    var currentUser = AuthService.getCurrentUser();
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    var userId = currentUser.id;
    if (props.history.action == "POP") {
        var isKeyPresent = (JSON.parse(localStorage.getItem("ReSubmitQuestionKey")));
        if (isKeyPresent ==null) {
            props.history.push({
                pathname: '/Assessments'
            })
            window.location.reload();
        }
    }
    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: '/Assessments'
        })
        window.location.reload();
    }
    var CurrentQuestion = props.location.state.question;

    var OptionsWithScore = props.location.state.optionList;
    var currentCounter = props.location.state.currentCounter;
    var checkedRadio = props.location.state.selectedAnswer;
    var AssessmentName = props.location.state.AssessmentName;
    var assessmentId = props.location.state.assessmentId;
    var title = props.location.state.title;
    var creditId = props.location.state.creditId;
    //for tab view
    const [toggleState, setToggleState] = useState(1);
    //const [AssessmentName, setAssessmentName] = useState(null);
    const [datetimeFromServerForProgress, setDateTimeFromServerForProgress] = useState(null);
    const [showTimesUp, setShowTimesUp] = useState(false);
    //const questions = assessmentService.GetAssessments();
    const [checkedRadioButton, setCheckedRadioButton] = useState(null);
    const [seconds, setSecondsForTimer] = useState(null);
    let intervalRef = useRef();
    let localTimeReSubmit = 0;
    const toggleTab = (index) => {
        setToggleState(index);
    };
    useEffect(() => {
        //set the values after taking from the session/local storage

        if (seconds == null || localTimeReSubmit == 0) {
            SetTimeForTimer();
        }


    }, []);
    const SetTimeForTimer = () => {
        assessmentService.GetPendingTimeForTimer(userId, assessmentId,creditId).then(
            (response) => {
                if (response !== null) {
                    if (response == 0) {
                        //clearInterval(interval.current);
                        setShowTimesUp(true);
                        setTimeout(function () {
                            setShowTimesUp(false);
                            props.history.push({
                                pathname: '/Assessments'
                            })
                            window.location.reload();
                        }, 2000);
                    } else {
                         
                        var timeForStorage = Number(JSON.parse(localStorage.getItem("hourAssessment")));
                        if (timeForStorage != 0) {
                            setSecondsForTimer(secondsToMinutesFormatting(timeForStorage));
                            localTimeReSubmit = timeForStorage;
                        }
                        else {

                            setSecondsForTimer(secondsToMinutesFormatting(response));
                            localTimeReSubmit = response;
                             
                            localStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                        }
                    }
                }
            });
    }
    const secondsToMinutesFormatting = (seconds1) => {
        if (seconds1 < 0) {
            seconds1 = 0;
        }
        var sec_num = parseInt(seconds1, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }
    const handleButtonClick = (e) => {

        var currentUser = AuthService.getCurrentUser();
        var userId = currentUser.id;
        assessmentService.GetCurrentDateAndTimeFromServer().then(
            (response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);
                    assessmentService.AddUserAssessmentProgress(userId, assessmentId, "0", 0, checkedRadioButton, response, response, creditId).then((response) => {
                        return response;
                    })
                        .then((data) => {
                            assessmentService.GetReviewAnsweredQuestions(userId, assessmentId,creditId).then(
                                (response) => {
                                    if (response !== null) {
                                         
                                        var time =Number(JSON.parse(localStorage.getItem("hourAssessment")));
                                        localStorage.setItem("hourAssessment", JSON.stringify(time));
                                        localStorage.setItem("FromResubmit", true);
                                        clearInterval(Number(JSON.parse(localStorage.getItem("ReviewTimer"))));
                                        clearInterval(Number(JSON.parse(localStorage.getItem("ResubmitTimer"))));

                                        localStorage.setItem("ReSubmitQuestionKey", "true");
                                        localStorage.setItem("AssessmentQuizKey", "false");
                                        localStorage.setItem("ReviewAssessmentKey", "false");


                                        props.history.push({
                                            pathname: '/ReviewAssessment',
                                            state: { questionsList: response[0], assessmentId: assessmentId, creditId: creditId}

                                        })
                                    }
                                });


                        });
                }
            });
    };
    const SetTimeForTimerReview = () => {
        if (seconds == null) {
            assessmentService.GetPendingTimeForTimer(userId, assessmentId,creditId).then(
                (response) => {
                    if (response !== null) {
                        if (response == 0) {
                            setShowTimesUp(true);
                            setTimeout(function () {
                                setShowTimesUp(false);
                                props.history.push({
                                    pathname: '/Assessments'
                                })
                                window.location.reload();
                            }, 2000);
                        } else {
                             
                            var timeForStorage = Number(JSON.parse(localStorage.getItem("hourAssessment")));
                            if (timeForStorage != 0 && timeForStorage > 0) {
                                setSecondsForTimer(secondsToMinutesFormatting(timeForStorage));
                                localTimeReSubmit = timeForStorage;
                            }
                            else {
                                 
                                setSecondsForTimer(secondsToMinutesFormatting(response));
                                localTimeReSubmit = response;
                                localStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                            }
                        }
                    }
                });
        }
    }

    useEffect(() => {
        //set the values after taking from the session/local storage

        assessmentService.GetCurrentAssessmentSessionValues(userId, assessmentId,creditId).then(
            (response) => {
                if (response !== null) {
                    var timeForStorage = Number(JSON.parse(localStorage.getItem("hourAssessment")));
                    if (timeForStorage != 0 && timeForStorage > 0) {
                        setSecondsForTimer(secondsToMinutesFormatting(timeForStorage));
                        localTimeReSubmit = timeForStorage;
                    }
                    else {
                        setSecondsForTimer(secondsToMinutesFormatting(response.Create_Date));
                        localTimeReSubmit = response.Create_Date;
                        localStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                    }
                }
            });


    }, []);

    useEffect(() => {

        if (seconds == null || localTimeReSubmit == 0) {
            SetTimeForTimerReview();
        }
    }, []);

    useEffect(() => {
        const startTimerAgain = () => {
            if (window.location.href.indexOf("ReSubmitQuestionOption") > -1) {
                if (localTimeReSubmit > 0 && localTimeReSubmit != undefined) {
                    //var test = localTimeReSubmit;
                    //test = localTimeReSubmit - 1;
                    localTimeReSubmit = localTimeReSubmit - 1;
                    setSecondsForTimer(secondsToMinutesFormatting(localTimeReSubmit));
                    localStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                }
                else {
                    setSecondsForTimer(secondsToMinutesFormatting(0));
                    localTimeReSubmit = 0;
                    localStorage.removeItem("hourAssessment");
                }

                if (localTimeReSubmit % 10 == 0 && window.location.href.indexOf("ReSubmitQuestionOption") > -1) {
                    assessmentService.SetPendingTimeForTimer(userId, assessmentId,creditId).then(
                        (response) => {
                            if (response !== null && response != 0) {

                                var timeForStorage = Number(JSON.parse(localStorage.getItem("hourAssessment")));
                                if (timeForStorage != 0 && timeForStorage > 0) {
                                    setSecondsForTimer(secondsToMinutesFormatting(timeForStorage));
                                    localTimeReSubmit = timeForStorage;
                                    localStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                                } else {
                                    setSecondsForTimer(secondsToMinutesFormatting(response));
                                    localTimeReSubmit = response;
                                    localStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));

                                }

                            }
                            else {
                                assessmentService.UpdateUserTestTable(userId, assessmentId,creditId).then(
                                    (response) => {
                                        if (response !== null) {
                                            setSecondsForTimer(secondsToMinutesFormatting(0));
                                            localStorage.removeItem("hourAssessment");
                                            localTimeReSubmit = 0;
                                            // clearInterval(interval.current);
                                            setShowTimesUp(true);
                                            setTimeout(function () {
                                                setSecondsForTimer(secondsToMinutesFormatting(0));
                                                localStorage.removeItem("hourAssessment");
                                                localTimeReSubmit = 0;
                                                setShowTimesUp(false);
                                                props.history.push({
                                                    pathname: '/Assessments'
                                                })
                                                window.location.reload();
                                            }, 2000);
                                        }
                                    });
                            }
                        });

                }
            }
        }
        intervalRef.current = setInterval(startTimerAgain, 1000);
        localStorage.setItem("ResubmitTimer", JSON.stringify(intervalRef.current._id));
        return () => clearInterval(intervalRef.current);
    }, []);

    const onChangeOfCheckedOption = (e) => {
        setCheckedRadioButton(e.target.id);
    }

    return (


        <Row>
            <Col md="12">
                <div className="container">
                    <div className="bloc-Mytabs">
                        <button
                            className={toggleState === 1 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(1)} >
                            Home
                                 </button>
                        <button
                            className={toggleState === 2 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(2)}>
                            Assessments
                                </button>
                        <button
                            className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(3)}>
                            User Analytics
                                </button>
                    </div>

                    <div className="content-Mytabs">
                        <div
                            className={toggleState === 1 ? "content-t  active-content-t" : "content-t"} >

                            {/*......................................Assessment Quiz View .......................................*/}

                            <Row>
                                <Modal size="lg" show={showTimesUp} className="modal-colored modal-danger" backdrop="static">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Time over!!</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Your assessment will now be automatically submitted!!
        </Modal.Body>
                                </Modal>
                                <div className="col-lg-8 col-md-8">

                                    <Card className="my-card">
                                        <CardBody>
                                            <Row>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="float-left">
                                                        <h4 className="my-h4-bold">{AssessmentName}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="float-right">
                                                        <h4 className="my-h4-blue">{title}</h4>
                                                    </div>
                                                </div>
                                            </Row>

                                        </CardBody>
                                        <hr className="my-hr" />

                                        <CardBody>
                                            <Row>
                                                <div className="col-lg-1 col-md-1">
                                                    <div className="small-div-circle"> {currentCounter}</div>
                                                </div>

                                                <div className="col-lg-11 col-md-11">
                                                    <h4 className="quiz-question-h5 ">Question</h4>
                                                    <p> {CurrentQuestion}</p>
                                                </div>
                                            </Row>
                                        </CardBody>
                                        <hr className="my-hr" />

                                        <CardBody>
                                            <Row>
                                                <div className="col-lg-12 col-md-12">
                                                    <h4>Answer</h4>
                                                </div>
                                            </Row>
                                        </CardBody>
                                        <CardBody>
                                            {OptionsWithScore.map((x) => (

                                                <div className="form-check" key={x.OptionsId}>
                                                    <input name="radios-example" type="radio" className="form-check-input" id={x.OptionsId} value={x.Score} checked={checkedRadioButton == x.OptionsId} onChange={onChangeOfCheckedOption} />
                                                    <label title="" className="form-check-label" htmlFor={x.OptionsId}>{x.Options}</label>
                                                </div>

                                            ))}

                                        </CardBody>

                                        <CardBody>
                                            <Row>

                                                <div className="col-lg-8 col-md-8">
                                                    <div className="float-right">
                                                        <button id="submit-button" className="quiz-submit-btn btn-pill" onClick={handleButtonClick}>Submit</button>
                                                    </div></div>
                                            </Row>
                                        </CardBody>

                                    </Card>

                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <Card className="my-card">
                                        <CardHeader>
                                            <Row>
                                                <div className="col-lg-12 col-md-12">
                                                    <Row>
                                                        <Col sm={4} md={4}>
                                                            <img
                                                                src={Watch}
                                                                className="img-responsive mt-2"
                                                                width="50"
                                                                height="50"
                                                            />
                                                        </Col>
                                                        <Col sm={8} md={8}>
                                                            <div>
                                                                <p>Your time left</p>
                                                                <h2>{seconds}</h2>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>

                                        </CardHeader>
                                        <hr className="my-hr" />

                                        <CardBody>
                                            <Row>
                                                <h2 className="right-sidebar-h3">Your course progress</h2>
                                            </Row>
                                        </CardBody>
                                        <hr className="my-hr" />
                                    </Card>
                                </div>
                            </Row>

                        </div>

                        <div
                            className={toggleState === 2 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Team Assessments</h2>
                            <hr />

                        </div>

                        <div
                            className={toggleState === 3 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Organization Assessments</h2>
                            <hr />

                        </div>
                        <div
                            className={toggleState === 4 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Partner Assessments</h2>
                            <hr />

                        </div>
                    </div>
                </div>
            </Col>


        </Row>

    );
}

const ReSubmitQuestionOption = (props) => (
    <>
        <Row className="mb-2 mb-xl-4">


            <Col xs="auto" className="ml-auto text-right mt-n1">


                <Button color="primary" className="shadow-sm mr-1">
                    <Filter className="feather" />
                </Button>
                <Button color="primary" className="shadow-sm">
                    <Settings className="feather" />
                </Button>

            </Col>
        </Row>
        <QuizView {...props} />
    </>
);



export default ReSubmitQuestionOption;

