
import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Filter, Settings } from "react-feather";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Watch from "../../../assets/img/icons/watch.png";
import assessmentService from "../../../AuthServices/assessment.service.js";
import { setInterval } from "timers";
import AuthService from "../../../AuthServices/auth.service.js";

const QuizView = (props) => {

    localStorage.removeItem("ReSubmitQuestionNTKey");
    localStorage.removeItem("ReviewAssessmentNTKey");

    var currentUser = AuthService.getCurrentUser();
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }

    const titleRef = useRef();
    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: '/Assessments'
        })
        window.location.reload();
    }
    if (props.history.action == "POP") {
        var isKeyPresent = (JSON.parse(localStorage.getItem("AssessmentQuizNTKey")));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: '/Assessments'
            })
            window.location.reload();
        }
    }
    //fetching assessmentId from props as passed from assessment home page
    var assessmentId = props.location.state.assessmentId;
    if (assessmentId == null || assessmentId == undefined) {
        props.history.push({
            pathname: '/Assessments'
        })
    }
    //fetching questionObject from props as passed from assessment home page
    var questions = props.location.state.questionsList;
    var creditId = props.location.state.creditId;
    //declaring other const for quiz related functionality
    const [toggleState, setToggleState] = useState(1);
    const [btnText, setbtnText] = useState('Next');
    const [state, setState] = useState({ answers: [] });
    const [totalQues, setTotalQues] = useState(0);
    const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0);
    const [questionCounter, setQuestionCounter] = useState(0);
    const [currentTitleIndex, setcurrentTitleIndex] = useState(0);
    const [assessmentData, setAssessmentData] = useState(null);
    const [Title, setTitle] = useState(null);
    const [AssessmentName, setAssessmentName] = useState(null);
    const [datetimeFromServerForProgress, setDateTimeFromServerForProgress] = useState(null);

    const TitleList = questions;
    const currentQuestion = questions.TitleList[(currentTitleIndex == null ? 0 : currentTitleIndex)].QuestionList[(currentQuestionIndex == null ? 0 : currentQuestionIndex)];
    const [checkedRadio, setCheckedRadio] = useState(null);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    //declaring local variables for side bar qestion highlighting
    var count = 0;
    var countDots = 0;
    var topicNavCount = 0;
    let interval = useRef();

    //component DidMount 
    //This is for persistence of data on refresh
    useEffect(() => {
        let answers = [];
        //set the values after taking from the session/local storage
        setQuestionCounter(Number(JSON.parse(window.localStorage.getItem('questionCounter'))));
        answers = (JSON.parse(window.localStorage.getItem('state')));
        if (answers !== undefined || answers !== null) {
            setState({ answers: answers });
        }
        else {
            setState({ answers: [] });
        }
        setTitle((window.localStorage.getItem('TitleNonTimer')));
        setAssessmentName((window.localStorage.getItem('AssessmentNameNonTimer')));
        setcurrentQuestionIndex(Number(JSON.parse(window.localStorage.getItem('currentQuestionIndexNonTimer'))));
        setcurrentTitleIndex(Number(JSON.parse(window.localStorage.getItem('currentTitleIndexNonTimer'))));
        setCheckedRadio((window.localStorage.getItem('checkedRadioNonTimer')));
        if (answers !== null) {
            setCheckedRadio(answers.filter(answer => answer.pageIndex === (JSON.parse(window.localStorage.getItem('questionCounter')))).map(x => x.id)[0]);
        }
    }, []);

    //This is for persistence of data on refresh
    useEffect(() => {
        var initializeStateArr = [];
        //storing the current values of the page ,question, answer etc on refresh in local storage in order to maintain the state
        window.localStorage.setItem('questionCounterNonTimer', questionCounter == null ? 0 : questionCounter);
        window.localStorage.setItem('stateNonTimer', (state == null || state.answers == null) ? JSON.stringify(initializeStateArr) : JSON.stringify(state.answers));
        window.localStorage.setItem('TitleNonTimer', Title);
        window.localStorage.setItem('AssessmentNameNonTimer', AssessmentName);
        window.localStorage.setItem('currentQuestionIndexNonTimer', currentQuestionIndex == null ? 0 : currentQuestionIndex);
        window.localStorage.setItem('currentTitleIndexNonTimer', (currentTitleIndex == null ? 0 : currentTitleIndex));
        window.localStorage.setItem('checkedRadioNonTimer', checkedRadio == null ? 0 : checkedRadio);
    }, [questionCounter, state, Title, AssessmentName, currentQuestionIndex, currentTitleIndex, assessmentData, checkedRadio]);


    useEffect(() => {
        if (state.answer == null) {
            setState({ answers: [] });

        }
        setAssessmentName(questions.AssessmentName)
        setTitle(questions.TitleList[currentTitleIndex].TitleName)

        //Fetches assessment data
        if (assessmentData === null) {
            assessmentService.GetAllAssessmentDetails().then(
                (response) => {
                    if (response !== null) {
                        setAssessmentData(response)
                    }
                });
        }
        if (datetimeFromServerForProgress == null) {
            assessmentService.GetCurrentDateAndTimeFromServer().then(
                (response) => {
                    if (response !== null) {
                        setDateTimeFromServerForProgress(response)
                    }
                });
        }
        //Gets total number of questions from the api
        if (totalQues == 0) {

            assessmentService.GetNoOfQuestionsPerAssessment(assessmentId).then(
                (response) => {
                    if (response !== null) {
                        setTotalQues(response)
                    }
                });
        }

    }, []);

    const handleNextButtonClick = () => {
        //getting the current user details from the local storage in order to pass to api the details and id of the current user giving the assessment
        var currentUser = AuthService.getCurrentUser();
        var userId = currentUser.id;

        var totalNum = questions.TitleList[currentTitleIndex].NoOfQuestionsPerTopic;
        if (currentQuestionIndex == (totalNum - 1) && (questionCounter !== (totalQues - 1))) {
            //adding the user assessment progress on every click, saving the response for the current answer.
            assessmentService.GetCurrentDateAndTimeFromServer().then(
                (response) => {
                    if (response !== null) {
                        setDateTimeFromServerForProgress(response);
                        assessmentService.AddUserAssessmentProgress(userId, assessmentId, Title, currentQuestionIndex, checkedRadio, null, null, creditId).then(
                (response) => {
                    if (response !== null) {
                        setbtnText("Next");
                        setcurrentQuestionIndex(0)

                        //incrementing title after the questions for one section has passed
                        setCheckedRadio(0);
                        setcurrentTitleIndex(currentTitleIndex + 1)
                        setTitle(questions.TitleList[currentTitleIndex + 1].TitleName)
                        if (state !== null && state.answers !== null && state.answers !== undefined) {
                            setCheckedRadio(state.answers.filter(answer => answer.pageIndex === (questionCounter + 1)).map(x => x.id)[0]);
                        }
                        setQuestionCounter(questionCounter + 1)
                        countDots = 0;
                    }
                });
                    }
                });
        }
        else if (questionCounter == (totalQues - 1)) {
            setbtnText("Submit");
            let answers = state.answers.map(x => x.answer)
            var totalScore = answers.reduce((tot, score) => parseFloat(tot) + parseFloat(score), 0)
            //alert("Your total score is " + totalScore)
            //props.history.push("/Assessments");

            //submitting the quiz will send the user on review page where he/she can review and change answers.
            var currentUser = AuthService.getCurrentUser();
            var userId = currentUser.id;

            //Get the question and answers for review 
            //Get the question and answers for review 
            assessmentService.GetCurrentDateAndTimeFromServer().then(
                (response) => {
                    if (response !== null) {
                        setDateTimeFromServerForProgress(response);
                        assessmentService.AddUserAssessmentProgress(userId, assessmentId, Title, currentQuestionIndex, checkedRadio, datetimeFromServerForProgress, datetimeFromServerForProgress, creditId).then((response) => {
                            return response;
                        })
                            .then((data) => {

                                assessmentService.GetReviewAnsweredQuestions(userId, assessmentId,creditId).then(
                                    (response) => {
                                        if (response !== null) {
                                            setCheckedRadio(0);
                                            localStorage.removeItem("ReSubmitQuestionNTKey");
                                            localStorage.removeItem("AssessmentQuizNTKey");
                                            localStorage.setItem("ReviewAssessmentNTKey", "false");

                                            props.history.push({
                                                pathname: '/ReviewAssessmentNonTimer',
                                                state: { questionsList: response[0], creditId: creditId }

                                            })
                                        }
                                    });


                            });
                    }
                });

        }
        else {
            //adding the user assessment progress on every click, saving the response for the current answer.
            assessmentService.GetCurrentDateAndTimeFromServer().then(
                (response) => {
                    if (response !== null) {
                        setDateTimeFromServerForProgress(response);
                        assessmentService.AddUserAssessmentProgress(userId, assessmentId, Title, currentQuestionIndex, checkedRadio, null, null, creditId).then(
                (response) => {
                    if (response !== null) {
                         
                       if (questionCounter == (totalQues - 2)) {
                            setbtnText("Submit");
                        }
                        else {
                            setbtnText("Next");

                        }
                        setCheckedRadio(0);
                        setcurrentQuestionIndex(currentQuestionIndex + 1)
                        if (state !== null || state.answers !== null || state.answers !== undefined || state !== undefined) {
                            //setting the checked answer 
                            setCheckedRadio(state.answers.filter(answer => answer.pageIndex === (questionCounter + 1)).map(x => x.id)[0]);
                        }
                        //incrementing the question counter 
                        setQuestionCounter(questionCounter + 1)

                        // setting the title 
                        setTitle(questions.TitleList[currentTitleIndex].TitleName)
                    }
                });
                    }
                });
        }
    };
    const handlePreviousButtonClick = () => {
        setbtnText("Next");
        //checking if the currentQuestionIndex and currentTitleIndex is not zero as it should not allow the user to go decrement
        if (!(currentQuestionIndex == 0 && currentTitleIndex == 0)) {
            if (currentQuestionIndex == 0) {
                if (currentQuestionIndex == 0) {
                    setcurrentQuestionIndex(currentQuestionIndex + 8);
                }
                else {
                    //decrementing the question counter
                    setcurrentQuestionIndex(currentQuestionIndex - 1)
                }
                if (state !== null && state.answers !== null && state.answers !== undefined) {
                    setCheckedRadio(state.answers.filter(answer => answer.pageIndex === (questionCounter - 1)).map(x => x.id)[0]);
                }
                setQuestionCounter(questionCounter - 1)
                countDots = 0;
                if (currentTitleIndex != 0) {
                    setcurrentTitleIndex(currentTitleIndex - 1)
                    setTitle(questions.TitleList[currentTitleIndex - 1].TitleName)

                }
            }
            else {
                if (currentQuestionIndex == 0 && currentTitleIndex > 0) {
                    setcurrentTitleIndex(currentTitleIndex - 1)
                    if (state !== null && state.answers !== null && state.answers !== undefined) {
                        setCheckedRadio(state.answers.filter(answer => answer.pageIndex === (questionCounter - 1)).map(x => x.id)[0]);
                    }
                    setQuestionCounter(questionCounter - 1)
                    setcurrentQuestionIndex(7)
                    setTitle(questions.TitleList[currentTitleIndex - 1].TitleName)

                }
                else {
                    setcurrentQuestionIndex(currentQuestionIndex - 1)
                    if (state !== null && state.answers !== null && state.answers !== undefined) {
                        setCheckedRadio(state.answers.filter(answer => answer.pageIndex === (questionCounter - 1)).map(x => x.id)[0]);
                    }
                    setQuestionCounter(questionCounter - 1)
                }

            }
        }
    };
    const handleButtonClick = (e) => {

        switch (e.target.id) {
            case 'previous-button':
                handlePreviousButtonClick();
                break;
            case 'submit-button':
                if (checkedRadio == "0" || checkedRadio == undefined) {
                    //if the radio for the answer is not selected, giving an alert /promt to answer the specific question
                    alert('Please select one option!');
                }
                else {
                    handleNextButtonClick();
                    //since the counter starts from zero checking the second last question next hit making the text of button to submit
                    if (questionCounter == (totalQues - 2)) {
                        setbtnText("Submit");
                    }
                }
                break;

            default:
                break;
        }

    };

    const onChangeOfCheckedOption = (e) => {
        setCheckedRadio(e.target.id);
        const id = e.target.id;
        const answer = { id, answer: e.target.value, pageIndex: questionCounter };
        let answers = [];
        if (state == null || state.answers == null || state.answers == undefined) {
            setState({ answers: [] });
        }

        if (!(state !== null && state.answers !== null && state.answers !== undefined && state.answers.filter(answer => answer.pageIndex === questionCounter && answer.id == id).length > 0)) {

            if (state.answers !== null && state.answers.filter(answer => answer.pageIndex === questionCounter).length > 0) {
                answers = [...state.answers.filter(x => x.pageIndex !== questionCounter), answer];
            }
            else {
                answers = [...state.answers, answer];
            }

        }
        if (answers !== undefined) {

            setState({ answers });
        }
        else {
            if (answer !== undefined) {
                setState({ answers: answer });
            }
            else {
                setState({ answers: [] });
            }
        }

    }

    return (


        <Row>
            <Col md="12">
                <div className="container">
                    <div className="bloc-Mytabs">
                        <button
                            className={toggleState === 1 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(1)} >
                            Home
                                 </button>
                        <button
                            className={toggleState === 2 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(2)}>
                            Assessments
                                </button>
                        <button
                            className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
                            onClick={() => toggleTab(3)}>
                            User Analytics
                                </button>
                    </div>

                    <div className="content-Mytabs">
                        <div
                            className={toggleState === 1 ? "content-t  active-content-t" : "content-t"} >

                            {/*......................................Assessment Quiz View .......................................*/}

                            <Row>
                                <div className="col-lg-8 col-md-8">

                                    <Card className="my-card">
                                        <CardBody>
                                            <Row>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="float-left">
                                                        <h4 className="my-h4-bold">{AssessmentName}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="float-right">
                                                        <h4 className="my-h4-blue">{Title}</h4>
                                                    </div>
                                                </div>
                                            </Row>

                                        </CardBody>
                                        <hr className="my-hr" />

                                        <CardBody>
                                            <Row>
                                                <div className="col-lg-1 col-md-1">
                                                    <div className="small-div-circle"> {questionCounter + 1}</div>
                                                </div>

                                                <div className="col-lg-11 col-md-11">
                                                    <h4 className="quiz-question-h5 ">Question</h4>
                                                    <p> {currentQuestion.Questions}</p>
                                                </div>
                                            </Row>
                                        </CardBody>
                                        <hr className="my-hr" />

                                        <CardBody>
                                            <Row>
                                                <div className="col-lg-12 col-md-12">
                                                    <h4>Answer</h4>
                                                </div>
                                            </Row>
                                        </CardBody>
                                        <CardBody>
                                            {currentQuestion.OptionsWithScore.map((x) => (

                                                <div className="form-check" key={x.OptionsId}>
                                                    <input name="radios-example" type="radio" className="form-check-input" id={x.OptionsId} value={x.Score} checked={checkedRadio == x.OptionsId} onChange={onChangeOfCheckedOption} />
                                                    <label title="" className="form-check-label" htmlFor={x.OptionsId}>{x.Options}</label>
                                                </div>

                                            ))}

                                        </CardBody>

                                        <CardBody>
                                            <Row>

                                                <div className="col-lg-4 col-md-4">  {TitleList.TitleList[0].QuestionList.map((x) => (
                                                    <FontAwesomeIcon
                                                        className={countDots <= currentQuestionIndex ? "text-primary" : "text-secondary"}
                                                        icon={faCircle}
                                                        id={countDots += 1}
                                                        fixedWidth
                                                    />))}</div>
                                                <div className="col-lg-8 col-md-8">
                                                    <div className="float-right">
                                                        {/*<button className="quiz-btn-blue btn-pill" id="previous-button" onClick={handleButtonClick}>Previous Question</button> &nbsp;*/}
                                                        <button id="submit-button" className="quiz-submit-btn btn-pill" onClick={handleButtonClick}>{btnText}</button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </CardBody>

                                    </Card>

                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <Card className="my-card">
                                        <hr className="my-hr" />

                                        <CardBody>
                                            <Row>
                                                <h2 className="right-sidebar-h3">Your course progress</h2>
                                            </Row>
                                        </CardBody>
                                        <hr className="my-hr" />
                                        <CardBody>
                                            <h4>Topics</h4>

                                            <Row>
                                                <Col md={12} sm={12}>



                                                    {/*sidebar*/}
                                                    <div className="my-sidenav">
                                                        <div className="scrollable">
                                                            {TitleList.TitleList.map((x) => (
                                                                <div id={topicNavCount += 1} ref={titleRef}>  <h5 className="right-sidebar-h5">{x.TitleName}</h5><hr className="my-hr" />
                                                                    {x.QuestionList.map((p) => (<Card className="" className={questionCounter == count ? "my-question-card-Highlight" : "my-question-card"}>Question {count += 1}</Card>))}
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </div>



                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Row>

                        </div>

                        <div
                            className={toggleState === 2 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Team Assessments</h2>
                            <hr />

                        </div>

                        <div
                            className={toggleState === 3 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Organization Assessments</h2>
                            <hr />

                        </div>
                        <div
                            className={toggleState === 4 ? "content-t  active-content-t" : "content-t"}>
                            <h2>Partner Assessments</h2>
                            <hr />

                        </div>
                    </div>
                </div>
            </Col>


        </Row>

    );
}

const NonTimeBasedQuiz = (props) => (
    <>
        <Row className="mb-2 mb-xl-4">


            <Col xs="auto" className="ml-auto text-right mt-n1">


                <Button color="primary" className="shadow-sm mr-1">
                    <Filter className="feather" />
                </Button>
                <Button color="primary" className="shadow-sm">
                    <Settings className="feather" />
                </Button>

            </Col>
        </Row>
        <QuizView {...props} />
    </>
);



export default NonTimeBasedQuiz;

